import * as React from 'react'
import { Table } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getRequest } from '../../actions/common'
import ReactBreadcrumb from '../../components/breadcrumbs'
import ReactTableReport from '../../components/tables/react_table_report'

const DashboardReportItem = () => {
    const [data, setData] = useState(null)
    const params = useParams()
    const fields = ['id', 'title', 'report_year']
    const labels = { id: 'ID', title: 'Заголовок', report_year: 'Год', created_at: 'Время создания' }

    useEffect(() => {
        if (!data) {
            getRequest('dashboard_report', setData, {}, params.id)
        } else {
            document.title = `${data.results[0].title} | ИС «ПИШ»`
        }
    }, [data])

    const CheckField = ({ field }) => {
        if (data.results[0][field]) {
            return <span>{data.results[0][field]}</span>
        } else {
            return <span className={'not-set'}>(не задано)</span>
        }
    }

    const TablesRow = () => {
        if (data) {
            let rows = []
            for (let key in data.results[0]) {
                if (fields.includes(key)) {
                    rows.push(
                        <tr key={key}>
                            <th className={'text-right'}>{labels[key]}</th>
                            <td className={'text-left'}><CheckField field={key}/></td>
                        </tr>,
                    )
                }
            }
            return rows
        }
        return null
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                {data !== null ? data.results[0].title : null}
            </h1>
            <Table striped bordered hover>
                <tbody>
                <TablesRow/>
                </tbody>
            </Table>
            <p className='lead'>Данные</p>
            <ReactTableReport data={data ? typeof data.results[0].result === 'string'
                    ? JSON.parse(data.results[0].result)
                    : data.results[0].result
                : null}/>
        </React.Fragment>
    )
}

export default DashboardReportItem
