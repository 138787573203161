import { ROLES } from '../../../rbac/constant'
import { Input, InputDate, Select } from '../../../components/form/forms'
import * as Yup from 'yup'
import { setRequiredField } from '../../../utils/utils'

export const STATUS_DRAFT = -1             // Черновик
export const STATUS_NEW = 1                // Новая сущность в БД
export const STATUS_APPROVE = 2            // Рассматривается Дирекцией
export const STATUS_ACTS = 3               // Запрос актов
export const STATUS_CHECK_ACTS = 4         // Проверка актов
export const STATUS_ACCEPT = 5             // Принято
export const STATUS_ADD_MONEY_ORDER = 6
export const STATUS_CHECK_MONEY_ORDER = 7
export const STATUS_TEMP = 8

export const STATUS_LABELS = {
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Рассматривается',
    [STATUS_ACTS]: 'Запрос актов',
    [STATUS_CHECK_ACTS]: 'Проверка актов',
    [STATUS_ACCEPT]: 'Принято',
    // [STATUS_ACCOUNTING]: 'Ведётся учёт по расходам',
}

export const STATUS_OPTIONS = [
    { value: STATUS_NEW, label: 'Новый' },
    { value: STATUS_APPROVE, label: 'Рассматривается' },
    { value: STATUS_ACTS, label: 'Запрос актов' },
    { value: STATUS_CHECK_ACTS, label: 'Проверка актов' },
    { value: STATUS_ACCEPT, label: 'Принято' },
    // {value: STATUS_ACCOUNTING, label: 'Ведётся учёт по расходам'},
]

export const STATUS_BADGE_CLASS = {
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_ACTS]: 'badge-warning',
    [STATUS_CHECK_ACTS]: 'badge-info',
    [STATUS_ACCEPT]: 'badge-success',
    // [STATUS_ACCOUNTING]: 'badge-success',
}

export const FINANCING_DOCUMENT_TYPES = [
    { value: 1, label: 'НИОКР' },
    { value: 2, label: 'НИР' },
    { value: 3, label: 'Образовательные услуги' },
    { value: 4, label: 'СЧ ОКР' },
    { value: 5, label: 'ОКР' },
    { value: 6, label: 'ОТР' },
    { value: 7, label: 'СЧ НИР' },
    { value: 8, label: 'Услуги' },
    { value: 9, label: 'Другое' },
]

export const INDICATOR_TYPES = [
    { value: 1, label: 'Показатель p6 (Софинансирвоание)' },
    { value: 2, label: 'Показатель p7 (Объем НИОКР)' },
]

export const CUSTOMER_TYPES = [
    { label: 'Зарубежная компания', value: 1 },
    { label: 'Отечественная компания', value: 2 },
]


export const LABELS = {
    'id': 'ID',
    'status': 'Статус',
    'project': 'Проект',
    'account': 'Лицевой счет',
    // "event": "Мероприятие",
    'customer_type': 'Вид компании заказчика',
    'indicator_type': 'Показатель, в котором учитывается этот договор',
    'person': 'Ответственный исполнитель',
    'customer': 'Заказчик (Контрагент)',
    'contract_number': 'Номер договора',
    'contract_date': 'Дата договора',
    'work_date_end': 'Дата окончания работ/услуг по договору',
    'contract_type': 'Вид договора',
    'contract_subject': 'Предмет договора',
    'total_cost': 'Общая стоимость договора',
    'created_by': 'Создано',
    'updated_by': 'Обновлено',
    'created_at': 'Дата создания',
    'updated_at': 'Дата обновления',
    'direction_by': 'Дирекция',
    'message': 'Комментарий',
    'messages': 'Сообщения',
    'is_checked': 'Проверено',
    'is_contract': 'Файл договора',
    'is_notes': 'Файл служебной записки',
    'act_number': '№ акта',
    'date_act': 'Дата акта',
    'actual_income': 'Фактический приход денежных средств по акту',
    'inn': 'ИНН',
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'number',
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short,
    },
    account: {
        type: Input,
        source: 'text',
    },
    // event: {
    //     type: Select,
    //     source: 'event',
    //     key: (v) => `(${v?.title_short}) ${v?.title}`
    // },
    person: {
        type: Input,
        content: 'text',
    },
    customer: {
        type: Input,
        content: 'text',
    },
    customer_type: {
        type: Select,
        options: CUSTOMER_TYPES,
    },
    inn: {
        type: Input,
        content: 'text',
        props: {
            mask: '9999-999999-99',
        },
    },
    contract_number: {
        type: Input,
        content: 'text',
    },
    contract_date: {
        type: InputDate,
        content: 'date',
    },
    work_date_end: {
        type: InputDate,
        content: 'date',
    },
    contract_type: {
        type: Select,
        options: FINANCING_DOCUMENT_TYPES,
    },
    contract_subject: {
        type: Input,
        content: 'text',
    },
    total_cost: {
        type: Input,
        content: 'text',
    },
    direction_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [
        'project', 'customer', 'contract_date', 'work_date_end', 'customer_type', //'event',
        'account',
        'indicator_type',
        'contract_number',
        'total_cost',
        'inn',
    ],
    [STATUS_APPROVE]: [
        'project', 'customer', 'contract_date', 'work_date_end', 'customer_type', //'event',
        'account',
        'indicator_type',
        'contract_number',
        'total_cost',
        'inn',
    ],
    [STATUS_ACTS]: [
        'project', 'customer', 'contract_date', 'work_date_end', 'customer_type', //'event',
        'account',
        'indicator_type',
        'contract_number',
        'total_cost',
        'inn',
    ],
    [STATUS_CHECK_ACTS]: [
        'project', 'customer', 'contract_date', 'work_date_end', 'customer_type', //'event',
        'account',
        'indicator_type',
        'contract_number',
        'total_cost',
        'act_number', 'date_act', 'actual_income',
        'inn',
    ],
    [STATUS_ACCEPT]: [
        'project', 'customer', 'contract_date', 'work_date_end', 'customer_type', //'event',
        'account',
        'indicator_type',
        'contract_number',
        'total_cost',
        'act_number', 'date_act', 'actual_income',
        'inn',
    ],
    // [STATUS_ACCOUNTING]: [
    //     'project', 'customer', 'contract_date', 'work_date_start', 'work_date_end', 'person'
    // ],
}

export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction, ROLES.direction_financing],
    [STATUS_ACTS]: [ROLES.admin, ROLES.project_manager],
    [STATUS_CHECK_ACTS]: [ROLES.admin, ROLES.direction, ROLES.direction_financing],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction, ROLES.direction_financing],
}

const isINNLegalEntity = (value) => {
    const valueToString = value ? value.toString().replaceAll('-', '') : ''
    const getN = (index) => (parseInt(valueToString[index]))
    if (valueToString.length === 10) {
        const dgt10 = ((
            2 * getN(0) + 4 * getN(1) + 10 * getN(2)
            + 3 * getN(3) + 5 * getN(4) + 9 * getN(5)
            + 4 * getN(6) + 6 * getN(7) + 8 * getN(8)
        ) % 11) % 10
        return (getN(9) === dgt10)
    }
    if (valueToString.length === 12) {
        const checkSumOne = ((
            7 * getN(0) + 2 * getN(1) + 4 * getN(2)
            + 10 * getN(3) + 3 * getN(4) + 5 * getN(5)
            + 9 * getN(6) + 4 * getN(7) + 6 * getN(8)
            + 8 * getN(9)) % 11) % 10
        const checkSumTwo = ((
            3 * getN(0) + 7 * getN(1) + 2 * getN(2)
            + 4 * getN(3) + 10 * getN(4) + 3 * getN(5)
            + 5 * getN(6) + 9 * getN(7) + 4 * getN(8)
            + 6 * getN(9) + 8 * getN(10)) % 11) % 10
        if (checkSumOne === Number(getN(10)) && checkSumTwo === Number(getN(11)))
            return true
    }
    return false
}

const Schema = Yup.object().shape({
        status: Yup.number().test('conditional-status', `Для того чтобы переместить запись в статус "Принято",
     необходимо принять все акты`, function (val, context) {
            return !(val === STATUS_ACCEPT && context.parent?.contract_stages?.some((v) => !v?.is_checked))
        }),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return (status[0] < status[1]) && status[0] !== STATUS_ACTS ? schema.required('Обязательное поле!') : schema
        }),
        project_id: Yup.number()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!'),
        customer: Yup.string().nullable()
            .typeError('Обязательное поле!')
            .when(['customer_type'],
                (fields, schema) => {
                    if (fields?.[0] && fields?.[0] === 1) {
                        return schema.required('Обязательное поле!')
                    } else return schema
                }),
        account: Yup.string()
            .required('Обязательное поле!'),
        contract_number: Yup.string()
            .required('Обязательное поле!').nullable(),
        contract_date: Yup.date()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!')
            .nullable()
            .default(undefined)
            .test('test-on-null', 'Обязательное поле!', (value, context) => {
                return value
            }),
        work_date_end: Yup.date()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!')
            .nullable()
            .default(undefined)
            .test('test-on-null', 'Обязательное поле!', (value, context) => {
                return value
            }),
        person: Yup.string()
            .nullable()
            .typeError('Обязательное поле!'),
        customer_type: Yup.number().nullable().typeError('Обязательное поле!').required('Обязательное поле!'),
        contract_type: Yup.number().nullable().typeError('Обязательное поле!'),
        indicator_type: Yup.number().nullable().typeError('Обязательное поле!').required('Обязательное поле!'),
        contract_type_name: Yup.string().nullable().typeError('Обязательное поле!').when(['contract_type'],
            (fields, schema) => {
                if (fields?.[0] && fields?.[0] === 9) {
                    return schema.required('Обязательное поле!')
                } else return schema
            }),
        contract_stages:
            Yup.array()
                .of(Yup.object({
                    cost: Yup.number().required('Обязательное поле!')
                        .typeError('Введите число!')
                        .min(0, 'Минимально возможная стоимость 0!'),
                    date_start: Yup.string()
                        .required('Обязательное поле!')
                        .nullable()
                        .default(undefined),
                    date_end: Yup.string()
                        .required('Обязательное поле!')
                        .nullable()
                        .default(undefined),
                    is_checked: Yup.bool(),
                    act_number: Yup.string().nullable(),
                    date_act: Yup.string()
                        .nullable()
                        .default(undefined),
                    actual_income: Yup.number()
                        .typeError('Введите число!')
                        .min(0, 'Минимально возможный приход 0!'),
                }).typeError('Минимально возможное количeство этапов - 1!'))
                .typeError('Минимально возможное количeство этапов - 1!')
                .min(1, 'Минимально возможное количeство этапов - 1!'),
        total_cost:
            Yup.number()
                .required('Обязательное поле!')
                .typeError('Сумма по всем этапам должна совпадать!')
                .when(['contract_stages', 'status'],
                    (fields, schema) => {
                        if (fields?.[0]) {
                            let result = fields[0].reduce((a, curr) => a + curr.cost, 0)
                            return schema
                                .min(result, 'Сумма по всем этапам должна совпадать!')
                                .max(result, 'Сумма по всем этапам должна совпадать!')
                        } else return schema
                    }),
        is_contract: Yup.boolean().test('conditional-is-contract', 'Загрузите файл договора!',
            function (val, context) {
                return !val
            }).nullable(),
        inn: Yup.string()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!')
            .test('inn', 'Неверный ИНН', isINNLegalEntity)
            .when(['customer_type'],
                (fields, schema) => {
                    if (fields?.[0] && fields?.[0] === 2) {
                        return schema.required('Обязательное поле!')
                    } else return schema
                }),
    })


export const MoneySchema = Yup.object().shape({
    is_act: Yup.boolean().nullable(),
    number: Yup.string().nullable()
        .required('Обязательное поле!'),
    date: Yup.date()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!')
        .nullable()
        .default(undefined),
    summary: Yup.number()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!'),
    include_month: Yup.date()
        .typeError('Обязательное поле!')
        .nullable()
        .default(undefined).test('include_month', 'Обязательное поле!',
            function (val, context) {
                return !context.parent.is_act ? val : true
            }),
})

export const FactSchema = Yup.object().shape({
    number: Yup.string().nullable()
        .required('Обязательное поле!'),
    assignments: Yup.string().nullable()
        .required('Обязательное поле!'),
    other: Yup.string()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!'),
    other_sum: Yup.number()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!'),
    salary: Yup.string()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!'),
    salary_sum: Yup.number()
        .typeError('Обязательное поле!')
        .required('Обязательное поле!'),
})


export const REQUIRED_FIELDS_TEMP = {
    [STATUS_NEW]: Schema,
    [STATUS_APPROVE]: Schema,
    [STATUS_ACTS]: Schema,
    [STATUS_CHECK_ACTS]: Schema,
    [STATUS_ACCEPT]: Schema,
}
