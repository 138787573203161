import * as React from 'react'
import { useEffect, useState } from 'react'

import { getChartConfig, renderChart } from '../../utils/highcharts/charts'
import { getRequest } from '../../actions/common'
import { Button, Form, Row } from 'react-bootstrap'
import { SelectReact } from '../../components/form/select'
import { IconText } from '../../components/icon_txt'
import { useNavigate } from 'react-router-dom'
import { Check } from '../../components/form/forms'


const Dashboard = (props) => {
    let current_year = new Date().getFullYear()
    const select_fields = ['year', 'project']
    const labels = { 'year': 'Год', 'project': 'Проект' }
    const navigate = useNavigate()

    const [data, setData] = useState(null)
    const [show_home, setShowHome] = useState(false)
    const [show_full, setShowFull] = useState(false)

    const [select_values, setSelectValues] = useState({ year: current_year })
    const [select_data, setSelectData] = useState({})


    useEffect(() => {
        if ('project' in select_values) {
            getRequest('dashboard', setData, {
                year: select_values.year,
                project_name: select_values.project,
                show_full: show_full,
            }).then((r) => setShowHome(true))
        } else {
            getRequest('dashboard', setData, {
                year: select_values.year,
                show_full: show_full,
            }).then((r) => setShowHome(true))
        }
    }, [select_values, show_full])

    useEffect(() => {
        if (Object.keys(select_data).length === 0 && select_fields !== null) {
            getRequest('dashboard/report_year', setSelectData, {}, '', select_data, 'year')
        }
        if (Object.keys(select_data).length === 1 && select_fields !== null) {
            getRequest('project', setSelectData, { type_data: 'all' }, '', select_data, 'project')
        }
    }, [select_data])

    useEffect(() => {
        if (data !== undefined && data !== null) {
            if (data.hasOwnProperty('lastReportDate')) {
                const config = getChartConfig({
                    type: 'common',
                    json: data,
                    name: data?.name 
                        ? data.name 
                        : 'Данные по показателям программы «Передовые инженерные школы» (ПИШ)',
                    subTitle: data.lastIndUpdatedAt 
                        ? data.lastReportDate + data.lastIndUpdatedAt + data.lastIndUpdatedBy 
                        : data.lastIndUpdatedAt,
                })
                renderChart(config)
            }
        }
    }, [data])

    const FilterSelects = () => {
        if (select_fields && Object.keys(select_data).length === 2) {
            let elements = []
            select_fields.forEach((field) => {
                let options = []
                for (let i = 0; i < select_data[field].length; i++) {
                    if (select_data[field][i]) {
                        options.push({
                            value: 'year' === field 
                                ? select_data[field][i] 
                                : select_data[field][i].title_short,
                            label: 'year' === field 
                                ? select_data[field][i] 
                                : select_data[field][i].title_short,
                        })
                    }
                }
                elements.push(
                    <div className='form-group ml-3' key={field} style={{ maxWidth: '200px' }}>
                        <Form.Label>{labels[field]}</Form.Label>
                        <SelectReact options={options} setSelectState={true} select_key={field}
                                     setState={setSelectValues}
                                     select_values={select_values}
                                     value={select_values[field]}
                                     selectID={`${field}_select`}
                                     isClearable={'year' !== field}
                        />
                    </div>,
                )
            })
            if (show_home) {
                elements.push(
                    <div className='form-group ml-3' key={'home-btn'} style={{ maxWidth: '200px' }}>
                        <Button variant={'info'} style={{ marginTop: '29px' }} onClick={() => {
                            navigate('/')
                        }}>
                            <IconText icon={'fa-home'} text={'На главную'}/>
                        </Button>
                    </div>,
                )
            }
            return elements
        }
        return null
    }

    return (
        <React.Fragment>
            <Row className={'position-absolute'}
                 style={{ zIndex: 2, width: '700px', left: '20px', top: '10px' }}
            >
                <FilterSelects/>
                <div className='form-group ml-3' style={{ maxWidth: '200px' }}>
                    <div style={{ marginTop: '35px' }}>
                        <Check label={'Подробные данные'}
                               handleChangeValue={(e) => setShowFull(e.target.value === 'true')}/>
                    </div>
                </div>
            </Row>
            <div className={'chart'} id={'chart'}
                 style={{ width: '100vw', height: 'calc(100vh - 82px)', marginTop: '82px' }}/>
        </React.Fragment>
    )
}

export default Dashboard
