import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import {
    INTERNSHIP_TYPE_OPTIONS, ONLY_UPLOAD,
    REQUIRED_FIELDS, STATUS_ACCEPT, STATUS_NEW, STATUS_UPLOAD,
} from './constant'
import { Area, Input } from '../../../components/form/forms'
import { useAbac } from 'react-abac'
import { PERMISSIONS } from '../../../rbac/constant'
import { btnMessage } from '../../../utils/utils'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { Field, FormikProvider, useFormik } from 'formik'
import { REQUIRED_FIELDS_TEMP } from './constant'
import { FormikDate, FormikSelect } from '../../../components/form/formik'
import { toast } from 'react-toastify'

export const InternshipsItem = ({
                                    data,
                                    setData,
                                    user,
                                    disabled,
                                    messages,
                                    generateOptions,
                                    ErrorsBlock,
                                    MessageHistory,
                                    ActionButtons,
                                    DisableButton,
                                    HeaderStatus,
                                    handleSubmit,
                                }) => {
    const params = useParams()
    const navigate = useNavigate()
    const project_ref = useRef()

    const { userHasPermissions } = useAbac()

    const [select_data, setSelectData] = useState({})
    const select_fields = ['specialist', 'project']
    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'order_number': '',
        'order_date': null,
        'order_type': '',

        'partner_name': '',
        'partner_number': '',
        'partner_date': null,

        'protocol_number': '',
        'protocol_date': null,

        'students': '',

        'internship_number': '',
        'internship_date': null,

        'scholarships_number': '',
        'scholarships_date': null,
    })
    const can_only_upload = ONLY_UPLOAD[data?.status] ? user.roles.some((role) => ONLY_UPLOAD[data?.status].includes(role)) : false
    disabled = can_only_upload || disabled

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return saveInternships()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        handleSubmit(formik, saveInternships)
    }, [formik.values])


    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД Cтажировки | ИС «ПИШ»`
            let set_selected = {}
            select_fields.forEach((field) => {
                if (field === 'specialist') {
                    set_selected['students'] = data['students']?.map((item) => item.id)
                } else {
                    set_selected[`${field}_id`] = data[field]?.id
                }
            })

            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }
            formik.setValues({ ...set_inputs, ...set_selected, current_status: data.status })
        }
    }, [data])

    useEffect(() => {
        if (data) {
            let is_manager = user?.roles.every((value) => value === 'project_manager')  // can't view all projects
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of select_fields) {
                    if (!Object.keys(select_data).includes(field)) {
                        if (field === 'specialist') {
                            getRequest(field, setSelectData, { not_new: 'all' }, '', select_data, field)
                        } else if (field === 'project' && is_manager) {
                            getRequest('project', setSelectData, { type_data: 'user_projects', 'user_id': user.id }, '', select_data, field)
                        } else {
                            getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        }
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values])

    const saveInternships = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction: user.id }
        } else {
            payload = formik.values
        }

        updateRequest('internships', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/internships')
                setData(response.data)
            } else if (response.status === 400) {
                if (response.data?.message)
                    toast.error(response.data?.message)
                if (response.data?.fields)
                    formik.setErrors({ ...formik.errors, ...response.data?.fields })
            }
        })

        if (formik?.values?.message) {
            putRequest('internships_messages', {
                content: formik?.values?.message,
                internship_status: data.status,
                status: data.status,
                created_by: user.id,
                internship: data.id,
            }, false)
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }
    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <ReactBreadcrumb/>
                <Form>
                    <div className='section-header sticky-top' style={{ zIndex: 2 }}>
                        <h1 className='page-header'>
                            {`Редактирование записи №${params.id} в БД Стажировка`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_INTERN}
                                       permission_manage={PERMISSIONS.MANAGE_INTERN}
                                       can_back={!userHasPermissions(PERMISSIONS.IS_MANAGER)
                                           && data?.status > STATUS_NEW
                                           && data?.status < STATUS_ACCEPT}
                        />
                    </div>
                    <div
                        className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                        <HeaderStatus style={{ minWidth: '250px' }}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='internships_message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          disabled={disabled}
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          rows='5'
                                          value={formik?.values?.message}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>Приказ о конкурсе</legend>
                                <Field component={FormikSelect} label={'Проект'} id={'project_id'}
                                       isSearchable={true}
                                       options={generateOptions('project', 'title_short', select_data)}
                                       name={'project_id'}
                                       disabled={disabled}
                                       error={formik.errors['project']}
                                       invalid={formik.errors['project']}
                                       required={data ? REQUIRED_FIELDS[data.status + 1].includes('project_id') : false}/>
                                <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                                    <Col>
                                        <Input handleChangeValue={formik.handleChange} label={'Номер'}
                                               name={'order_number'}
                                               id={'order_number'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('order_number') : false}
                                               error={formik.errors['order_number']}
                                               invalid={formik.errors['order_number']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.order_number : null}/>
                                    </Col>
                                    <Col>
                                        <Field component={FormikDate}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата'}
                                               id={'order_date'}
                                               name={'order_date'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('order_date') : false}
                                               error={formik.errors['order_date']}
                                               invalid={formik.errors['order_date']}
                                               disabled={disabled}
                                               value={formik.values.order_date}/>
                                    </Col>
                                    <Col>
                                        <Field component={FormikSelect}
                                               label={'Тип стажировки'}
                                               id={'order_type'}
                                               name={'order_type'}
                                               isSearchable={true}
                                               options={INTERNSHIP_TYPE_OPTIONS}
                                               error={formik.errors['order_type_id']}
                                               invalid={formik.errors['order_type_id']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('order_type') : false}/>
                                    </Col>
                                </Row>
                                <MyFileBrowser path={`root/storage/internships/internships_${params.id}/order/`}
                                               instanceId={'order'}
                                               height={200}
                                               read_only={disabled}
                                />
                                <hr/>
                            </fieldset>

                            <fieldset>
                                <legend>Договор с партнером</legend>
                                <Row className='row-cols-1 row-cols-md-2 row-cols-lg-3'>
                                    <Col>
                                        <Input handleChangeValue={formik.handleChange} label={'Название'}
                                               name={'partner_name'}
                                               id={'partner_name'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('partner_name') : false}
                                               error={formik.errors['partner_name']}
                                               invalid={formik.errors['partner_name']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.partner_name : null}/>
                                    </Col>
                                    <Col>
                                        <Input handleChangeValue={formik.handleChange} label={'Номер'}
                                               name={'partner_number'}
                                               id={'partner_number'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('partner_number') : false}
                                               error={formik.errors['partner_number']}
                                               invalid={formik.errors['partner_number']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.partner_number : null}/>
                                    </Col>
                                    <Col>
                                        <Field component={FormikDate}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата'}
                                               id={'partner_date'}
                                               name={'partner_date'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('partner_date') : false}
                                               error={formik.errors['partner_date']}
                                               invalid={formik.errors['partner_date']}
                                               disabled={disabled}
                                               value={formik.values.order_date}/>
                                    </Col>
                                </Row>
                                <MyFileBrowser path={`root/storage/internships/internships_${params.id}/partner/`}
                                               instanceId={'partner'}
                                               height={200}
                                               read_only={disabled}
                                />
                                <hr/>
                            </fieldset>

                            <fieldset>
                                <legend>Протокол по результатам конкурсного отбора</legend>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Номер'}
                                               name={'protocol_number'}
                                               id={'protocol_number'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('protocol_number') : false}
                                               error={formik.errors['protocol_number']}
                                               invalid={formik.errors['protocol_number']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.protocol_number : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата'}
                                               id={'protocol_date'}
                                               name={'protocol_date'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('protocol_date') : false}
                                               error={formik.errors['protocol_date']}
                                               invalid={formik.errors['protocol_date']}
                                               disabled={disabled}
                                               value={formik.values.protocol_date}/>
                                    </Col>
                                </Row>
                                <MyFileBrowser path={`root/storage/internships/internships_${params.id}/partner/`}
                                               instanceId={'partner'}
                                               height={200}
                                               read_only={disabled}
                                />
                                <hr/>
                            </fieldset>

                            <fieldset>
                                <legend>Студенты</legend>
                                <Field component={FormikSelect} label={'Студенты'}
                                       id={'students'}
                                       name={'students'}
                                       isSearchable={true}
                                       disabled={disabled}
                                       isMulti={true}
                                       closeMenuOnSelect={false}
                                       required={data ? REQUIRED_FIELDS[data.status + 1].includes('students') : false}
                                       options={generateOptions('specialist', (element) => {
                                            return element?.fio
                                       }, select_data)}
                                       error={formik.errors['students_id']}
                                       invalid={formik.errors['students_id']}
                                />
                            </fieldset>

                            <fieldset>
                                <legend>Приказ о направлении на стажировку</legend>
                                <Row>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange} label={'Номер'}
                                               name={'internship_number'}
                                               id={'internship_number'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('internship_number') : false}
                                               error={formik.errors['internship_number']}
                                               invalid={formik.errors['internship_number']}
                                               disabled={disabled}
                                               value={formik.values ? formik.values?.internship_number : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата'}
                                               id={'internship_date'}
                                               name={'internship_date'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('internship_date') : false}
                                               error={formik.errors['internship_date']}
                                               invalid={formik.errors['internship_date']}
                                               disabled={disabled}
                                               value={formik.values.internship_date}/>
                                    </Col>

                                </Row>
                                <MyFileBrowser
                                    path={`root/storage/internships/internships_${params.id}/internship/`}
                                    instanceId={'internship'}
                                    height={200}
                                    read_only={disabled}
                                />
                                <hr/>
                            </fieldset>

                            {formik.values.order_type === 1
                                ? <fieldset>
                                    <legend>Приказ о выплате стипендии или оплата билетов (если грант)</legend>
                                    <Row>
                                        <Col md={6}>
                                            <Input handleChangeValue={formik.handleChange} label={'Номер'}
                                                   name={'scholarships_number'}
                                                   id={'scholarships_number'}
                                                   required={data ? REQUIRED_FIELDS[data.status + 1].includes('scholarships_number') : false}
                                                   error={formik.errors['scholarships_number']}
                                                   invalid={formik.errors['scholarships_number']}
                                                   disabled={disabled}
                                                   value={formik.values ? formik.values?.scholarships_number : null}/>
                                        </Col>
                                        <Col md={6}>
                                            <Field component={FormikDate}
                                                   handleChangeValue={formik.handleChange}
                                                   label={'Дата'}
                                                   id={'scholarships_date'}
                                                   name={'scholarships_date'}
                                                   required={data ? REQUIRED_FIELDS[data.status + 1].includes('scholarships_date') : false}
                                                   error={formik.errors['scholarships_date']}
                                                   invalid={formik.errors['scholarships_date']}
                                                   disabled={disabled}
                                                   value={formik.values.scholarships_date}/>
                                        </Col>
                                    </Row>
                                    <MyFileBrowser
                                        path={`root/storage/internships/internships_${params.id}/scholarship/`}
                                        instanceId={'scholarship'}
                                        height={200}
                                        read_only={disabled}
                                    />
                                    <hr/>
                                </fieldset>
                                : null}

                            {data?.status >= STATUS_UPLOAD
                                ? <fieldset>
                                    <legend>Отчет студентов по практике</legend>
                                    <hr/>
                                    <MyFileBrowser path={`root/storage/internships/internships_${params.id}/report/`}
                                                   instanceId={'report'}
                                                   read_only={disabled && !can_only_upload}
                                    />
                                </fieldset>
                                : null}
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}
