import { ROLES } from '../../../rbac/constant'
import { Input, InputDate, Select } from '../../../components/form/forms'
import * as Yup from 'yup'

export const STATUS_DRAFT = -1             // Черновик
export const STATUS_ACCEPT = 5             // Принято
export const STATUS_NEW = 1                // Новая сущность в БД
export const STATUS_APPROVE = 2            // Рассматривается
export const STATUS_PROCESS = 3            // Добавление результатов
export const STATUS_CHECK = 4              // Проверка результатов

export const STATUS_LABELS = {
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Рассматривается',
    [STATUS_PROCESS]: 'Добавление результатов',
    [STATUS_CHECK]: 'Проверка результатов',
    [STATUS_ACCEPT]: 'Принято',
}

export const STATUS_OPTIONS = [
    { value: STATUS_NEW, label: 'Новый' },
    { value: STATUS_APPROVE, label: 'Рассматривается' },
    { value: STATUS_PROCESS, label: 'Добавление результатов' },
    { value: STATUS_CHECK, label: 'Проверка результатов' },
    { value: STATUS_ACCEPT, label: 'Принято' },
]

export const STATUS_BADGE_CLASS = {
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_PROCESS]: 'badge-primary',
    [STATUS_CHECK]: 'badge-primary',
    [STATUS_ACCEPT]: 'badge-success',
}

export const SCIENCE_DIRECTIONS = [
    { value: 1, label: 'Кросс-отраслевые цифровые платформенные решения и технологии' },
    { value: 2, label: 'Системный цифровой инжиниринг в двигателестроении' },
    { value: 3, label: 'Цифровые технологии в атомной отрасли' },
    { value: 4, label: 'Цифровые технологии для топливно-энергетического комплекса' },
    { value: 5, label: 'Новые материалы' },
]

export const MONTHS = {
    [1]: 'Январь',
    [2]: 'Февраль',
    [3]: 'Март',
    [4]: 'Апрель',
    [5]: 'Май',
    [6]: 'Июнь',
    [7]: 'Июль',
    [8]: 'Август',
    [9]: 'Сентябрь',
    [10]: 'Октябрь',
    [11]: 'Ноябрь',
    [12]: 'Декабрь',
}

export const LABELS = {
    'id': 'ID',
    'status': 'Статус',
    'science_dir': 'Научное направление',
    'title': 'Тема НИОКР',
    'partner': 'Индустриальный партнер',
    'supervisor': 'Руководитель/Отв. исп. НИОКР',
    'presentation_link': 'Ссылка на презентацию',
    'grnti_program': 'ГРНТИ (Программа)',
    'expected_result': 'Ожидаемый результат проекта',
    'results': 'Результаты работы за месяц ',
    'niokr_message': 'Комментарий',
    'messages': 'Сообщения',
    'project': 'Проект',
    'updated_by': 'Обновлено пользователем',
    'created_at': 'Дата создания',
    'updated_at': 'Дата обновления',
    'created_by': 'Создано пользователем',
}

export const ERROR_LABELS = {
    'application_date': 'Дата подачи заявки',
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'text',
    },
    title: {
        type: Input,
        content: 'text',
    },
    type: {
        type: Input,
        content: 'text',
    },
    partner: {
        type: Input,
        content: 'text',
    },
    supervisor: {
        type: Input,
        content: 'text',
    },
    presentation_link: {
        type: Input,
        content: 'text',
    },
    grnti_program: {
        type: Input,
        content: 'text',
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short,
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },

    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [
         'science_dir', 'title', 'partner', 'supervisor', 'project',
    ],
    [STATUS_APPROVE]: [
        'science_dir', 'title', 'partner', 'supervisor', 'project', 'expected_result_year',
    ],
    [STATUS_PROCESS]: [
        'science_dir', 'title', 'partner', 'supervisor', 'project', 'expected_result_year',
    ],
    [STATUS_CHECK]: [
        'science_dir', 'title', 'partner', 'supervisor', 'project', 'expected_result_year',
    ],
    [STATUS_ACCEPT]: [
        'science_dir', 'title', 'partner', 'supervisor', 'project', 'project', 'expected_result_year',
    ],
}

function validateExpectedResults() {
    const { parent } = this
    let isEmptyResults = false
    if (parent.current_status === 4 && parent.status === 5) {
        if (!parent?.acceptable) return false
        parent.expected_result_year.forEach((year_item) => {
            year_item.month_results.forEach((month_item) => {
                if (!month_item.accepted || !('results' in month_item) || !month_item.results) {
                    isEmptyResults = true
                }
            })
        })
    } 
    return !isEmptyResults
}
const getSchema = () => {
    return {
        status: Yup.number(),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return status[0] < status[1] ? schema.required('Обязательное поле!') : schema
        }),
        expected_result_year: Yup.array(
            Yup.object({
                year: Yup.number().nullable(),
                result: Yup.string(),
                month_results: Yup.array(
                    Yup.object({
                        month: Yup.number().nullable(),
                        results: Yup.string(),
                        accepted: Yup.boolean(),
                    }),
                ),
            })).test('results test', ' ', validateExpectedResults),
        science_dir: Yup.string()
            .typeError('Обязательное поле!')
            .nullable()
            .required('Обязательное поле!'),
        title: Yup.string()
            .typeError('Обязательное поле!')
            .nullable()
            .required('Обязательное поле!'),
        partner: Yup.string()
            .typeError('Обязательное поле!')
            .nullable()
            .required('Обязательное поле!'),
        supervisor: Yup.string()
            .typeError('Обязательное поле!')
            .nullable()
            .required('Обязательное поле!'),
        project_id: Yup.number().required('Обязательное поле!').typeError('Обязательное поле!'),

    }
}

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_NEW]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE]: Yup.object().shape(getSchema()),
    [STATUS_PROCESS]: Yup.object().shape(getSchema()),
    [STATUS_CHECK]: Yup.object().shape(getSchema()),
    [STATUS_ACCEPT]: Yup.object().shape(getSchema()),
}

export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin,  ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction],
    [STATUS_PROCESS]: [ROLES.admin, ROLES.project_manager],
    [STATUS_CHECK]: [ROLES.admin, ROLES.direction],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}

export const CAN_MANAGE_ROLES_COMMON = {
    [STATUS_NEW]: [ROLES.admin,  ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction],
    [STATUS_PROCESS]: [ROLES.admin],
    [STATUS_CHECK]: [ROLES.admin, ROLES.direction],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}

export const CAN_MANAGE_ROLES_RESULTS = {
    [STATUS_NEW]: [ROLES.admin,  ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin],
    [STATUS_PROCESS]: [ROLES.admin, ROLES.project_manager],
    [STATUS_CHECK]: [ROLES.admin, ROLES.direction],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}

export const CAN_APPROVE_ROLES = {
    [STATUS_NEW]: [ROLES.admin],
    [STATUS_APPROVE]: [ROLES.admin],
    [STATUS_PROCESS]: [ROLES.admin, ROLES.direction],
    [STATUS_CHECK]: [ROLES.admin, ROLES.direction],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}
