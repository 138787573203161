import * as React from 'react'
import '../../assets/css/auth.css'
import logo from '../../assets/image/logo-pish-pl.png'

import { Button, Card, Container, Form, Image } from 'react-bootstrap'
import { IconText } from '../../components/icon_txt'
import { checkResetToken, resetPassToken } from '../../actions/auth'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Input } from '../../components/form/forms'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { ResetSchema } from './constant'

const ResetPass = () => {
    const params = useParams()
    const navigate = useNavigate()

    const formik = useFormik({
        validationSchema: ResetSchema,
        initialValues: {
            password: '',
            password_repeat: '',
        },
        onSubmit: (values) => {
            handleSubmit()
        },
        enableReinitialize: true,
    })
    const [access, setAccess] = useState(null)

    useEffect(() => {
        if (!access) {
            checkResetToken(params.token, setAccess).then((e) => {
            })
        } else if (!access) {
            navigate('/access-error')
        }
    }, [access])


    const handleSubmit = () => {
        if (formik.isValid) {
            resetPassToken(formik.values.password, params.token).then((e) => {
            })
        } else {
            toast.error('Пароли должны совпадать! И минимальная длинна пароля должна составлять 8 символов!')
        }
    }

    return (
        <React.Fragment>
            <Container className={'wrapper'}>
                <Link className={'logo-link'} to={'/'}>
                    <Image fluid src={logo}/>
                </Link>
                <Card className={'login-card'}>
                    <Card.Header>Восстановление пароля</Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleSubmit}>
                            <Input label={'Пароль'} className='mb-3' type={'password'}
                                   id={'password'} name={'password'}
                                   handleChangeValue={formik.handleChange}
                                   invalid={formik.errors.password}
                                   error={formik.errors.password}
                            />
                            <Input label={'Повторите пароль'} className='mb-3' type={'password'}
                                   id={'password_repeat'} name={'password_repeat'}
                                   handleChangeValue={formik.handleChange}
                                   invalid={formik.errors.password_repeat}
                                   error={formik.errors.password_repeat}
                            />
                            <Button onClick={formik.submitForm} variant={'outline-primary'}>
                                <IconText text={'Восстановить'} icon={'sign-in-alt'}/>
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    )
}

export default ResetPass
