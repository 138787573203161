import Breadcrumb from 'react-bootstrap/Breadcrumb'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import * as React from 'react'
import { Link } from 'react-router-dom'

const generatePath = (path, name) => {
    return [
        { path: `/${path}`, breadcrumb: name },
        { path: `/${path}/update`, breadcrumb: 'Редактировать' },
        { path: `/${path}/create`, breadcrumb: 'Создать' },
        { path: `/${path}/item`, breadcrumb: 'Элемент' },
    ]
}


const routes = [
    { path: '/', breadcrumb: 'Главная' },
    { path: '/dicts', breadcrumb: 'Справочники' },
    { path: '/grant', breadcrumb: 'Грант' },
    { path: '/dashboard-report', breadcrumb: 'Данные дашборда' },
    { path: '/dashboard-report/item', breadcrumb: 'Элемент' },
    { path: '/indicator/settings', breadcrumb: 'Настройки показателей' },
    { path: '/import', breadcrumb: 'Импорт данных' },
    ...generatePath('indicator/report', 'Отчет'),
    ...generatePath('internships', 'Стажировка'),
    ...generatePath('indicator/report_fin', 'Отчет софинансирования'),
    ...generatePath('amortization', 'Амортизация'),
    ...generatePath('financing', 'Доход / Софинансирование'),
    ...generatePath('users', 'Пользователи'),
    ...generatePath('indicator', 'Показатели'),
    ...generatePath('staff', 'Кадры'),
    ...generatePath('employed', 'Трудоустроенные'),
    ...generatePath('rid', 'РИДы'),
    ...generatePath('rid/rid-external', 'РИДы (внешний мониторинг)'),
    ...generatePath('rid/rid-internal', 'РИДы (внутренний мониторинг)'),
    ...generatePath('rid', 'РИДы'),
    ...generatePath('dpo', 'ДПО'),
    ...generatePath('school', 'Школьники'),
    ...generatePath('niokr', 'НИОКР'),
    ...generatePath('educational_program', 'Образовательные программы'),
    ...generatePath('control_oop', 'Контроль разработки ООП'),
    ...generatePath('specialist', 'Обученные и студенты'),
    ...generatePath('publication', 'Публикации'),
    ...generatePath('indicator-project', 'Показатели по проектам'),
    ...generatePath('dicts/event-category', 'Категории мероприятий'),
    ...generatePath('dicts/academic-degree', 'Ученые степени'),
    ...generatePath('dicts/school-event', 'Типы мероприятий для БД "Школьники"'),
    ...generatePath('dicts/academic-rank', 'Ученые звания'),
    ...generatePath('dicts/country', 'Страны'),
    ...generatePath('dicts/department', 'Подразделения'),
    ...generatePath('dicts/event', 'Мероприятия'),
    ...generatePath('dicts/job-contract', 'Формы привлечения работников'),
    ...generatePath('dicts/organization', 'Контрагенты'),
    ...generatePath('dicts/patent-office', 'Патентные ведомства'),
    ...generatePath('dicts/project', 'Проекты'),
    ...generatePath('dicts/self-academic-degree', 'Ученые степени'),
    ...generatePath('dicts/self-academic-rank', 'Ученые звания'),
    ...generatePath('dicts/staff-category', 'Категории работников'),
    ...generatePath('dicts/staff-position', 'Должности'),
    ...generatePath('dicts/staff-unit', 'Штатные единицы'),
    ...generatePath('dicts/unit', 'Единицы измерения'),
    ...generatePath('dicts/template', 'Шаблоны'),
    ...generatePath('dicts/template-oop', 'Шаблоны документов для открытия ООП'),
    ...generatePath('dicts/order', 'Приказы'),
    ...generatePath('dicts/person', 'Персоналии'),
    ...generatePath('dicts/deadlines-oop', 'Сроки добавления документов по ООП'),
    ...generatePath('dicts/speciality', 'Специальность и направления подготовки'),
]


function ReactBreadcrumb({ label }) {
    const breadcrumbs = useBreadcrumbs(routes)
    let active_path = false
    let bootstrap_item_array = []

    for (let i = 0; i < breadcrumbs.length; i++) {
        if (i === breadcrumbs.length - 1
            || breadcrumbs[i].match.pathname.includes('item')
            || breadcrumbs[i].match.pathname.includes('update')
            || breadcrumbs[i].match.pathname.includes('create')) {
            active_path = true
        }
        bootstrap_item_array.push(
            <Breadcrumb.Item key={breadcrumbs[i].match.pathname} active={active_path} linkAs={Link}
                             linkProps={{ to: breadcrumbs[i].match.pathname }}>
                {label && i === breadcrumbs.length - 1 ? label : breadcrumbs[i].breadcrumb}
            </Breadcrumb.Item>,
        )
    }

    return (
        <Breadcrumb>
            {bootstrap_item_array}
        </Breadcrumb>
    )
}

export default ReactBreadcrumb
