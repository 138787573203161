import * as React from 'react'
import '../../assets/css/auth.css'
import logo from '../../assets/image/logo-pish-pl.png'

import { Button, Card, Container, Form, Image } from 'react-bootstrap'
import { IconText } from '../../components/icon_txt'
import { generateResetToken } from '../../actions/auth'
import { Input } from '../../components/form/forms'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { ForgetSchema } from './constant'
import { toast } from 'react-toastify'

const Forget = () => {
    const navigate = useNavigate()
    const formik = useFormik({
        validationSchema: ForgetSchema,
        initialValues: {
            email: '',
        },
        onSubmit: (values) => {
            handleSubmit()
        },
        enableReinitialize: true,
    })

    const handleSubmit = () => {
        generateResetToken(formik.values.email).then((r) => {
            if (r?.status === 200) {
                navigate('/')
                toast.success('Вам была отправлена ссылка для сброса пароля! Проверьте Email-почту')
            }
        })
    }

    return (
        <React.Fragment>
            <Container className={'wrapper'}>
                <Link className={'logo-link'} to={'/'}>
                    <Image fluid src={logo}/>
                </Link>
                <Card className={'login-card'}>
                    <Card.Header>Восстановление пароля</Card.Header>
                    <Card.Body>
                        <Form>
                            <Input className={'mb-3'} label={'Адрес эл. почты'} type={'email'}
                                   id={'email'}
                                   name={'email'}
                                   value={formik.values.email}
                                   handleChangeValue={formik.handleChange}
                                   invalid={formik.errors?.email}
                                   error={formik.errors?.email}
                            />
                            <Button onClick={() => formik.submitForm()} variant={'outline-primary'}>
                                <IconText text={'Отправить ссылку для сброса пароля'} 
                                          icon={'paper-plane'}
                                />
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </React.Fragment>
    )
}

export default Forget
