import axiosInstance, { fullExit, protectedAxios } from '../utils/axiosAPI'
import { toast } from 'react-toastify'

export const setAuthToken = (token) => {
    if (token) {
        axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`
    } else {
        delete axiosInstance.defaults.headers['Authorization']
    }
}

export const getUser = (setFunc) => {
    return protectedAxios(axiosInstance.get, '/api/user/')
        .then((response) => {
            return response.data
        })
        .catch((err) => console.log(err))
}


export const loginSubmit = async (email, pass, href) => {
    const loginPayload = {
        username: email,
        password: pass,
    }

    return axiosInstance.post('/api/token/', loginPayload)
        .then((response) => {
            if (response.status === 200) {
                localStorage.setItem('token', response.data.access)
                localStorage.setItem('refresh_token', response.data.refresh)

                setAuthToken(response.data.access)
                window.location.href = href
                console.log(response)
            }
            return response
        })
        .catch((err) => {
            let message = err?.response?.data?.detail
            if (message) toast.error(message)
            return err
        })
}


export const logoutSubmit = () => {
    const logoutPayload = {
        refresh_token: localStorage.getItem('refresh_token'),
    }

    protectedAxios(axiosInstance.post, '/api/logout/', logoutPayload)
        .then((response) => {
            localStorage.clear()
            setAuthToken()

            window.location.href = '/'
        })
        .catch((err) => console.log(err))
}

export const generateResetToken = (email) => {

    return axiosInstance.post('/api/reset_token/', { email: email })
        .then()
        .catch((err) => {
            let message = err?.response?.data?.detail
            message ? toast.error(message) : null
            return err?.response?.data?.fields
        })
}

export const checkResetToken = (reset_token, setFunc) => {
    const payload = {
        params: { reset_token: reset_token },
    }

    return axiosInstance.get('/api/reset_token/', payload)
        .then((response) => {
            setFunc(response.data)
        })
        .catch((err) => {
            return err
        })
}

export const resetPassToken = (pass, token) => {

    return axiosInstance.put('/api/reset_token/', { password: pass, reset_token: token })
        .then((response) => {
            if (response.status === 200) {
                window.location.href = '/login'
            }
        })
        .catch((err) => {
        })
}
