import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../../components/breadcrumbs'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { IconText } from '../../../../components/icon_txt'
import { useEffect, useRef, useState } from 'react'
import { getRequest, putRequest, updateRequest } from '../../../../actions/common'
import { Area, Input, InputDate } from '../../../../components/form/forms'
import { PERMISSIONS } from '../../../../rbac/constant'
import { useAbac } from 'react-abac'
import { RID_DOCUMENT_TYPES, RID_STATUS, RID_TYPES, STATUS_ACCEPT, STATUS_PROCESS } from '../constant'
import { btnMessage, findInSelectData } from '../../../../utils/utils'
import { REQUIRED_FIELDS } from '../constant'
import { MyFileBrowser } from '../../../../components/file_manager/file_manager'
import { Field, FormikProvider, useFormik } from 'formik'
import { REQUIRED_FIELDS_TEMP } from '../constant'
import { FormikDate, FormikSelect } from '../../../../components/form/formik'

export const RidInternalItem = ({
                            data,
                            setData,
                            user,
                            disabled,
                            messages,
                            handleChangeValue,
                            generateOptions,
                            ErrorsBlock,
                            MessageHistory,
                            ActionButtons,
                            DisableButton,
                            handleSubmit,
                            HeaderStatus,
                        }) => {
    const params = useParams()
    const navigate = useNavigate()
    const project_ref = useRef()

    const { userHasPermissions } = useAbac()
    const [select_data, setSelectData] = useState({})

    const select_fields = ['project', 'event', 'patent_office']
    const given_select_fields = ['type', 'application_status', 'document_type']


    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'title_ru': '',
        'title_en': '',
        'type': null,
        'document_type': null,
        'application_status': null,
        'date': null,
        'number': '',
        'related_docs_url': '',
        'organization_name': '',
        'authors_input': '',
    })

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return saveRid()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        handleSubmit(formik, saveRid)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД РИДы | ИС «ПИШ»`
            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })
            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }
            if (!set_selected?.patent_office_id) {
                set_selected.patent_office_id = 10
            }
            formik.setValues({ ...set_inputs, ...set_selected, current_status: data.status })
        }
    }, [data])

    useEffect(() => {
        if (data) {
            let is_manager = user?.roles.every((value) => value === 'project_manager')  // can't view all projects
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of select_fields) {
                    if (!Object.keys(select_data).includes(field) && field !== 'event') {
                        if (field === 'staff') {
                            getRequest('staff_select', setSelectData, {}, '', select_data, field)
                        } else if (field === 'project' && is_manager) {
                            getRequest('project', setSelectData, { type_data: 'user_projects', 'user_id': user.id }, '', select_data, field)
                        } else {
                            getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        }
                        break
                    }

                    if (!select_data?.event?.length && field === 'event' && formik.values?.project_id) {
                        getRequest('event', setSelectData, {
                            type_data: 'all',
                            project: formik.values?.project_id,
                        }, '', select_data, 'event').then((r) => {
                            if (formik.values?.event_id && !findInSelectData(r.data, 'id', formik.values?.event_id)) {
                                setInputFields({ ...formik.values, event_id: null })
                            }
                        })
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values])

    useEffect(() => {
        if (formik.values?.project_id !== project_ref.current && Object.keys(select_data).includes('event')) {
            // formik.setFieldValue('event_id', null);
            setSelectData({ ...select_data, event: [] })
        }
        if (formik.values?.project_id)
            project_ref.current = formik.values?.project_id
    }, [formik.values])

    const saveRid = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_by: user.id }
        } else if (STATUS_PROCESS === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_rid_by: user.id }
        } else {
            payload = formik.values
        }

        updateRequest('rid_internal', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/rid/rid-internal')
                setData(response.data)
            }
        })
        if (formik?.values?.message) {
            putRequest('rid_internal_messages', {
                content: formik?.values?.message,
                rid_status: data.status,
                status: data.status,
                created_by: user.id,
                rid: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <FormikProvider value={formik}>

                <Form>
                    <div className='section-header sticky-top'>
                        <h1 className='page-header'>
                            {`Редактирование записи №${params.id} в БД РИДы`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_RID}
                                       permission_manage={PERMISSIONS.MANAGE_RID}
                        />
                    </div>
                    <div
                        className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                        <HeaderStatus style={{ minWidth: '250px' }}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='rid_message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          disabled={disabled}
                                          rows='5'
                                          value={formik?.values?.message}
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>Личные данные</legend>
                                <Row>
                                    <Col md={12}>
                                        <Input label={'Наименование созданного РИД'} id={'title_ru'}
                                               name={'title_ru'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('title_ru') : false}
                                               handleChangeValue={formik.handleChange}
                                               value={formik.values ? formik.values.title_ru : null}
                                               disabled={disabled}
                                               error={formik.errors['title_ru']}
                                               invalid={formik.errors['title_ru']}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'type'}
                                               label={'Вид РИД'} id={'type'}
                                               isSearchable={true}
                                               options={RID_TYPES}
                                               error={formik.errors['type_id']}
                                               invalid={formik.errors['type_id']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('type') : false}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'document_type'}
                                               label={'Вид документа'} id={'document_type'}
                                               isSearchable={true}
                                               options={RID_DOCUMENT_TYPES}
                                               error={formik.errors['document_type_id']}
                                               invalid={formik.errors['document_type_id']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('document_type') : false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Field component={FormikSelect} name={'project_id'} label={'Проект'}
                                               id={'project_id'}
                                               isSearchable={true}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               error={formik.errors['project']}
                                               invalid={formik.errors['project']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('project') : false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Field component={FormikSelect}
                                               name={'application_status'} label={'Статус подачи'}
                                               id={'application_status'}
                                               isSearchable={true}
                                               options={RID_STATUS}
                                               error={formik.errors['application_status_id']}
                                               invalid={formik.errors['application_status_id']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('application_status') : false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input label={'Заявленный правообладатель'} id={'organization_name'}
                                            name={'organization_name'}
                                            required={data ? REQUIRED_FIELDS[data.status + 1].includes('organization') : false}
                                            handleChangeValue={formik.handleChange}
                                            value={formik.values ? formik.values.organization_name : null}
                                            disabled={disabled}
                                            error={formik.errors['organization_name']}
                                            invalid={formik.errors['organization_name']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'patent_office_id'}
                                               label={'Страна действия патента'} id={'patent_office_id'}
                                               isSearchable={true}
                                               options={generateOptions('patent_office', (element) => {
                                                   if (element?.title_short)
                                                       return `${element.title} (${element.title_short})`
                                                   else
                                                       return `${element.title}`
                                               }, select_data)}
                                               error={formik.errors?.patent_office}
                                               invalid={formik.errors?.patent_office}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('patent_office') : false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input label={'Номер охранного документа / заявки'} id={'number'} name={'number'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('number') : false}
                                               handleChangeValue={formik.handleChange}
                                               disabled={disabled}
                                               error={formik.errors['number']}
                                               invalid={formik.errors['number']}
                                               value={formik.values ? formik.values.number : null}/>
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate} name={'date'}
                                               label={'Дата получения охранного документа / заявки'} id={'date'}
                                               required={data ? REQUIRED_FIELDS[data.status + 1].includes('date') : false}
                                               handleChangeValue={formik.handleChange}
                                               small={'Дата регистрации охранного документа'}
                                               disabled={disabled}
                                               error={formik.errors['date']}
                                               invalid={formik.errors['date']}
                                               value={formik.values ? formik.values.date : null}/>
                                    </Col> 
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Area label={'ФИО авторов'} id={'authors_input'}
                                            name={'authors_input'}
                                            required={data ? REQUIRED_FIELDS[data.status + 1].includes('authors') : false}
                                            handleChangeValue={formik.handleChange}
                                            value={formik.values ? formik.values.authors_input : null}
                                            disabled={disabled}
                                            error={formik.errors['authors_input']}
                                            invalid={formik.errors['authors_input']}
                                        />
                                    </Col>
                                </Row>
                                <Input label={'Ссылка на сопутствующие документы'} id={'related_docs_url'}
                                       name={'related_docs_url'}
                                       handleChangeValue={formik.handleChange}
                                       disabled={disabled}
                                       small={'Скан документа, подтверждающий поле «Вид документа»'}
                                       value={formik.values ? formik.values.related_docs_url : null}/>
                            </fieldset>
                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                <hr/>
                                <MyFileBrowser path={`root/storage/rid/rid_${params.id}`}
                                               read_only={disabled}
                                               instanceId={`rid_${params.id}`}/>
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}
