import { ROLES } from '../../../rbac/constant'
import { Input, InputDate, Select } from '../../../components/form/forms'
import * as Yup from 'yup'

export const STATUS_DRAFT = -1             // Черновик
export const STATUS_NEW = 1                // Новая сущность в БД
export const STATUS_APPROVE = 2            // Рассматривается Дирекцией
export const STATUS_OPEN_OOP = 3           // Открытие ООП
export const STATUS_ADD_RPD = 4            // Добавление РПД
export const STATUS_ACCEPT = 5             // Принято

export const INITIAL_FORMIK_HELPER = {
    type: '',
    change_date: false,
    show_messages: false,
    message: '',
}

export const STATUS_LABELS = {
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Рассматривается',
    [STATUS_OPEN_OOP]: 'Открытие ООП',
    [STATUS_ADD_RPD]: 'Добавление РПД',
    [STATUS_ACCEPT]: 'Принято',
}

export const STATUS_OPTIONS = [
    { value: STATUS_NEW, label: 'Новый' },
    { value: STATUS_APPROVE, label: 'Рассматривается' },
    { value: STATUS_OPEN_OOP, label: 'Открытие ООП' },
    { value: STATUS_ADD_RPD, label: 'Добавление РПД' },
    { value: STATUS_ACCEPT, label: 'Принято' },
]

export const STATUS_BADGE_CLASS = {
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_OPEN_OOP]: 'badge-warning',
    [STATUS_ADD_RPD]: 'badge-info',
    [STATUS_ACCEPT]: 'badge-success',
}

export const BACHELOR = 1
export const MAGISTRACY = 2
export const PHD = 3
export const SPECIALTY = 4

export const EVENT_TYPES = [
    { value: BACHELOR, label: 'Бакалавриат' },
    { value: MAGISTRACY, label: 'Магистратура' },
    { value: PHD, label: 'Аспирантура' },
    { value: SPECIALTY, label: 'Специалитет' },
]

export const IS_NETWORK = [
    { value: 1, label: 'Нет' },
    { value: 2, label: 'Да' },
]

export const LABELS = {
    'id': 'ID',
    'status': 'Статус',
    'event_name': 'Наименование программы',
    'is_network': 'Сетевая',
    'speciality': 'Специальность и направления подготовки',
    'event_type': 'Тип программы',
    'start': 'Начало',
    'project': 'Проект',
    'end': 'Окончание',
    'partners': 'Задействованные в реализации, высокотехнологичные компании партнеры',
    'supervisor': 'Руководитель паспорта',
    'responsible': 'Ответственный за образование',
    'created_by': 'Создано',
    'updated_by': 'Обновлено',
    'created_at': 'Дата создания',
    'updated_at': 'Дата обновления',
    'direction_by': 'Дирекция',
    'message': 'Комментарий',
    'messages': 'Сообщения',

    'presentation': 'Стратегическая сессия (презентация)',
    'matrix_dev': 'Разработка матрицы компетенций',
    'reference': 'Справка-обоснование',
    'characteristic': 'Общая характеристика ООП',
    'curriculum': 'Учебный план',
    'information_pedagogical': 'Справка о кадровом обеспечении',
    'information_specialist': 'Сведения о специалистах-практиках',
    'information_mto': 'Сведения о МТО',
    'information_leader': 'Сведения о руководителе научным содержанием магистерской программы',
    'extract_protocol': 'Выписка из протокола заседания Ученого совета института',
    'order_oop': 'Приказ об открытии ООП',
    'rpd': 'РПД',
    'enrollment_order': 'Приказы о зачислении',
}

export const BLOCKS_IDS = {
    'presentation': 1,
    'matrix_dev': 2,
    'reference': 3,
    'characteristic': 4,
    'curriculum': 5,
    'information_pedagogical': 6,
    'information_specialist': 7,
    'information_mto': 8,
    'information_leader': 9,
    'extract_protocol': 10,
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'number',
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },
    event_type: {
        type: Select,
        options: EVENT_TYPES,
    },
    event_name: {
        type: Input,
        content: 'text',
    },
    speciality: {
        type: Select,
        source: 'speciality',
        key: (v) => `${v?.title}`,
    },
    is_network: {
        type: Select,
        options: IS_NETWORK,
    },
    start: {
        type: InputDate,
        content: 'date',
    },
    end: {
        type: InputDate,
        content: 'date',
    },
    partners: {
        type: Input,
        content: 'text',
    },
    supervisor: {
        type: Select,
        source: 'project',
        key: (v) => `${v?.title_short}`,
    },
    responsible_edu: {
        type: Input,
        content: 'text',
    },
    direction_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => `${v?.fio}`,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_NEW]: [],
    [STATUS_APPROVE]: [],
    [STATUS_OPEN_OOP]: [],
    [STATUS_ADD_RPD]: [],
    [STATUS_ACCEPT]: [],
}

export const CAN_MANAGE_ROLES = {
    [STATUS_NEW]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction, ROLES.direction_oop],
    [STATUS_OPEN_OOP]: [ROLES.admin, ROLES.project_manager, ROLES.direction, ROLES.direction_oop],
    [STATUS_ADD_RPD]: [ROLES.admin, ROLES.project_manager, ROLES.direction, ROLES.direction_oop],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}

export const CAN_ADMINISTRATE_ROLES = [ROLES.admin, ROLES.project_manager, ROLES.direction, ROLES.direction_oop]

const BLOCK_DATA = Yup.object({
    type: Yup.string().nullable(),
    change_date: Yup.boolean().nullable(),
    show_messages: Yup.boolean().nullable(),
    message: Yup.string().nullable(),
})

const Schema = Yup.object().shape({
    status: Yup.number().test('conditional-status', `Для того чтобы переместить запись в статус "Добавление РПД" или далее,
     необходимо 100% готовность к реализации ООП`, function (val, context) {
        if (!context.parent.blocks) return false
        if (val >= STATUS_ADD_RPD && context.parent.blocks) {
            let blocks_number = (context.parent.event_type && context.parent.event_type === MAGISTRACY) 
                                ? BLOCKS.length 
                                : BLOCKS.length - 1 
            return calculateProgress(context.parent, blocks_number) > 99
        }
        return true
    }).test('conditional-rpd', `Для того чтобы переместить запись в статус "Принято",
     необходимо 100% прогресс РПД`, function (val, context) {
        if (val >= STATUS_ACCEPT && context.parent.blocks) {
            return context.parent.rpd_progress > 99
        }
        return true
    }),
    // Возможно, еще понадобится
    // is_upload: Yup.boolean().test('conditional-is-upload', 'Загрузите файл приказа об открытии ООП',
    //     function (val, context) {
    //         if (context.parent.status >= STATUS_ADD_RPD)
    //             return !val && typeof val !== 'undefined';
    //         return true;
    //     }).nullable(),

    message: Yup.string().when(['status', 'current_status'], (status, schema) => {
        return (status[0] < status[1]) ? schema.required('Обязательное поле!') : schema
    }),
    rpd_progress: Yup.number().nullable(),
    blocks: Yup.object({
        matrix_dev: BLOCK_DATA,
        reference: BLOCK_DATA,
        characteristic: BLOCK_DATA,
        curriculum: BLOCK_DATA,
        information_pedagogical: BLOCK_DATA,
        information_specialist: BLOCK_DATA,
        information_mto: BLOCK_DATA,
        information_leader: BLOCK_DATA,
        extract_protocol: BLOCK_DATA,
    }).nullable(),
})

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_NEW]: Schema,
    [STATUS_APPROVE]: Schema,
    [STATUS_OPEN_OOP]: Schema,
    [STATUS_ADD_RPD]: Schema,
    [STATUS_ACCEPT]: Schema,
}

export const BLOCKS = [
    'presentation',
    'matrix_dev',
    'reference',
    'characteristic',
    'curriculum',
    'information_pedagogical',
    'information_specialist',
    'information_mto',
    'information_leader',
    'extract_protocol',
]

export const calculateProgress = (values, blocks_number) => {
    let percent = 0
    for (let block of BLOCKS) {
        percent += values?.blocks?.[block]?.progress ? Number(values?.blocks?.[block]?.progress) : 0
    }
    return (percent / blocks_number).toFixed(0)
}

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]
