import Select from 'react-select'
import * as React from 'react'
import { Card, Form, Overlay } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import CreatableSelect from 'react-select/creatable'
import { useRef, useState } from 'react'

export const FormikSelect = ({
                                 options,
                                 field,
                                 form,
                                 label,
                                 error,
                                 small,
                                 id,
                                 style,
                                 compare = null,
                                 placeholder = '',
                                 required = false,
                                 invalid = false,
                                 disabled = null,
                                 isSearchable = true,
                                 isClearable = true,
                                 isMulti = false,
                                 closeMenuOnSelect = true,
                                 selectAllOption = false,
                                 onMenuScrollToBottom = null,
                                 onMenuScrollToTop = null,
                                 submit_on_change = false,
                                 isCreatable = false,
                                 menuPortalTarget,
                             }) => {

    const SelectType = isCreatable ? CreatableSelect : Select

    const customStyles = {
        control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            borderColor: state.isFocused
                ? '#ddd' : !form.errors[field.name] && !invalid
                    ? '#ddd' : '#dc3545',
            backgroundColor: disabled ? '#e9ecef' : 'hsl(0, 0%, 100%)',
            '&:hover': {
                borderColor: state.isFocused
                    ? '#ddd' : !form.errors[field.name] && !invalid
                        ? '#ddd' : '#dc3545',
            },
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: 'none',
        }),
    }

    const selectAll = {
        value: 'All',
        label: 'Выбрать все',
    }

    const [searchParams, _] = useSearchParams()

    const onChangeSelect = (new_value) => {
        if (
            new_value !== null
            && new_value.length > 0
            && new_value?.[new_value.length - 1]?.value === selectAll.value
        ) {
            let temp = []
            for (let index in options) {
                if (!options[index]?.isDisabled) {
                    temp.push(options[index])
                }
            }
            return onChangeSelect(temp)
        }
        if (isMulti) {
            form.setFieldValue(field.name,
                (new_value).map((item) => item.value),
            )
        } else {
            form.setFieldValue(field.name, new_value?.value ? new_value.value : null)
        }
        if (submit_on_change) {
            form.submitForm()
        }
    }

    const HoverOption = ({ option }) => {
        const [showTooltip, setShowTooltip] = useState(false)
        const target = useRef(null)

        return (<div className={'position-relative'}>
            <div ref={target}
                 onMouseLeave={() => setShowTooltip(false)}
                 onMouseEnter={() => setShowTooltip(true)}>
                {option?.label}
            </div>
            <Overlay target={target.current} show={showTooltip} placement='top'>
                {({
                      arrowProps: _arrowProps,
                      show: _show,
                      popper: _popper,
                      hasDoneInitialMeasure: _hasDoneInitialMeasure,
                      ...props
                  }) => (
                    <Card {...props}
                          style={{
                              position: 'absolute',
                              zIndex: '9999',
                              ...props.style,
                          }}>
                        <Card.Body>{option.hover}</Card.Body>
                    </Card>
                )}
            </Overlay>
        </div>)
    }

    const getOption = (option) => option?.hover
        ? (<HoverOption option={option}/>)
        : option?.label

    return (
        <div className={`form-group ${required ? 'required' : ''}`}>
            <Form.Label>{label}</Form.Label>
            <SelectType
                formatOptionLabel={getOption}
                onBlur={field?.onBlur}
                onChange={onChangeSelect}
                options={selectAllOption && options ? [selectAll, ...options] : options}
                value={(() => {
                    if (!options) return ''
                    if (isMulti) return options.filter((option) => {
                        return field.value ? field.value.indexOf(option.value) >= 0 : false
                    })
                    for (let optionsLength = options.length, i = 0; i < optionsLength; i++) {
                        const option = options[i]
                        if (compare) {
                            let res = compare(option.value, field.value)
                            if (res) return res
                        } else {
                            if (option.options) {
                                const valueCandidate = option.options.find(({ value }) =>
                                    value === field.value)
                                if (valueCandidate) return valueCandidate
                            }
                            if (option.value === field.value) return option
                        }
                    }
                    return ''
                })()}
                styles={{ ...customStyles, ...style }}
                selectAllOption={selectAllOption}
                selectID={id}
                placeholder={placeholder}
                isSearchable={isSearchable}
                menuPortalTarget={menuPortalTarget}
                aria-invalid={invalid}
                controlShouldRenderValue={true}
                hideSelectedOptions={false}
                closeMenuOnSelect={closeMenuOnSelect}
                isMulti={isMulti}
                isLoading={!options}
                isClearable={isClearable}
                onMenuScrollToBottom={onMenuScrollToBottom}
                onMenuScrollToTop={onMenuScrollToTop}
                isDisabled={!options ? true : disabled === null
                    ? searchParams.has('update') && searchParams.get('update') === 'false'
                    : disabled}
            />
            {form.errors[field.name]
                ? <small className='form-text text-danger'>{form.errors[field.name]}</small> : null}
            {error && !form.errors[field.name]
                ? <small className='form-text text-danger'>{error}</small> : null}
            {small ? <small className='form-text text-muted'>{small}</small> : null}
        </div>
    )
}


export const FormikDate = (
    {
        field,
        form,
        label,
        id,
        showMonthYear = false,
        placeholderText = '',
        type = '',
        step = '',
        onKeyPress,
        required = false,
        required_on_transition = false,
        invalid = false,
        disabled = null,
        small = '',
        error = '',
        className = '',
        classNameField = '',
        submit_on_change = false,
        onBlur,
        formatChars,
    },
) => {
    function createDateAsUTC(date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
            date.getHours(), date.getMinutes(), date.getSeconds()))
    }

    const [searchParams, _] = useSearchParams()

    return <div
        className={`form-group ${required_on_transition ? 'required-on-transition' : ''} 
        ${required ? 'required' : ''} 
        ${className}`}>
        <Form.Label>{label}</Form.Label>
        <Form.Control id={id}
                      as={DatePicker}
                      isInvalid={invalid}
                      placeholderText={placeholderText}
                      dateFormat={showMonthYear ? 'MM.yyyy' : 'dd.MM.yyyy'}
                      showMonthYearPicker={showMonthYear}
                      onChange={(date) => {
                          if (date && date instanceof Date && !isNaN(date)) {
                              if (date?.getYear() >= 999) {
                                  date.setYear(new Date().getYear() + 1900)
                              } else if (date?.getYear() <= 0) {
                                  date.setYear(1900)
                              }
                              form.setFieldValue(field.name, createDateAsUTC(date))
                              if (submit_on_change) {
                                  form.submitForm()
                              }
                          } else {
                              form.setFieldValue(field.name, null)
                          }
                      }}
                      type={type}
                      step={step}
                      onBlur={onBlur}
                      onKeyDown={onKeyPress}
                      disabled={disabled === null
                          ? searchParams.has('update') && searchParams.get('update') === 'false'
                          : disabled}
                      selected={field?.value ? new Date(field.value) : undefined}
                      formatChars={formatChars}
                      className={classNameField}
        />
        {error ? <small className='form-text text-danger'>{error}</small> : null}
        {small ? <small className='form-text text-muted'>{small}</small> : null}
    </div>
}

export const FormikRadio = (
    {
        field,
        form,
        label,
        name,
        id,
        type,
        onKeyPress,
        required = false,
        required_on_transition = false,
        invalid = false,
        disabled = null,
        small = '',
        error = '',
        className = '',
        value = null,
        set_value = null,
        onBlur,
        formatChars,
    },
) => {

    const [searchParams, _] = useSearchParams()

    return <div
        className={`form-group ${required_on_transition ? 'required-on-transition' : ''} 
        ${required ? 'required' : ''} 
        ${className}`}>
        <Form.Check id={id}
                    isInvalid={invalid}
                    onChange={(event) => {
                        form.setFieldValue(field.name, event.target?.value
                            ? event.target?.value
                            : null)
                    }}
                    value={value}
                    checked={form.values?.[field.name] == set_value}
                    label={label}
                    onBlur={onBlur}
                    type={type}
                    name={field.name}
                    onKeyDown={onKeyPress}
                    disabled={disabled === null
                        ? searchParams.has('update') && searchParams.get('update') === 'false'
                        : disabled}
                    selected={field?.value ? new Date(field.value) : undefined}
                    formatChars={formatChars}
        />
        {error ? <small className='form-text text-danger'>{error}</small> : null}
        {small ? <small className='form-text text-muted'>{small}</small> : null}
    </div>
}
