import { Button, ButtonGroup, Card, Col, Row } from 'react-bootstrap'
import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { FormikSelect } from '../form/formik'
import { DATABASES, IndicatorCalculationSchema } from '../../screens/indicator/indicator/constant'
import { Input } from '../form/forms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { postRequest } from '../../actions/common'

const CalculationBlock = ({
                              indicator,
                              formulaName = 'calculation_formula',
                              title,
                              type,
                          }) => {
    const [show_info, setShowInfo] = useState(false)
    const [is_hidden, setIsHidden] = useState(true)

    useEffect(() => {
        if (indicator?.[type]) {
            formik.setValues(indicator?.[type])
        }
    }, [indicator])

    const formik = useFormik({
        initialValues: {
            [formulaName]: {
                calculations: [{
                    name: 'value_1', label: 'Значение', value: '', database: '', conditions: [{
                        name: 'cond_1', label: 'Условие 1', value: '',
                    }],
                }],
                actions: '',
            },
        },
        onSubmit: (values) => {
            postRequest('calculations', () => {
            }, { ...values, type: type }, indicator.id).then((r) => {
                if (r?.status === 400) {
                    formik.setErrors(r?.data)
                }
            })
        },
        validationSchema: IndicatorCalculationSchema(formulaName),
        validateOnBlur: false, validateOnChange: false,
    })


    return <fieldset>
        <legend>{title}</legend>
        <div className={'mb-2'}>
            <ButtonGroup>
                <Button className={show_info ? 'mb-2' : ''}
                        onClick={() => setShowInfo(!show_info)}>
                    Справка
                </Button>
                <Button variant={'outline-primary'} className={show_info ? 'mb-2' : ''}
                        onClick={() => {
                            setIsHidden(!is_hidden)
                        }}>
                    {is_hidden ? 'Показать блок' : 'Скрыть блок'}
                </Button>
            </ButtonGroup>
            {show_info && <Card>
                <Card.Body>
                    Доступные функции для поля значение:
                    <ul>
                        <li>КОЛ - Количество записей базы данных</li>
                        <li>СУММ - Сумма поля записей базы данных</li>
                    </ul>
                    Пример заполнения:
                    <div className={'indicator-example'}/>
                </Card.Body>
            </Card>}
        </div>
        <FormikProvider value={formik}>
            <FieldArray
                name={'calculation_formula.calculations'}
                render={(arrayHelpers) => (<>
                    <Button variant={'success'} className={'mb-1'} onClick={() => {
                        let new_index = formik.values.calculation_formula.calculations.length + 1
                        arrayHelpers.push({
                            name: `value_${new_index}`,
                            label: 'Значение',
                            value: '',
                            database: '',
                            conditions: [{
                                name: 'cond_1', label: 'Условие 1', value: '',
                            }],
                        })
                    }}>
                        Добавить значение
                    </Button>
                </>)}/>
            <hr/>
            {is_hidden ? null : <>
                {formik.values.calculation_formula.calculations.map((formula, index) => {
                    return <div key={`formula_${formula.name}`}>
                        <FieldArray
                            name={'calculation_formula.calculations'}
                            render={(arrayHelpers) => (<>
                                <legend>
                                    {index !== 0
                                        ? (<Button variant={'danger'} className={'mr-2'} 
                                            onClick={() => {
                                                arrayHelpers.remove(index)
                                            }}>
                                                <FontAwesomeIcon icon={'trash-alt'}/>
                                        </Button>)
                                        : null}
                                    Значение {index + 1}
                                </legend>
                            </>)}/>

                        <FieldArray
                            name={`calculation_formula.calculations.${index}.conditions`}
                            render={(arrayHelpers) => (<>
                                <ButtonGroup>
                                    <Button variant={'success'} className={'mb-3'}
                                            onClick={() => {
                                                let new_index = formula.conditions?.length
                                                arrayHelpers.push({
                                                    name: `cond_${new_index + 1}`,
                                                    label: `Условие ${new_index + 1}`,
                                                    union: '',
                                                    value: '',
                                                })
                                            }}>
                                        Добавить условие
                                    </Button>
                                </ButtonGroup></>)}/>
                        <Field component={FormikSelect} label={'База данных'}
                               required
                               id={`calculation_formula.calculations.${index}.database`}
                               name={`calculation_formula.calculations.${index}.database`}
                               error={formik.errors?.calculation_formula?.calculations?.[index]?.database}
                               invalid={!!formik.errors?.calculation_formula?.calculations?.[index]?.database}
                               options={Object.keys(DATABASES).map((key) => {
                                   return { value: key, label: DATABASES[key] }
                               })}/>
                        <Row>
                            <Col>
                                <Input id={`calculation_formula.calculations.${index}.value`}
                                       label={formula.label}
                                       required
                                       error={formik?.errors?.calculation_formula?.calculations?.[index]?.value}
                                       invalid={formik?.errors?.calculation_formula?.calculations?.[index]?.value}
                                       handleChangeValue={formik.handleChange}
                                       value={formula.value}/>
                            </Col>
                            <Col>
                                <FieldArray
                                    name={`calculation_formula.calculations.${index}.conditions`}
                                    render={(arrayHelpers) => (<>
                                        {formula.conditions.map((cond, c_index) => {
                                            return <Row key={`formula_cond_${cond.name}`}>
                                                <Col>
                                                    <Input
                                                        required
                                                        id={`calculation_formula.calculations.${index}.conditions.${c_index}.value`}
                                                        label={`Условие ${c_index + 1}`}
                                                        error={formik?.errors?.calculation_formula?.calculations
                                                            ?.[index]?.conditions?.[c_index]?.value}
                                                        handleChangeValue={formik.handleChange}
                                                        value={cond.value}/>
                                                </Col>
                                                {c_index === formula.conditions.length - 1
                                                    ? null
                                                    : <Col>
                                                        <Field component={FormikSelect}
                                                               label={'И / Или'}
                                                               required
                                                               id={`calculation_formula.calculations.${index}.conditions.${c_index}.union`}
                                                               error={formik.errors?.calculation_formula?.calculations?.[index]?.conditions?.[c_index]?.union}
                                                               invalid={!!formik.errors?.calculation_formula?.calculations?.[index]?.conditions?.[c_index]?.union}
                                                               name={`calculation_formula.calculations.${index}.conditions.${c_index}.union`}
                                                               options={[{
                                                                   value: 'AND',
                                                                   label: 'И',
                                                               }, { value: 'OR', label: 'Или' }]}/>
                                                    </Col>}
                                                <Col md={1} style={{ paddingTop: '27px' }}>
                                                    <Button variant={'danger'} onClick={() => {
                                                        arrayHelpers.remove(c_index)
                                                    }}>
                                                        <FontAwesomeIcon icon={'trash-alt'}/>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        })}
                                    </>)}/>
                            </Col>
                        </Row>
                        {index !== formik.values.calculation_formula.calculations.length - 1
                            && <hr/>
                        }
                    </div>
                })}
                <hr/>
                <div>
                    Для составления формулы используйте следующие переменные:
                    <ul>
                        {formik.values.calculation_formula.calculations.map((c) => <li>
                            {c.name}
                        </li>)}
                    </ul>
                </div>
                <Input label={'Действия со значениями'} id={'calculation_formula.actions'}
                       value={formik.values.calculation_formula.actions}
                       required
                       error={formik.errors?.calculation_formula?.actions}
                       invalid={!!formik.errors?.calculation_formula?.actions}
                       handleChangeValue={formik.handleChange}/>
                <Button variant={'success'} onClick={() => formik.submitForm()}>
                    Сохранить
                </Button>
                <hr/>
            </>}
        </FormikProvider>
    </fieldset>
}

export default CalculationBlock
