import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const IconText = ({ text, icon, icon_color=null, style=null }) => {
    return (
        <React.Fragment>
            <div className={'d-inline-block svg-30-container'} style={style}>
                <FontAwesomeIcon icon={icon} color={icon_color}/>
            </div>
            {text}
        </React.Fragment>
    )
}
