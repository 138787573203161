import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'
import { getRequest, putRequest } from '../../../actions/common'
import {
    STATUS_NEW,
    STATUS_ACCEPT,
    STATUS_LABELS, FINANCING_DOCUMENT_TYPES,
} from './constant'
import { Check, Input, Select } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { useAbac } from 'react-abac'
import { setChangedValue } from '../../../utils/utils'
import { AuthContext } from '../../../auth'
import { REQUIRED_FIELDS } from './constant'
import { toast } from 'react-toastify'

export const FinancingCreate = (props) => {
    const { state } = useLocation()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()
    const navigate = useNavigate()

    const [input_values, setInputValues] = useState({ common: {} })
    const [select_data, setSelectData] = useState({})
    const [select_values, setSelectValues] = useState({})
    const [count_stages, setCountStages] = useState(1)
    const [stages, setStages] = useState([])
    const [is_stages, setIsStages] = useState(false)
    const [errors, setErrors] = useState(() => {
        let temp = {}
        REQUIRED_FIELDS[STATUS_ACCEPT].forEach((field) => {
            temp[field] = null
        })
        return temp
    })

    const select_fields = ['project', 'event', 'person']

    useEffect(() => {
        generateContractStages()
    }, [count_stages])

    useEffect(() => {
        if (!userHasPermissions(PERMISSIONS.MANAGER_PANEL)) {
            navigate('/access-error')
        } else {
            document.title = 'Создание записи в БД Cофинансирование | ИС «ПИШ»'
            if (user?.project?.id) {
                setSelectValues({ ...select_values, project: user?.project?.id })
            }
        }
    })

    useEffect(() => {
        if (Object.keys(select_data).length < select_fields.length) {
            for (let field of select_fields) {
                if (!Object.keys(select_data).includes(field)) {
                    getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                    break
                }
            }
        }
    }, [select_data])

    const getStatusClassName = (status) => {
        if (status === STATUS_NEW) {
            return 'bg-primary'
        } else if (status < STATUS_NEW) {
            return 'bg-success'
        } else if (status > STATUS_NEW) {
            return 'bg-secondary'
        }

        return null
    }

    const handleChangeValue = (e) => {
        e.preventDefault()
        let target = e.target.getAttribute('data_change_key')
        let sub_key = e.target.getAttribute('data_foreign_id')
        let index = e.target.getAttribute('data_list_index')
        setChangedValue(setInputValues, input_values, target, e.target.id, e.target.value, sub_key, index)
    }

    const generateOptions = (field, key) => {
        if (select_data && Object.keys(select_data).includes(field)) {
            let options = []
            for (let element of select_data[field]) {
                options.push({ value: element.id, label: element[key] })
            }
            return options
        }
        return null
    }

    const checkErrors = (status) => {
        let new_errors = {}
        let expression
        let has_errors = false

        REQUIRED_FIELDS[status].forEach((field) => {
            if (typeof select_values[field] === 'object') {
                expression = !select_values[field]?.length
            } else {
                expression = !document.getElementById(field)?.value && (!select_values[`${field}_id`] && !select_values[field])
            }
            if (expression) {
                new_errors[field] = 'Обязательное поле!'
                has_errors = true
            } else {
                new_errors[field] = null
            }
        })
        setErrors({ ...errors, ...new_errors })
        return has_errors
    }

    const saveFinancing = () => {
        let payload = { ...input_values['common'], ...(select_values) }
        payload['status'] = state?.monitoring ? STATUS_ACCEPT : STATUS_NEW
        putRequest('financing', payload).then((response) => {
            if (response.status === 201) {
                navigate(`/financing/item/${response.data.id}?update=true`)
            }
        })
    }

    const handleSumitForm = (e) => {
        e.preventDefault()
        if (checkErrors(STATUS_NEW)) {
            toast.error('Ошибка в заполении данных!')
            return null
        }
        saveFinancing()
    }

    const Stage = ({ number }) => {
        let value = input_values?.common?.contract_stages ? input_values?.common?.contract_stages[number - 1] : null
        return <React.Fragment>
            <p>Этап {number}</p>
            <Row>
                <Col md={6}>
                    <Input handleChangeValue={handleChangeValue}
                           label={'Номер этапа'}
                           id={'stage_number'}
                           value={value ? value?.stage_number : null}
                           data_foreign_id={'contract_stages'}
                           data_list_index={number - 1}
                    />
                    <Input handleChangeValue={handleChangeValue}
                           label={'Дата начала'}
                           type={'date'}
                           id={'date_start'}
                           value={value ? value?.date_start : null}
                           data_foreign_id={'contract_stages'}
                           data_list_index={number - 1}
                    />
                </Col>
                <Col md={6}>
                    <Input handleChangeValue={handleChangeValue}
                           label={'Сумма'}
                           type={'number'}
                           id={'cost'}
                           value={value ? value?.cost : null}
                           data_foreign_id={'contract_stages'}
                           data_list_index={number - 1}
                    />
                    <Input handleChangeValue={handleChangeValue}
                           label={'Дата окончания'}
                           type={'date'}
                           id={'date_end'}
                           value={value ? value?.date_end : null}
                           data_foreign_id={'contract_stages'}
                           data_list_index={number - 1}
                    />
                </Col>
            </Row>
            <hr/>
        </React.Fragment>
    }

    const generateContractStages = () => {
        let elements = []
        if (stages.length > count_stages && count_stages > 0) {
            setStages(stages.slice(0, count_stages))
            let new_stages = input_values
            if (input_values?.common?.contract_stages) {
                new_stages = new_stages?.common?.contract_stages.slice(0, count_stages)
                setInputValues({ ...input_values, common: { contract_stages: new_stages } })
            }

        } else if (stages.length < count_stages) {
            for (let i = 0; i < count_stages - stages.length; i++) {
                elements.push(<Stage key={Math.random()} number={count_stages}/>)
            }
            if (elements.length) {
                setStages([...stages, ...elements])
            }
        }
    }

    const Stages = () => {
        return <React.Fragment>
            <Check label={'Без этапов'} value={is_stages} handleChangeValue={(event) => {
                setIsStages(event.target.checked)
                if (event.target.checked) {
                    setCountStages(1)
                    let new_input = input_values
                    delete new_input?.common?.contract_stages
                    setInputValues(new_input)
                }
            }}/>
            {
                !is_stages ? <React.Fragment>
                        <div className={'mb-4'}>
                            <Button variant={'success'} onClick={() => {
                                setCountStages(count_stages + 1)
                            }}>Добавить этап</Button>
                            <Button variant={'danger'} onClick={() => {
                                if (count_stages > 1) {
                                    setCountStages(count_stages - 1)
                                }
                            }} className={'ml-2'}>Удалить этап</Button>
                        </div>
                    </React.Fragment>
                    : null
            }
            {stages}
        </React.Fragment>
    }

    const HeaderStatus = ({ style }) => {
        let elements = []
        for (let index in STATUS_LABELS) {
            let status = Number(index)
            if (status !== STATUS_NEW) {
                elements.push(
                    <FontAwesomeIcon key={`arrow_${status}`} icon={'long-arrow-alt-right'}
                                     className={'text-dark mb-2 fa-2x'}/>,
                )
            }
            elements.push(
                <div key={`status_${status}`} className='mx-2 mb-2' style={style}>
                    <p className={`m-0 p-2 rounded ${getStatusClassName(status)}`}>{STATUS_LABELS[status]}</p>
                </div>,
            )
        }
        return elements
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <Form onSubmit={handleSumitForm}>
                <div className='section-header sticky-top'>
                    <h1 className='page-header'>
                        {'Создание записи в БД Доход / Софинансирование'}
                    </h1>
                    <div className='button-actions'>
                        <Button variant='success' type='submit'>Создать</Button>
                    </div>
                </div>
                <div
                    className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                    <HeaderStatus style={{ width: '200px' }}/>
                </div>

                <Card className={'bg-light mb-3 mt-3'}>
                    <Card.Body>
                        <p>
                            <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                            условные обозначения,
                            используемые в интерфейсе:
                        </p>
                        <ul>
                            <li>поля помеченные <strong className='text-danger'>красной «*»</strong> являются <strong>обязательными
                                на текущем
                                этапе для перехода на следующий</strong>;
                            </li>
                            <li>поля помеченные <strong className='text-success'>зелёной «*»</strong> являются <strong>обязательными
                                для
                                определенных условий</strong>;
                            </li>
                            <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет сохранение
                                текущего состояния карточки (требуется
                                заполнения ключевых полей);
                            </li>
                            <li>кнопка <span
                                className='badge badge-success'>Сохранить + отправить далее</span> сохраняет карточку и
                                проверяет выполнение
                                условий текущего этапа (при выполнении условий карточка переходит на следующий этап).
                            </li>
                        </ul>
                        <hr/>
                        <fieldset>
                            <legend>Данные договора</legend>
                            <Row>
                                <Col md={12}>
                                    <Select label={'Проект'} id={'project'}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={generateOptions('project', 'title_short')}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['project']}
                                            invalid={errors['project']}
                                            disabled={user ? user?.project?.id : false}
                                            required={true}/>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Select label={'Мероприятие'} id={'event'}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={generateOptions('event', 'title')}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['event']}
                                            invalid={errors['event']}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('event')}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Select label={'Исполнитель'} id={'person'}
                                            isClearable={true}
                                            options={generateOptions('person', 'fio')}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            error={errors['person']}
                                            invalid={errors['person']}
                                            isSearchable={true}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('person')}
                                    />
                                </Col>
                            </Row>
                            <Input handleChangeValue={handleChangeValue}
                                   label={'Заказчик (контрагент)'}
                                   id={'customer'}
                                   error={errors['customer']}
                                   invalid={errors['customer']}
                                   required={REQUIRED_FIELDS[STATUS_NEW].includes('customer')}
                            />
                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Номер договора'}
                                           id={'contract_number'}
                                           error={errors['contract_number']}
                                           invalid={errors['contract_number']}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_number')}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Дата договора'}
                                           type={'date'}
                                           id={'contract_date'}
                                           error={errors['contract_date']}
                                           invalid={errors['contract_date']}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_date')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Дата начала работ'}
                                           type={'date'}
                                           id={'work_date_start'}
                                           error={errors['work_date_start']}
                                           invalid={errors['work_date_start']}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('work_date_start')}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Дата окончания работ'}
                                           type={'date'}
                                           id={'work_date_end'}
                                           error={errors['work_date_end']}
                                           invalid={errors['work_date_end']}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('work_date_end')}
                                    />
                                </Col>
                            </Row>
                            <Input handleChangeValue={handleChangeValue}
                                   label={'Лицевой счёт'}
                                   id={'account'}
                                   error={errors['account']}
                                   invalid={errors['account']}
                                   required={REQUIRED_FIELDS[STATUS_NEW].includes('account')}
                            />
                            <Row>
                                <Col md={6}>
                                    <Select label={'Вид договора'}
                                            isClearable={true}
                                            isSearchable={true}
                                            select_values={select_values}
                                            setSelectValues={setSelectValues}
                                            options={FINANCING_DOCUMENT_TYPES}
                                            id={'contract_type'}
                                            error={errors['contract_type']}
                                            invalid={errors['contract_type']}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_type')}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Input handleChangeValue={handleChangeValue}
                                           label={'Предмет договора'}
                                           id={'contract_subject'}
                                           error={errors['contract_subject']}
                                           invalid={errors['contract_subject']}
                                           required={REQUIRED_FIELDS[STATUS_NEW].includes('contract_subject')}
                                    />
                                </Col>
                            </Row>
                        </fieldset>
                        <fieldset>
                            <legend>Этапы договора</legend>
                            <Stages/>
                        </fieldset>
                        <fieldset>
                            <legend>Прикрепленные файлы</legend>
                            <hr/>
                        </fieldset>
                    </Card.Body>
                </Card>
            </Form>
        </React.Fragment>
    )
}
