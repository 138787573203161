import * as React from 'react'
import { UploadFile } from '../../components/file_manager/file_handlers'
import { uploadFile } from '../../actions/file_manager'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Form } from 'react-bootstrap'
import Breadcrumbs from '../../components/breadcrumbs'
import { LoaderContext } from '../../utils/loader'
import { toast } from 'react-toastify'

export const AbstractImport = () => {
    const [file, setFile] = useState(null)
    const navigate = useNavigate()
    const setShowLoading = useContext(LoaderContext)

    const handleChangeValue = (e) => {
        e.preventDefault()
        if (e.target.type === 'file') {
            setFile(e.target.files[0])
        }
    }

    const onSubmitForm = (e) => {
        e.preventDefault()

        let formData = new FormData()
        formData.append('file', file)
        setShowLoading(true)
        uploadFile('/api/import/', formData, () => {
        }).then((response) => {
            if (response?.status === 200) {
                toast.success('Данные успешно сохранены')
            } else if (!response) {
                toast.warning('Время ожидания истекло')
            }
            setShowLoading(false)
            navigate('/')
        })
    }


    return <React.Fragment>
        <Breadcrumbs/>
        <div className='section-header sticky-top'>
            <h1 className='page-header'>
                Импорт данных
            </h1>
        </div>
        <Form onSubmit={onSubmitForm}>
            <Card>
                <Card.Body>
                    <label>Файл импорта</label>
                    <UploadFile
                        handleChangeValue={handleChangeValue}
                        file_name={file?.name}
                        id={'upload_file'}
                    />
                </Card.Body>
                <Card.Footer>
                    <div className={'button-actions'}>
                        <Button variant={'success'} type={'submit'}>Далее</Button>
                        <Button variant={'secondary'} onClick={(e) => {
                            e.preventDefault()
                            window.location.reload()
                        }}>
                            Сбросить
                        </Button>
                    </div>
                </Card.Footer>
            </Card>
        </Form>
    </React.Fragment>
}
