import * as React from 'react'
import {
    Button,
    Card, Col, Row,
} from 'react-bootstrap'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Input } from '../../../components/form/forms'
import { putRequest } from '../../../actions/common'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Field, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { FormikSelect } from '../../../components/form/formik'

const ReportFinCreate = () => {
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            title: '',
            is_total: null,
            type: null,
            quarter: null,
            year: null,
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required('Обязательное поле!'),
            is_total: Yup.number().required('Обязательное поле!'),
            type: Yup.number().required('Обязательное поле!'),
            quarter: Yup.number().required('Обязательное поле!'),
            year: Yup.number().required('Обязательное поле!'),
        }),
        onSubmit: (values) => saveReportFin(),
        validateOnChange: false,
        validateOnBlur: false,
    })


    const saveReportFin = () => {
        putRequest('report_fin', formik.values).then((response) => {
            if (response.status === 201) {
                navigate('/indicator/report_fin')
            }
        })
    }

    React.useEffect(() => {
        if (!formik.isValid) {
            toast.error('Ошибка в заполнении данных!')
        }
    }, [formik.isValid])

    const quarter_options = [
        { value: 1, label: '1 квартал' },
        { value: 2, label: '2 квартал' },
        { value: 3, label: '3 квартал' },
        { value: 4, label: '4 квартал' },
    ]

    const is_total_options = [
        { value: 1, label: 'Да' },
        { value: 2, label: 'Нет' },
    ]

    const type_options = [
        { value: 1, label: 'Внутренний' },
        { value: 2, label: 'Мин. обр.' },
    ]

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                Сгенерировать отчет
            </h1>

            <Card>
                <FormikProvider value={formik}>
                    <Card.Body>
                        <Input required={true}
                               id={'title'}
                               name={'title'}
                               label={'Заголовок'}
                               value={formik.values.title}
                               handleChangeValue={formik.handleChange}
                               error={formik.errors.title}
                               invalid={formik.errors.title}
                        />
                        <Row>
                            <Col md={6}>
                                <Field component={FormikSelect} required={true}
                                       id={'is_total'}
                                       name={'is_total'}
                                       label={'Нарастающм итогом'}
                                       options={is_total_options}
                                       error={formik.errors.is_total}
                                       invalid={formik.errors.is_total}
                                />
                            </Col>
                            <Col md={6}>
                                <Field component={FormikSelect} required={true}
                                       id={'type'}
                                       name={'type'}
                                       label={'Тип'}
                                       options={type_options}
                                       error={formik.errors.type}
                                       invalid={formik.errors.type}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Field component={FormikSelect} required={true}
                                       id={'quarter'}
                                       name={'quarter'}
                                       label={'Квартал'}
                                       options={quarter_options}
                                       error={formik.errors.quarter}
                                       invalid={formik.errors.quarter}
                                />
                            </Col>
                            <Col md={6}>
                                <Input required={true}
                                       id={'year'}
                                       name={'year'}
                                       type={'number'}
                                       label={'Год'}
                                       value={formik.values.year}
                                       handleChangeValue={formik.handleChange}
                                       error={formik.errors.year}
                                       invalid={formik.errors.year}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </FormikProvider>
                <Card.Footer>
                    <div className={'button-actions'}>
                        <Button variant={'success'} onClick={formik.submitForm}>Сгенерировать отчет</Button>
                        <Button variant={'secondary'} onClick={() => {
                            formik.setValues(formik.initialValues)
                        }}>Сбросить</Button>
                    </div>
                </Card.Footer>
            </Card>
        </React.Fragment>
    )
}

export default ReportFinCreate
