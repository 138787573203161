import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Card, Col, Form, Row, Modal } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { useEffect, useRef, useState } from 'react'
import { getRequest, putRequest, updateRequest, deleteRequest } from '../../../actions/common'
import { Area, Check, Input } from '../../../components/form/forms'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    STATUS_ACCEPT,
    STATUS_PROCESS,
    STATUS_CHECK,
    MONTHS,
    CAN_APPROVE_ROLES,
    CAN_MANAGE_ROLES_COMMON,
    CAN_MANAGE_ROLES_RESULTS,
    STATUS_NEW,
    SCIENCE_DIRECTIONS,
} from './constant'
import { btnMessage } from '../../../utils/utils'
import { REQUIRED_FIELDS } from './constant'
import { Field, FieldArray, FormikProvider, useFormik, Formik } from 'formik'
import { REQUIRED_FIELDS_TEMP } from './constant'
import { FormikSelect } from '../../../components/form/formik'
import { toast } from 'react-toastify'

export const NiokrItem = ({
                              data,
                              setData,
                              user,
                              disabled,
                              messages,
                              handleChangeValue,
                              generateOptions,
                              ErrorsBlock,
                              MessageHistory,
                              ActionButtons,
                              DisableButton,
                              handleSubmit,
                              HeaderStatus,
                          }) => {
    const params = useParams()
    const navigate = useNavigate()
    const project_ref = useRef()

    const [select_data, setSelectData] = useState({})

    const select_fields = ['person', 'project']
    const given_select_fields = ['science_dir']

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'type': '',
        'title': '',
        'partner': '',
        'grnti_program': '',
        'project_id': null,
        'save_form': false,
        'acceptable': false,
        'expected_result': '',
        'expected_result_year': [{
            year: 2024,
            result: '',
            gos_number: '',
            month_results: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].fill({
                month: 1,
                results: '',
                accepted: false,
            }, 0, 12),
        }],
        'supervisor': '',
        'presentation_link': '',
    })

    const formikHelper = useFormik({
        initialValues: {},
    })

    const [start, setStart] = useState(true)
    const [status, setStatus] = useState(1)
    const [move_index_plus, setMoveIndexPlus] = useState(1)
    const [show_modal, setShowModal] = useState(false)
    const [delete_year, setDeleteYear] = useState(null)
    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return saveNiokr()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        handleSubmit(formik, saveNiokr)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД НИОКР | ИС «ПИШ»`
            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })

            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }
            if (!set_inputs?.expected_result_year) {
                set_inputs['expected_result_year'] = [{
                    year: 2024,
                    result: '',
                    month_results: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].fill({
                        month: 1,
                        results: '',
                        accepted: false,
                    }, 0, 12),
                }]
            }
            if (set_inputs?.expected_result_year) {
                let temp = {}
                set_inputs.expected_result_year.forEach((item) => {
                    temp[item?.year] = {
                        visible: false,
                    }
                })
                formikHelper.setValues({ ...temp })
            }

            setStatus(data?.status)
            if (data?.status === STATUS_NEW && user?.project) {
                formik.values.project_id = user?.project?.id
                set_selected.project_id = user?.project?.id
            }
            formik.setValues({ ...set_inputs, ...set_selected, current_status: data.status })
            setStart(false)
        }
    }, [data, status])

    useEffect(() => {
        if (data) {
            let is_manager = user?.roles.every((value) => value === 'project_manager')  // can't view all projects
            if (Object.keys(select_data).length < select_fields.length || !select_data?.event?.length) {
                for (let field of ['project', 'person_select']) {
                    if (!Object.keys(select_data).includes(field)) {
                        if (field === 'project' && is_manager) {
                            getRequest('project', setSelectData, { type_data: 'user_projects', 'user_id': user.id }, '', select_data, field)
                        } else {
                            getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        }
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values])

    useEffect(() => {
        if (formik.values?.project_id)
            project_ref.current = user?.project?.id
        if (data?.status === STATUS_NEW && user?.project)
            formik.values.project_id = user?.project?.id
    }, [formik.values])

    useEffect(() => {
        if (status == STATUS_CHECK) {
            let all_accepted = true
            formik.values.expected_result_year.forEach((year_item) => {
                year_item.month_results.forEach((month_item) => {
                    if (!month_item.accepted) {
                        all_accepted = false
                    }
                })
            })
            if (all_accepted) {
                setMoveIndexPlus(1)
                formik.setFieldValue('acceptable', true)
            } else {
                setMoveIndexPlus(-1)
                formik.setFieldValue('acceptable', false)
            }
       }
    }, [formik.values, move_index_plus])

    useEffect(() => {
        if (delete_year) {
            let years = formik.values.expected_result_year
            let i
            for (i = 0; i < years.length; i++) {
                if (years[i].year === delete_year) {
                    break
                }
            }
            setDeleteYear(null)
            years.splice(i, 1)
        }
    }, [formik.values, delete_year])

    const saveNiokr = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values }
        } else if (STATUS_PROCESS === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, ...data?.acceptable }
        } else {
            payload = formik.values
        }

        updateRequest('niokr', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/niokr')
                setData(response.data)
            } else if (response.status === 400) {
                if (response.data?.message)
                    toast.error(response.data?.message)
                if (response.data?.fields)
                    formik.setErrors({ ...formik.errors, ...response.data?.fields })
            }
        })
        if (formik?.values?.message) {
            putRequest('niokr_messages', {
                content: formik?.values?.message,
                niokr_status: data.status,
                status: data.status,
                created_by: user.id,
                niokr: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }
        return Promise.resolve('is_saved')
    }

    const isDisabledRes = (isAccepted) => {
        if (disabled) return true
        let can_manage = user.roles.some((role) => CAN_MANAGE_ROLES_RESULTS[status].includes(role))
        let can_manage_accepted = (data?.status === STATUS_ACCEPT && (user.roles.some((role) => CAN_MANAGE_ROLES_RESULTS[status].includes(role))))
        if (can_manage_accepted) return false
        if (!can_manage) return true
        return isAccepted
    }

    const handleAddYear = (values) => {
        if (formik.values.expected_result_year.find((item) => item?.year === Number(values?.year))) {
            toast.error('Введенный год уже присутствует в фактических значениях')
            return
        }
        formik.setFieldValue('expected_result_year', [...formik.values.expected_result_year, {
            year: values?.year,
            result: '',
            month_results: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].fill({
                month: 1,
                results: '',
                accepted: false,
            }, 0, 12),
        }])
        formikHelper.setValues({
            ...formikHelper.values,
            [values?.year]: {
                visible: false,
            },
        })
        setShowModal(false)
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <FormikProvider value={formik}>

                <Form>
                    <div className='section-header sticky-top'>
                        <h1 className='page-header'>
                            {`Редактирование записи №${params.id} в БД НИОКР`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_NIOKR}
                                       permission_manage={PERMISSIONS.MANAGE_NIOKR}
                                       move_index_plus={move_index_plus}
                        />
                    </div>
                    <div
                        className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                        <HeaderStatus style={{ minWidth: '250px' }}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='niokr_message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          disabled={disabled}
                                          rows='5'
                                          value={formik?.values?.message}
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <Field component={FormikSelect}
                                        name={'science_dir'}
                                        label={'Научное направление'}
                                        id={'science_dir'}
                                        isSearchable={true}
                                        options={SCIENCE_DIRECTIONS}
                                        error={formik.errors['science_dir']}
                                        invalid={formik.errors['science_dir']}
                                        disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                        required={data ? REQUIRED_FIELDS[data.status].includes('science_dir') : false}
                                />
                                <Row>
                                    <Col md={6}>
                                        <Input label={'Тема НИОКР'} id={'title'}
                                               name={'title'}
                                               required={REQUIRED_FIELDS?.[data?.status]?.includes('title')}
                                               handleChangeValue={formik.handleChange}
                                               isClearable={true}
                                               value={formik.values.title}
                                               disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                               error={formik.errors['title']}
                                               invalid={formik.errors['title']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'project_id'}
                                               label={'Проект'}
                                               id={'project_id'}
                                               isSearchable={true}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               error={formik.errors['project']}
                                               invalid={formik.errors['project']}
                                               disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('project') : false}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input label={'Индустриальный(ые) партнер(ы)'} id={'partner'}
                                               name={'partner'}
                                               required={REQUIRED_FIELDS?.[data?.status]?.includes('partner')}
                                               handleChangeValue={formik.handleChange}
                                               isClearable={true}
                                               value={formik.values.partner}
                                               disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                               error={formik.errors['partner']}
                                               invalid={formik.errors['partner']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input label={'Руководитель / Отв. исп. НИОКР'} id={'supervisor'}
                                               name={'supervisor'}
                                               required={REQUIRED_FIELDS?.[data?.status]?.includes('supervisor')}
                                               handleChangeValue={formik.handleChange}
                                               isClearable={true}
                                               value={formik.values.supervisor}
                                               disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                               error={formik.errors['supervisor']}
                                               invalid={formik.errors['supervisor']}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Input label={'ГРНТИ (Программа)'} id={'grnti_program'}
                                               name={'grnti_program'}
                                               required={REQUIRED_FIELDS?.[data?.status]?.includes('grnti_program')}
                                               handleChangeValue={formik.handleChange}
                                               isClearable={true}
                                               value={formik.values.grnti_program}
                                               disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                               error={formik.errors['grnti_program']}
                                               invalid={formik.errors['grnti_program']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input label={'Ссылка на презентацию'} id={'presentation_link'}
                                               name={'presentation_link'}
                                               required={REQUIRED_FIELDS?.[data?.status]?.includes('presentation_link')}
                                               handleChangeValue={formik.handleChange}
                                               isClearable={true}
                                               value={formik.values.presentation_link}
                                               disabled={!(user.roles.some((role) => CAN_MANAGE_ROLES_COMMON[status].includes(role))) || disabled}
                                               error={formik.errors['presentation_link']}
                                               invalid={formik.errors['presentation_link']}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <hr/>
                            <fieldset>
                            <legend>План выполнения</legend>
                                <Area
                                    label={'Ожидаемый результат проекта'}
                                    id={'expected_result'}
                                    name={'expected_result'}
                                    handleChangeValue={formik.handleChange}
                                    value={formik.values.expected_result}
                                    step={'any'}
                                    disabled={disabled}
                                    required={REQUIRED_FIELDS?.[data?.status]?.includes('expected_result')}
                                    error={formik.errors['expected_result']}
                                    invalid={formik.errors['expected_result']}
                                />
                                <Modal size={'md'} show={show_modal} centered={true} onHide={() => setShowModal(false)}>
                                    <div className={'modal-content'}>
                                        <Modal.Header>
                                            <Modal.Title>
                                                Добавление ожидаемого результата за год
                                            </Modal.Title>
                                            <button className={'close'} onClick={() => {
                                                setShowModal(false)
                                            }}><FontAwesomeIcon icon={'times'}/>
                                            </button>
                                        </Modal.Header>
                                        <Formik
                                            initialValues={{ year: '' }}
                                            onSubmit={(values) => handleAddYear(values)}>
                                            {(props) => (
                                                <Form>
                                                    <Modal.Body>
                                                        <Input name={'year'} id={'year'}
                                                               label={'Год'}
                                                               max={2100}
                                                               min={1900}
                                                               type={'year'}
                                                               handleChangeValue={props.handleChange}/>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <div className={'button-actions'}>
                                                            <Button variant={'success'}
                                                                    onClick={() => props.submitForm()}>
                                                                <IconText icon={'plus'} text={'Создать'}/>
                                                            </Button>
                                                            <Button variant={'warning'} onClick={() => {
                                                                setShowModal(false)
                                                            }}>
                                                                Отменить
                                                            </Button>
                                                        </div>
                                                    </Modal.Footer>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </Modal>
                            </fieldset>
                            <hr/>
                            <fieldset>
                                <legend>Результаты по годам</legend>
                                <Button className={'mb-4'} variant={'success'} onClick={() => setShowModal(true)}>
                                    Добавить год
                                </Button>
                                <div className='alert alert-danger'
                                    style={{ display: formik.errors?.expected_result_year ? 'block' : 'none' }}>
                                    <p>Исправьте следующие ошибки:</p>
                                    <ul>
                                        <li>Необходимо заполнить и принять "Фактические результаты работы за месяц" за каждый
                                            месяц в каждом году.
                                        </li>
                                    </ul>
                                </div>
                                {formik.values?.expected_result_year ? formik.values?.expected_result_year?.map((item, index) => {
                                    return (
                                        <div key={item?.year}>
                                            <h5>Ожидаемые результаты за {item?.year}</h5>
                                            <Button
                                                onClick={() =>
                                                    formikHelper.setFieldValue(
                                                        `${item?.year}.visible`,
                                                        !formikHelper.values?.[item?.year]?.visible)
                                                }>
                                                {formikHelper.values?.[item?.year]?.visible ? 'Скрыть' : 'Показать'}
                                            </Button>
                                            <Button variant={'danger'} className={'ml-2'}
                                                    onClick={() => setDeleteYear(item?.year)}>
                                                <FontAwesomeIcon icon={'trash-alt'}/>
                                            </Button>
                                            {formikHelper.values?.[item?.year]?.visible
                                                ? <FieldArray name={'expected_result_year'} render={(arrayHelpers) => {
                                                    return (
                                                        <div key={`inside ${item?.year}`}>
                                                            <br/>
                                                            <Area
                                                                label={'Ожидаемый результат'}
                                                                id={`expected_result_year.${index}.result`}
                                                                name={`expected_result_year.${index}.result`}
                                                                handleChangeValue={formik.handleChange}
                                                                value={item.result}
                                                                step={'any'}
                                                                disabled={disabled}
                                                                required={REQUIRED_FIELDS?.[data?.status]?.includes('expected_result_year')}
                                                                error={formik.errors[`expected_result_year.${index}.result`]}
                                                                invalid={formik.errors[`expected_result_year.${index}.result`]}
                                                            />
                                                            <fieldset>
                                                                <legend>Фактические результаты работы за месяц:</legend>
                                                                <FieldArray name={'month_results'} render={(arrayHelpers) => (
                                                                    <div key={`month_results_${item?.year}`}>
                                                                        {arrayHelpers.form.values?.expected_result_year[index].month_results?.map((month_res, month_index) => (
                                                                            <fieldset key={`${item?.year}, ${month_index}`}>
                                                                                <Area
                                                                                    label={MONTHS[month_index + 1]}
                                                                                    id={`expected_result_year.${index}.month_results.${month_index}.results`}
                                                                                    name={`expected_result_year.${index}.month_results.${month_index}.results`}
                                                                                    handleChangeValue={formik.handleChange}
                                                                                    value={month_res.results}
                                                                                    step={'any'}
                                                                                    disabled={isDisabledRes(formik.values.expected_result_year[index].month_results[month_index]?.accepted)}
                                                                                    required={STATUS_CHECK === formik.values.status && !isDisabledRes(formik.values.expected_result_year[index].month_results[month_index]?.accepted)}
                                                                                    error={formik.errors['month_results']}
                                                                                    invalid={formik.errors['month_results']}
                                                                                />
                                                                                {data && user.roles.some((role) => CAN_APPROVE_ROLES[data?.status].includes(role)) && data?.status === STATUS_CHECK
                                                                                    ? <Check label={'Принять результат за месяц'}
                                                                                        id={`expected_result_year.${index}.month_results.${month_index}.accepted`}
                                                                                        value={month_res.accepted}
                                                                                        handleChangeValue={formik.handleChange}
                                                                                    />
                                                                                : null}
                                                                            </fieldset>
                                                                        ))}
                                                                    </div>
                                                                )}/>
                                                            </fieldset>
                                                            <fieldset>
                                                                <legend>Отчет о научно исследовательской работе</legend>
                                                                <Input
                                                                    label={'Номер государственной регистрации'}
                                                                    id={`expected_result_year.${index}.gos_number`}
                                                                    name={`expected_result_year.${index}.gos_number`}
                                                                    handleChangeValue={formik.handleChange}
                                                                    value={item.gos_number}
                                                                    step={'any'}
                                                                    disabled={disabled}
                                                                    required={REQUIRED_FIELDS?.[data?.status]?.includes('gos_number')}
                                                                    error={formik.errors[`expected_result_year.${index}.result`]}
                                                                    invalid={formik.errors[`expected_result_year.${index}.gos_number`]}
                                                                />
                                                                <label>Отчет о научно исследовательской работе</label>
                                                                <MyFileBrowser
                                                                    height={280}
                                                                    path={`root/storage/niokr/niokr_${params.id}/year/${index}`}
                                                                    read_only={disabled}
                                                                    instanceId={`${index}`}/>
                                                            </fieldset>
                                                        </div>
                                                    )
                                                }}/>
                                            : null}
                                            <br/>
                                            <br/>
                                        </div>
                                    )
                                }) : null}
                            </fieldset>
                            <hr/>
                            <fieldset>
                                <legend>Презентация</legend>
                                <MyFileBrowser
                                    path={`root/storage/niokr/niokr_${params.id}/presentation`}
                                    read_only={disabled}
                                    instanceId={'presentation'}/>
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}
