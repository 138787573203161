import * as React from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import ReactFilterTable from '../../../components/tables/react_table_filters'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { AuthContext } from '../../../auth'
import { FIELDS, LABELS, MONTHS, STATUS_BADGE_CLASS, STATUS_LABELS } from './constant'
import { putRequest } from '../../../actions/common'
import { STATUS_ACCEPT, STATUS_NEW } from './constant'
import { Messages } from '../../../utils/utils'
import { useAbac } from 'react-abac'
import { IS_NETWORK } from '../dpo/constant'


const Employed = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const [employed, setEmployed] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['id', 'status', 'organization',
            'specialist_fio',
            'employment_date',
            'project',
            'month',
            'year'],
        'Скрытые / Фиксированные колонки': [
            'event',
            'direction_by',
            'updated_by',
            'created_by',
            'created_at',
            'updated_at',
            'messages',
        ],
    })

    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        } else if (key === 'specialist') {
            return field?.fio
        } else if (key === 'project' && field) {
            return <span>{field.title_short}</span>
        } else if (key === 'event') {
            return field?.title
        } else if (key === 'month') {
            return MONTHS?.[field - 1]
        }

        return null
    }

    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({ status_not: STATUS_NEW })
        }
    }, [user])

    const addNewEmployed = (state) => {
        putRequest('employed', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW, project: user?.project?.id,
        }).then((response) => {
            console.log(state?.monitoring)
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/employed/item/${response.data.id}/?update=true`)
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                Трудоустроенные
            </h1>
            {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction)
            || user?.roles?.includes(ROLES.project_manager)
                ? <React.Fragment>
                    <div className={'button-actions'}>
                        {userHasPermissions(PERMISSIONS.CONTROL_EMPLOYED)
                            ? <React.Fragment>
                                <Button variant={'secondary'} className={'mb-3'} onClick={() => {
                                    addNewEmployed({ monitoring: true })
                                }}>
                                    <IconText icon={'plus'} text={'Добавить в мониторинг'}/>
                                </Button>
                                {userHasPermissions(PERMISSIONS.ADMIN_PANEL)
                                    ? <Button variant={'success'} className={'mb-3'} onClick={() => {
                                        addNewEmployed()
                                    }}>
                                        <IconText icon={'plus'} text={'Создать'}/>
                                    </Button> : null}
                            </React.Fragment>
                            : <React.Fragment>
                                <Button variant={'success'} className={'mb-3'} onClick={() => {
                                    addNewEmployed()
                                }}>
                                    <IconText icon={'plus'} text={'Создать'}/>
                                </Button>
                            </React.Fragment>
                        }
                    </div>
                </React.Fragment> : null}
            <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                              filtering={filtering}
                              data={employed} setFunc={setEmployed} page={1} url={'/employed'}
                              url_update={'/employed/item'}
                              get_title={'employed'}
                              checkField={checkField}
                              permission={PERMISSIONS.MANAGE_EMPLOYED}
                              fields={FIELDS}
            />
        </React.Fragment>
    )
}

export default Employed
