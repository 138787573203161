import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Card, Col, Row, Form } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { useEffect, useRef, useState } from 'react'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import {
    STATUS_NEW,
    STATUS_ACCEPT,
    REQUIRED_FIELDS_TEMP,
    MONTHS,
    EVENT_TYPE,
    ORGANIZATION_TYPE,
} from './constant'
import { Area, Input } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { useAbac } from 'react-abac'
import { btnMessage, findInSelectData } from '../../../utils/utils'
import { REQUIRED_FIELDS } from './constant'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikDate, FormikSelect } from '../../../components/form/formik'
import { toast } from 'react-toastify'

export let specialistNotFound = {value: false};

export const EmployedItem = ({
                                 data,
                                 user,
                                 disabled,
                                 messages,
                                 generateOptions,
                                 ErrorsBlock,
                                 MessageHistory,
                                 ActionButtons,
                                 DisableButton,
                                 HeaderStatus,
                                 setDisabled,
                                 handleSubmit,
                                 setData,
                                 setMessages,
                                 handleSaveAndMove,
                             }) => {
    const params = useParams()
    const navigate = useNavigate()

    const { userHasPermissions } = useAbac()
    const [select_data, setSelectData] = useState({})

    const select_fields = ['program', 'project', 'specialist_event']
    const given_select_fields = ['month', 'program_type', 'organization_type']
    const [specialists, setSpecialists]= useState(null)

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'is_upload': false,

        'organization': '',
        'employment_date': null,
        'month': null,
        'year': null,
        'specialist_fio': '',
        'enrollment_order': '',
        'docs_link': '',
        'organization_type': null,
        'edu_doc_number': '',
        'edu_doc_date': null,
        'event_oop_id': null,
        'event_dpo_id': null,
    })

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return saveFunction()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        handleSubmit(formik, saveFunction)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД Трудоустроенные | ИС «ПИШ»`

            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })
            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }
            set_selected['event_oop_id'] = data?.event_oop
            set_selected['event_dpo_id'] = data?.event_dpo
            set_selected['program_id'] = data?.event_dpo ? data?.event_dpo : data?.event_oop

            formik.setValues({
                ...set_inputs,
                ...set_selected,
                current_status: data.status,
            })
        }
    }, [data])

    function set_enrollment_order(fio) {
        getRequest('specialist', setSpecialists, { by_fio: fio }, specialists, 'specialists').then((r) => {
            let findOrder = false
            r.data.forEach((item) => {
                let findSpecialistWithDpo = (item.event_dpo && (formik.values.program_type <= 2) 
                    && (item.event_dpo.id === formik.values.program_id))
                let findSpecialistWithOop = (item.event_oop && (formik.values.program_type > 2) 
                    && (item.event_oop.id === formik.values.program_id))
                if (findSpecialistWithDpo || findSpecialistWithOop) {
                    formik.setFieldValue('enrollment_order', item.enrollment_order)
                    findOrder = true
                    specialistNotFound.value = false
                }
            })
            if (!findOrder) {
                formik.setFieldValue('enrollment_order', '')
                specialistNotFound.value = true
            }
        })
    }

    useEffect(() => {
        if (data) {
            let is_manager = user?.roles.every((value) => value === 'project_manager')  // can't view all projects
            if (Object.keys(select_data).length < select_fields.length || !select_data?.program?.length) {
                for (let field of select_fields) {
                    if (!Object.keys(select_data).includes(field) 
                        && field !== 'program' && field !== 'specialist_event') {
                        let params = { type_data: 'all' }
                        if (field === 'project' && is_manager) {
                            getRequest('project', setSelectData, { type_data: 'user_projects', 'user_id': user.id }, '', select_data, field)
                        } else {                            
                            getRequest(field, setSelectData, params, '', select_data, field).then()
                        }
                    }
                    if (!select_data?.program?.length && field === 'specialist_event') {
                        getRequest('specialist_event', setSelectData, {}, '', select_data, 'program').then((r) => {
                            let oop = r.data.find((v) => v?.type === 1 && v?.id === formik.values.event_oop_id)
                            let dpo = r.data.find((v) => v?.type === 2 && v?.id === formik.values.event_dpo_id)
                            if (oop || dpo) {
                                setInputFields({ ...formik.values, program: oop || dpo })
                            }
                        })
                    }
                }
            }
            if (!specialists) {
                set_enrollment_order(data['specialist_fio'])
            }
        }
    }, [data, select_data, formik.values])

    useEffect(() => {
        if (select_data.program) {
            let find = select_data?.program.find((v) => v?.id === formik.values.program_id)
            if (find?.event_type && find?.event_type === 1) {
                formik.setFieldValue('event_oop_id', find?.id)
                formik.setFieldValue('event_dpo_id', null)
            } else if (find?.event_type && find?.event_type === 2) {
                formik.setFieldValue('event_oop_id', null)
                formik.setFieldValue('event_dpo_id', find?.id)
            }
        }
    }, [formik.values])

    useEffect(() => {
        if (formik.values.specialist_fio) {
            set_enrollment_order(formik.values.specialist_fio)
        }
    }, [formik.values])
    
    const saveFunction = () => {
        formik.setFieldValue('save_form', false)
        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_by: user.id }
        } else {
            payload = formik.values
        }
        if (payload.program_id && payload.program_type) {
            
            if (payload.program_type === 1) {
                payload.event_oop_id = payload.program_id
                payload.event_dpo_id = null
            } else {
                payload.event_dpo_id = payload.program_id
                payload.event_oop_id = null
            }
        }

        updateRequest('employed', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/employed')
                setData(response.data)
            } else if (response.status === 400 && response.data.message) {
                toast.error(response.data.message)
            }
        })
        if (formik?.values?.message) {
            putRequest('employed_messages', {
                content: formik?.values?.message,
                employed_status: data.status,
                status: data.status,
                created_by: user.id,
                employed: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }

    // console.log(formik.errors)

    function generateEventOptions() {
        let options = generateOptions('program', (element) => {
            return element.event_name
        }, select_data)
        return !options ? options : options.filter((item) => Boolean(item?.label))
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <ReactBreadcrumb/>
                <Form>
                    <div className='section-header sticky-top'>
                        <h1 className='page-header'>
                            {`Редактирование записи №${params.id} в БД Трудоустроенные`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_EMPLOYED}
                                       permission_manage={PERMISSIONS.MANAGE_EMPLOYED}
                                       can_next={!(userHasPermissions(PERMISSIONS.IS_MANAGER) 
                                            && data?.status > STATUS_NEW)}
                                       can_back={userHasPermissions(PERMISSIONS.IS_MANAGER) ? false
                                           : data?.status > STATUS_NEW
                                           && data?.status !== STATUS_ACCEPT}
                        />
                    </div>
                    <div
                        className='mb-2 text-light text-center d-flex 
                            flex-wrap align-items-center align-content-center 
                            justify-content-md-center'>
                        <HeaderStatus style={{ width: '200px' }}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}>
                        <IconText icon={'comment'} text={'Сообщения'}/>
                    </Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message 
                            ? { display: 'block' } 
                            : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          value={formik.values?.message}
                                          disabled={disabled}
                                          rows='5'
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> 
                                При работе с данными карточки учитывайте следующие 
                                условные обозначения, используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет
                                    сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <div className='alert alert-danger'
                                 style={{display: 'enrollment_order' in formik.errors ? 'block' : 'none'}}>
                                    Такого человека нет в БД "Обученные и студенты". 
                                    Проверьте корректность заполнения полей "ФИО подготовленного 
                                    специалиста" и "Наименование образовательной программы"
                                    в БД "Обученные и студенты" 
                                    и в БД "Трудоустроенные"
                            </div>
                            <fieldset>
                                <legend>Основные данные</legend>
                                <Row>
                                    <Col md={12}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'ФИО подготовленного специалиста'}
                                               id={'specialist_fio'}
                                               name={'specialist_fio'}
                                               error={formik.errors['specialist_fio']}
                                               invalid={formik.errors['specialist_fio']}
                                               disabled={disabled}
                                               value={formik.values.specialist_fio}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('specialist_fio') : false}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Наименование организации, в которой трудоустроен специалист'}
                                               id={'organization'}
                                               name={'organization'}
                                               error={formik.errors['organization']}
                                               invalid={formik.errors['organization']}
                                               disabled={disabled}
                                               value={formik.values.organization}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('organization')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'employment_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата трудоустройства'}
                                               id={'employment_date'}
                                               disabled={disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('employment_date')}
                                               value={formik.values.employment_date}
                                               error={formik.errors['employment_date']}
                                               invalid={formik.errors['employment_date']}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <Field component={FormikSelect}
                                               label={'Характеристика организации, в которой трудоустроен специалист'}
                                               name={'organization_type'}
                                               id={'organization_type'}
                                               options={ORGANIZATION_TYPE}
                                               disabled={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'program_id'}
                                               label={'Наименование образовательной программы'}
                                               id={'program_id'}
                                               isClearable={true}
                                               isSearchable={true}
                                               options={generateEventOptions()}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('event') : false}
                                               disabled={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'program_type'}
                                               id={'program_type'}
                                               label={'Тип программы'}
                                               options={EVENT_TYPE}
                                               value={formik.values.program_type}
                                               disabled={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Номер документа об образовании'}
                                               id={'edu_doc_number'}
                                               name={'edu_doc_number'}
                                               error={formik.errors['edu_doc_number']}
                                               invalid={formik.errors['edu_doc_number']}
                                               disabled={disabled}
                                               value={formik.values.edu_doc_number}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('edu_doc_number') : false}
                                        />
                                    </Col>
                                    <Col md={6}>
                                         <Field component={FormikDate}
                                               name={'edu_doc_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата документа об образовании'}
                                               id={'edu_doc_date'}
                                               disabled={disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('edu_doc_date')}
                                               value={formik.values.edu_doc_date}
                                               error={formik.errors['edu_doc_date']}
                                               invalid={formik.errors['edu_doc_date']}
                                        />
                                    </Col>
                                    
                                    <Col md={12}>
                                        <Field component={FormikSelect}
                                               name={'project_id'}
                                               label={'Проект'}
                                               id={'project_id'}
                                               isClearable={true}
                                               isSearchable={true}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('project') : false}
                                               disabled={disabled}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                            name={'month'}
                                            label={'Месяц включения в отчет'}
                                            id={'month'}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={MONTHS.map((v, i) => {
                                                return { value: i + 1, label: v }
                                            })}
                                            required={data ? REQUIRED_FIELDS[data.status].includes('month') : false}
                                            disabled={disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                            label={'Год включения в отчет'}
                                            id={'year'}
                                            type={'int'}
                                            min={2022}
                                            max={2030}
                                            name={'year'}
                                            error={formik.errors['year']}
                                            invalid={formik.errors['year']}
                                            disabled={disabled}
                                            value={formik.values.year}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('year')}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Приказ о зачислении'}
                                               id={'enrollment_order'}
                                               name={'enrollment_order'}
                                               setValid={true} 
                                               error={formik.errors['enrollment_order']}
                                               invalid={formik.errors['enrollment_order']}
                                               disabled={true}
                                               small={'Заполняется автоматически'}
                                               value={formik.values.enrollment_order}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('enrollment_order') : false}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Ссылка на документы'}
                                               id={'docs_link'}
                                               name={'docs_link'}
                                               error={formik.errors['docs_link']}
                                               invalid={formik.errors['docs_link']}
                                               disabled={disabled}
                                               value={formik.values.docs_link}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('docs_link') : false}
                                        />
                                    </Col>
                                    
                                </Row>
                                <hr/>
                            </fieldset>

                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                <MyFileBrowser
                                    path={`root/storage/employed/employed_${params.id}`}
                                    read_only={data?.status > STATUS_NEW
                                    && !userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                        ? true : disabled}
                                    instanceId={`employed_${params.id}`}/>
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
        
}
