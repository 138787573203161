import { ROLES } from '../../../rbac/constant'
import { Input, InputDate, Select } from '../../../components/form/forms'
import * as Yup from 'yup'
import { setRequiredField } from '../../../utils/utils'

export const STATUS_DRAFT = 0                 // Черновик
export const STATUS_ACCEPT = 7                 // Принято Д
export const STATUS_TEMP = 8                 // Принято Д
export const STATUS_NEW = 1                    // Новый МП
export const STATUS_APPROVE = 2                // Рассматривается ДО
export const STATUS_DEV_ORDER = 3
export const STATUS_APPROVE_DEV = 4
export const STATUS_IMPL_ORDER = 5
export const STATUS_APPROVE_IMPL = 6


export const STATUS_LABELS = {
    [STATUS_DRAFT]: 'Отклонено',
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Согласование',
    [STATUS_DEV_ORDER]: 'Добавление приказа о разработке',
    [STATUS_APPROVE_DEV]: 'Согласование разработки ДПО',
    [STATUS_IMPL_ORDER]: 'Добавление информации о внедрении',
    [STATUS_APPROVE_IMPL]: 'Согласование внедрения ДПО',
    [STATUS_ACCEPT]: 'Принято',
}

export const STATUS_OPTIONS = [
    { value: STATUS_NEW, label: 'Новый' },
    { value: STATUS_APPROVE, label: 'Согласование' },
    { value: STATUS_DEV_ORDER, label: 'Добавление приказа о разработке' },
    { value: STATUS_APPROVE_DEV, label: 'Согласование разработки ДПО' },
    { value: STATUS_IMPL_ORDER, label: 'Добавление информации о внедрении' },
    { value: STATUS_APPROVE_IMPL, label: 'Согласование внедрения ДПО' },
    { value: STATUS_ACCEPT, label: 'Принято' },
]

export const STATUS_BADGE_CLASS = {
    [STATUS_DRAFT]: 'badge-danger',
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_DEV_ORDER]: 'badge-warning',
    [STATUS_APPROVE_DEV]: 'badge-primary',
    [STATUS_IMPL_ORDER]: 'badge-warning',
    [STATUS_APPROVE_IMPL]: 'badge-primary',
    [STATUS_ACCEPT]: 'badge-success',
}

export const IS_NETWORK = [
    { value: 1, label: 'Нет' },
    { value: 2, label: 'Да' },
]

export const EVENT_TYPE = [
    { value: 1, label: 'Повышение квалификации' },
    { value: 2, label: 'Профессиональная переподготовка' },
]

export const LABELS = {
    'id': 'ID',
    'status': 'Статус',

    'event_name': 'Образовательная программа',
    'event_type': 'Тип программы',
    'speciality': 'Специальность и направление',
    'development_date': 'Дата разработки',
    'implementation_date': 'Дата внедрения',
    'is_network': 'Сетевая',
    'dev_order_number': 'Номер приказа разработки',
    'dev_order_date': 'Дата приказа разработки',
    'impl_order_number': 'Номер приказа внедрения',
    'impl_order_date': 'Дата приказа внедрения',
    'project': 'Проект',

    'message': 'Комментарий',
    'messages': 'Сообщения',
    'updated_by': 'Обновлено пользователем',
    'created_at': 'Дата создания',
    'updated_at': 'Дата обновления',
    'created_by': 'Создано пользователем',
    'direction_by': 'Дирекция',
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'number',
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },
    event_name: {
        type: Input,
        content: 'text',
    },
    event_type: {
        type: Select,
        options: EVENT_TYPE,
    },
    speciality: {
        type: Select,
        source: 'speciality',
        key: (v) => `${v?.title}`,
    },
    development_date: {
        type: InputDate,
        content: 'date',
    },
    implementation_date: {
        type: InputDate,
        content: 'date',
    },
    is_network: {
        type: Select,
        options: IS_NETWORK,
    },
    dev_order_number: {
        type: Input,
        content: 'text',
    },
    dev_order_date: {
        type: InputDate,
        content: 'date',
    },
    impl_order_number: {
        type: Input,
        content: 'text',
    },
    impl_order_date: {
        type: InputDate,
        content: 'date',
    },
    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short,
    },

    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    direction_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_DRAFT]: [],
    [STATUS_NEW]: ['event_name', 'is_network', 'event_type', 'speciality', 'development_date', 'implementation_date', 'project'],
    [STATUS_APPROVE]: ['event_name', 'is_network', 'event_type', 'speciality', 'development_date', 'implementation_date', 'project'],
    [STATUS_DEV_ORDER]: ['event_name', 'is_network', 'event_type', 'speciality', 'development_date', 'implementation_date',
        'dev_order_number', 'dev_order_date', 'project'],
    [STATUS_APPROVE_DEV]: ['event_name', 'is_network', 'event_type', 'speciality', 'development_date', 'implementation_date',
        'dev_order_number', 'dev_order_date', 'project'],
    [STATUS_IMPL_ORDER]: ['event_name', 'is_network', 'event_type', 'speciality', 'development_date', 'implementation_date',
        'dev_order_number', 'dev_order_date', 'impl_order_number', 'impl_order_date', 'project'],
    [STATUS_APPROVE_IMPL]: ['event_name', 'is_network', 'event_type', 'speciality', 'development_date', 'implementation_date',
        'dev_order_number', 'dev_order_date', 'impl_order_number', 'impl_order_date', 'project'],
    [STATUS_ACCEPT]: ['event_name', 'is_network', 'event_type', 'speciality', 'development_date', 'implementation_date',
        'dev_order_number', 'dev_order_date', 'impl_order_number', 'impl_order_date', 'project'],
    [STATUS_TEMP]: ['event_name', 'is_network', 'event_type', 'speciality', 'development_date', 'implementation_date',
        'dev_order_number', 'dev_order_date', 'impl_order_number', 'impl_order_date', 'project'],
}

const getSchema = () => {
    return {
        status: Yup.number(),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return status[0] < status[1] ? schema.required('Обязательное поле!') : schema
        }),
        event_type: Yup.number().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
        is_network: Yup.number().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
        speciality_id: Yup.number().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
        project_id: Yup.number().required('Обязательное поле!').typeError('Обязательное поле!'),
        event_name: Yup.string().nullable().required('Обязательное поле!'),
        development_date: Yup.date()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!')
            .nullable()
            .default(undefined)
            .test('test-on-null', 'Обязательное поле!', (value, context) => {
                return value
            }),
        implementation_date: Yup.date()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!')
            .nullable()
            .default(undefined)
            .test('test-on-null', 'Обязательное поле!', (value, context) => {
                return value
            }).when(['development_date'],
                (development_date, schema) => {
                    return development_date[0]
                        ? schema.min(development_date[0], 'Дата внедрения не может быть меньше даты разработки') : schema
                }),
        impl_order_date: setRequiredField(Yup.date()
            .typeError('Обязательное поле!')
            .nullable()
            .default(undefined), STATUS_APPROVE_IMPL)
            .when(['dev_order_date'],
                (dev_order_date, schema) => {
                    return dev_order_date[0]
                        ? schema.min(dev_order_date[0], 'Дата внедрения не может быть меньше даты разработки') : schema
                }),
        dev_order_date: setRequiredField(Yup.date()
            .typeError('Обязательное поле!')
            .nullable()
            .default(undefined), STATUS_APPROVE_DEV),
        impl_order_number: setRequiredField(Yup.string().nullable(), STATUS_APPROVE_IMPL),
        dev_order_number: setRequiredField(Yup.string().nullable(), STATUS_APPROVE_DEV),
    }
}

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_DRAFT]: Yup.object().shape(getSchema()),
    [STATUS_NEW]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE_DEV]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE_IMPL]: Yup.object().shape(getSchema()),
    [STATUS_DEV_ORDER]: Yup.object().shape(getSchema()),
    [STATUS_IMPL_ORDER]: Yup.object().shape(getSchema()),
    [STATUS_ACCEPT]: Yup.object().shape(getSchema()),
}

export const CAN_MANAGE_ROLES = {
    [STATUS_DRAFT]: [ROLES.admin],
    [STATUS_NEW]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction_dpo, ROLES.direction],
    [STATUS_DEV_ORDER]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE_DEV]: [ROLES.admin, ROLES.direction_dpo, ROLES.direction],
    [STATUS_IMPL_ORDER]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE_IMPL]: [ROLES.admin, ROLES.direction_dpo, ROLES.direction],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction_dpo, ROLES.direction],
}
