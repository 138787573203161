import * as React from 'react'
import ReactBreadcrumb from '../../../../components/breadcrumbs'
import { useEffect, useState } from 'react'
import {
    Button,
    Card,
    Col,
    Row,
    Form,
} from 'react-bootstrap'
import { IconText } from '../../../../components/icon_txt'
import { getRequest, putRequest } from '../../../../actions/common'
import { useAbac } from 'react-abac'
import { PERMISSIONS } from '../../../../rbac/constant'
import { Input } from '../../../../components/form/forms'
import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { MONTHS, REPORT_YEAR } from '../constant'
import { FormikSelect } from '../../../../components/form/formik'
import { toast } from 'react-toastify'


const RidExternal = () => {
    const today = new Date()
    const navigate = useNavigate()
    const { userHasPermissions } = useAbac()
    const formik = useFormik({
        initialValues: {
            results: null,
            year: today.getFullYear(),
            month: today.getMonth() + 1,
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            return saveRidExternal()
        },
    })
    const [data, setData] = useState(null)
    const [current_month, setCurrentMonth] = useState(today.getMonth() + 1)
    const [current_year, setCurrentYear] = useState(today.getFullYear())
    const [reset, setReset] = useState(false)

    useEffect(() => {
        document.title = 'РИДы (внешний мониторинг) | ИС «ПИШ»'
    })

    useEffect(() => {
        let is_new_month = (current_month !== formik.values.month)
        let is_new_year = (current_year !== formik.values.year)
        if (!data || is_new_month || is_new_year || reset) {
            getRequest('rid_external', setData, { year: formik.values.year, month: formik.values.month }, '', data).then((response) => {
                if (response?.status === 200) {
                    response.data['results'].forEach((res) => {
                        res['result_accumulated'] = Number(res['result_accumulated']).toFixed(0)
                        res['result_current'] = Number(res['result_current']).toFixed(0)
                    })
                    response.data['total_current'] = Number(response.data['total_current']).toFixed(0)
                    response.data['total_accumulated'] = Number(response.data['total_accumulated']).toFixed(0)
                    setData(response.data)
                }
            })
        }
        setCurrentMonth(formik.values.month)
        setCurrentYear(formik.values.year)
        setReset(false)
    }, [data, formik.values, current_month, current_year, reset])

    useEffect(() => {
        if (data) {
            let set_selected = { 'month': formik.values.month, 'year': formik.values.year }
            formik.setValues({ 'results': data?.results, ...set_selected })
        }
    }, [data])

    const saveRidExternal = () => {
        putRequest('rid_external', { 'common': formik.values }, '')
            .then((response) => {
                if (response.status === 201) {
                    toast.success('Данные сохранены')
                    getRequest('rid_external', setData, { year: formik.values.year, month: formik.values.month }, '', data)
                        .then((response) => {
                            if (response?.status === 200) {
                                setData(response.data)
                            }
                        })
                }
            },
        )
        return Promise.resolve('is_saved')
    }

   
    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <div className='section-header sticky-top'>         
                <h1 className={'page-header'}>
                    РИДы (внешний мониторинг)
                </h1>
                <div className='button-actions mb-3'>
                    <Button variant={'info'} disabled={true} className={'mb-3'} onClick={() => {
                            navigate('/rid/rid-external')
                        }}>
                        <IconText icon={'dot-circle'} text={'Внешний мониторинг'}/>
                    </Button> 
                    <Button variant={'info'} className={'mb-3'} onClick={() => {
                            navigate('/rid/rid-internal') 
                        }}>
                        <IconText icon={'circle'} text={'Внутренний мониторинг'}/>
                    </Button> 
                    {userHasPermissions(PERMISSIONS.MANAGE_COMMON)    
                    ? <div className='button-actions mb-3'>
                        <Button variant={'success'} onClick={() => saveRidExternal()}>Сохранить</Button>
                        <Button key={'reset'} variant={'secondary'} onClick={() => setReset(true)}>
                            <IconText icon={'ban'} text={'Сбросить'}/>
                        </Button>
                        </div>
                    : null}
                </div>
            </div>
            
            <fieldset>
                <FormikProvider value={formik}>
                    <Form>
                        <Card bg={'light'}>
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                            name={'month'} label={'Месяц зачета'}
                                            id={'month'}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={MONTHS.map((v, i) => {
                                                return { value: i + 1, label: v }
                                            })}
                                            error={formik.errors['month_id']}
                                            invalid={formik.errors['month_id']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                            name={'year'} label={'Год зачета'}
                                            id={'year'}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={REPORT_YEAR}
                                            error={formik.errors['year_id']}
                                            invalid={formik.errors['year_id']}
                                        />
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col md={4} className='d-flex justify-content-center align-items-center'>
                                        <div className={'lead mb-0'}> <legend>Вид</legend> </div>
                                    </Col>
                                    <Col md={4}>
                                        <legend>Накопительный, шт</legend>
                                    </Col>
                                    <Col md={4}>
                                        <legend>На текущий месяц, шт</legend>
                                    </Col>
                                </Row>

                                <FieldArray name={'results'} render={(arrayHelpers) => (<div>
                                    {arrayHelpers.form.values?.results?.map((res, index) => (
                                        <fieldset key={index}>
                                            <Row>
                                                <Col md={4} className='d-flex justify-content-center align-items-center'>
                                                    <div className={'lead mb-0'}>{res.title}</div>
                                                </Col>
                                                <Col md={4}>
                                                    <Input
                                                        id={`results.${index}.result_accumulated`}
                                                        name={`results.${index}.result_accumulated`}
                                                        type={'int'}
                                                        handleChangeValue={formik.handleChange}
                                                        value={res.result_accumulated}
                                                        error={formik.errors['results']}
                                                        invalid={formik.errors['results']}
                                                        disabled={true}
                                                    />
                                                </Col>
                                                <Col md={4}>
                                                    <Input
                                                        id={`results.${index}.result_current`}
                                                        name={`results.${index}.result_current`}
                                                        handleChangeValue={formik.handleChange}
                                                        type={'int'}
                                                        value={res.result_current}
                                                        error={formik.errors['results']}
                                                        invalid={formik.errors['results']}
                                                    />
                                                </Col>
                                            </Row>
                                        </fieldset>
                                    ))}
                                </div>)}/>
                                
                                <Row>
                                    <Col md={4} className='d-flex justify-content-center align-items-center'>
                                        <div className={'lead mb-0'}> <legend>Итого:</legend> </div>
                                    </Col>
                                    <Col md={4}>
                                        <legend>{data ? data['total_accumulated'] : null}</legend>
                                    </Col>
                                    <Col md={4}>
                                        <legend>{data ? data['total_current'] : null}</legend>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Form>
                </FormikProvider>
            </fieldset>
           
            
        </React.Fragment>
    )
}

export default RidExternal
