import { createContext, useEffect, useState } from 'react'
import { getUser } from './actions/auth'

export const AuthContext = createContext(null)

export const AuthContextProvider = (props) => {
    const [user, setUser] = useState(null)

    useEffect(() => {
        if (!user && localStorage.getItem('token')) {
            getUser(setUser).then((r) => {setUser(r)})
        }
    } )

    return (
        <AuthContext.Provider value={user}>
            {props.children}
        </AuthContext.Provider>
    )
}
