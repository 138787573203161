import * as React from 'react'
import IndicatorProjects from './projects/indicator_section'

export const IndicatorProject = () => {

    const labels = { 'indicator': 'Показатель', 'project': 'Проект' }
    const select_fields = ['indicator', 'project']

    return (
        <React.Fragment>
            <IndicatorProjects labels={labels} section_name={'project'} title={'Показатели по проектам'} select_fields={select_fields}/>
        </React.Fragment>
    )
}
