import * as Yup from 'yup'

export const DATABASES = {
    specialist: 'Обученные и студенты',
    rid: 'РИДы',
    financing: 'Доход / Софинансирование',
    internships: 'Стажировка',
    educational_program: 'Образовательные программы',
    niokr: 'НИОКР',
    dpo: 'ДПО',
    school: 'Школьники',
    employed: 'Трудоустроенные',
}


const CalculationJsonSchema = Yup.object().shape({
    calculations: Yup.array().of(Yup.object().shape({
        name: Yup.string().nullable().required('Обязательное поле!'),
        label: Yup.string().nullable().required('Обязательное поле!'),
        value: Yup.string().nullable().required('Обязательное поле!'),
        database: Yup.string().nullable().required('Обязательное поле!'),
        conditions: Yup.array().of(Yup.object().shape({
            name: Yup.string().nullable().required('Обязательное поле!'),
            label: Yup.string().nullable().required('Обязательное поле!'),
            value: Yup.string().nullable().required('Обязательное поле!'),
            union: Yup.string().nullable(),
        })),
    })),
    actions: Yup.string().required('Обязательное поле!'),
})

export const IndicatorCalculationSchema = (formulaName) => Yup.object().shape({
    [formulaName]: CalculationJsonSchema,
})
