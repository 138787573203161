import * as React from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { useAbac } from 'react-abac'
import { PERMISSIONS } from '../../../rbac/constant'
import { useNavigate } from 'react-router-dom'

const Rid = () => {
    const navigate = useNavigate()
    const { userHasPermissions } = useAbac()

    useEffect(() => {
        document.title = 'РИДы | ИС «ПИШ»'
    })
   
    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                РИДы
            </h1>
            <div className='button-actions mb-3'>
                <Button variant={'info'} className={'mb-3'} onClick={() => {
                        navigate('/rid/rid-external')
                    }}>
                    <IconText icon={'circle'} text={'Внешний мониторинг'}/>
                </Button> 
                <Button variant={'info'} className={'mb-3'} onClick={() => {
                        navigate('/rid/rid-internal') 
                    }}>
                    <IconText icon={'circle'} text={'Внутренний мониторинг'}/>
                </Button> 
            </div> 
        </React.Fragment>
    )
}

export default Rid
