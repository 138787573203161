import * as React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import axiosInstance from '../../utils/axiosAPI'

const Verify = () => {
    const params = useParams()
    const [is_verify, setIsVerify] = useState(null)

    useEffect(() => {
        if (is_verify && params.verify_token !== undefined && params.verify_token !== null) {
            axiosInstance.get('api/verify', { params: { verify_token: params.verify_token } }).then((response) => {
                setIsVerify(response.data)
            })
        }
    }, [is_verify])

    const RenderMessage = () => {
        if (is_verify) {
            return <section>
                <h1 className='h2 pb-2 mt-2 mb-2 border-bottom'>
                    Email подтвержден
                </h1>
                <p className='lead'>
                    Ваш аккаунт подтвержден, перейдите по данной ссылке, для того чтобы войти в систему:
                </p>
                <p><Link to='https://dev.control-ncmu.spbstu.ru/login'>control-ncmu.spbstu.ru</Link></p>
            </section>
        } else {
            return <section>
                <h1 className='h2 pb-2 mt-2 mb-2 border-bottom'>
                    Отказано в доступе
                </h1>
                <p className='lead'>
                    Ваш аккаунт был заблокирован, либо необходимо подтвердить Email адрес </p>
                <p>Для получения доступа обратитесь по адресу <a href='mailto:ccm@spbstu.ru'>ccm@spbstu.ru</a></p>
            </section>
        }
    }

    return (
        <React.Fragment>
            <Container>
                <RenderMessage/>
            </Container>
        </React.Fragment>
    )
}

export default Verify
