import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Card, Col, Row, Form } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { useEffect, useRef, useState } from 'react'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import {
    EVENT_TYPE,
    IS_NETWORK,
    REQUIRED_FIELDS_TEMP,
    STATUS_ACCEPT,
    STATUS_APPROVE,
    STATUS_DEV_ORDER, STATUS_IMPL_ORDER,
    STATUS_NEW,
} from './constant'
import { Area, Input } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { useAbac } from 'react-abac'
import { btnMessage } from '../../../utils/utils'
import { REQUIRED_FIELDS } from './constant'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikDate, FormikSelect } from '../../../components/form/formik'

export const DpoItem = ({
                            data,
                            user,
                            disabled,
                            messages,
                            generateOptions,
                            ErrorsBlock,
                            MessageHistory,
                            ActionButtons,
                            DisableButton,
                            HeaderStatus,
                            setDisabled,
                            handleSubmit,
                            setData,
                            setMessages,
                            handleSaveAndMove,
                        }) => {
    const params = useParams()
    const navigate = useNavigate()
    const project_ref = useRef()

    const { userHasPermissions } = useAbac()
    const [select_data, setSelectData] = useState({})

    const select_fields = ['speciality_id', 'project']
    const given_select_fields = ['event_type', 'is_network']

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'is_upload': false,
        'speciality_id': null,
        'project_id': null,
        'event_name': '',
        'event_type': null,
        'development_date': '',
        'implementation_date': '',
        'is_network': null,
        'dev_order_number': '',
        'dev_order_date': '',
        'impl_order_number': '',
        'impl_order_date': '',
    })

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return saveFunction()
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })


    useEffect(() => {
        handleSubmit(formik, saveFunction)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД ДПО | ИС «ПИШ»`

            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })
            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }

            formik.setValues({
                ...set_inputs,
                ...set_selected,
                current_status: data.status,
            })
        }
    }, [data])

    useEffect(() => {
        if (data) {
            let is_manager = user?.roles.every((value) => value === 'project_manager')  // can't view all projects
            if (Object.keys(select_data).length < select_fields.length) {
                for (let field of ['speciality', 'project']) {
                    if (!Object.keys(select_data).includes(field)) {
                        if (field === 'project' && is_manager) {
                            getRequest('project', setSelectData, { type_data: 'user_projects', 'user_id': user.id }, '', select_data, field)
                        } else {
                            getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        } 
                        break
                    }
                }
            }
        }
    }, [data, select_data, formik.values])

    useEffect(() => {
        if (formik.values?.project_id)
            project_ref.current = formik.values?.project_id
    }, [formik.values])


    const saveFunction = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_by: user.id }
        } else {
            payload = formik.values
        }

        updateRequest('dpo', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/dpo')
                setData(response.data)
            }
        })
        if (formik?.values?.message) {
            putRequest('dpo_messages', {
                content: formik?.values?.message,
                dpo_status: data.status,
                status: data.status,
                created_by: user.id,
                dpo: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <ReactBreadcrumb/>
                <Form>
                    <div className='section-header sticky-top'>
                        <h1 className='page-header'>
                            {`Редактирование записи №${params.id} в БД ДПО`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_DPO}
                                       permission_manage={PERMISSIONS.MANAGE_DPO}
                                       can_back={data?.status > STATUS_NEW
                                           && data?.status !== STATUS_ACCEPT
                                           && data?.status !== STATUS_IMPL_ORDER
                                           && data?.status !== STATUS_DEV_ORDER}
                        />
                    </div>
                    <div
                        className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                        <HeaderStatus style={{ width: '145px' }}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          value={formik.values?.message}
                                          disabled={disabled}
                                          rows='5'
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте
                                следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет
                                    сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>Основные данные</legend>
                                <Input handleChangeValue={formik.handleChange}
                                       label={'Образовательная программа'}
                                       id={'event_name'}
                                       name={'event_name'}
                                       error={formik.errors['event_name']}
                                       invalid={formik.errors['event_name']}
                                       disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status >= STATUS_APPROVE)}
                                       value={formik.values.event_name}
                                       required={REQUIRED_FIELDS[STATUS_NEW].includes('event_name')}
                                />
                                <Row>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'project_id'}
                                               label={'Проект'}
                                               id={'project_id'}
                                               isSearchable={true}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               error={formik.errors['project']}
                                               invalid={formik.errors['project']}
                                               disabled={disabled}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('project') : false}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'event_type'}
                                               label={'Тип программы'}
                                               isClearable={true}
                                               options={EVENT_TYPE}
                                               id={'event_type'}
                                               error={formik.errors['event_type_id']}
                                               invalid={formik.errors['event_type_id']}
                                               disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status >= STATUS_APPROVE)}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('event_type')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'development_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата разработки'}
                                               id={'development_date'}
                                               disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status >= STATUS_APPROVE)}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('development_date')}
                                               value={formik.values.development_date}
                                               error={formik.errors['development_date']}
                                               invalid={formik.errors['development_date']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'implementation_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата внедрения'}
                                               id={'implementation_date'}
                                               disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status >= STATUS_APPROVE)}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('implementation_date')}
                                               value={formik.values.implementation_date}
                                               error={formik.errors['implementation_date']}
                                               invalid={formik.errors['implementation_date']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'speciality_id'}
                                               label={'Специальность и направления подготовки'}
                                               id={'speciality_id'}
                                               isClearable={true}
                                               isSearchable={true}
                                               options={generateOptions('speciality', 'title', select_data)}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('speciality') : false}
                                               disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status >= STATUS_APPROVE)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'is_network'}
                                               label={'Сетевая'}
                                               isClearable={true}
                                               options={IS_NETWORK}
                                               id={'is_network'}
                                               error={formik.errors['is_network_id']}
                                               invalid={formik.errors['is_network_id']}
                                               disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status >= STATUS_APPROVE)}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('is_network')}
                                        />
                                    </Col>
                                </Row>
                                <hr/>
                            </fieldset>
                            {data?.status >= STATUS_DEV_ORDER
                                ? <fieldset>
                                    <legend>Приказ разработки</legend>
                                    <Row>
                                        <Col md={6}>
                                            <Input handleChangeValue={formik.handleChange}
                                                   label={'Номер приказа'}
                                                   id={'dev_order_number'}
                                                   name={'dev_order_number'}
                                                   error={formik.errors['dev_order_number']}
                                                   invalid={formik.errors['dev_order_number']}
                                                   disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status !== STATUS_DEV_ORDER)}
                                                   value={formik.values.dev_order_number}
                                                   required={true}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Field component={FormikDate}
                                                   name={'dev_order_date'}
                                                   handleChangeValue={formik.handleChange}
                                                   label={'Дата'}
                                                   id={'dev_order_date'}
                                                   disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status !== STATUS_DEV_ORDER)}
                                                   required={true}
                                                   value={formik.values.dev_order_date}
                                                   error={formik.errors['dev_order_date']}
                                                   invalid={formik.errors['dev_order_date']}
                                            />
                                        </Col>
                                    </Row>

                                    <MyFileBrowser
                                        height={250}
                                        path={`root/storage/dpo/dpo_${params.id}/dev`}
                                        read_only={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status !== STATUS_DEV_ORDER)}
                                        instanceId={'dev'}/>
                                    <hr/>
                                </fieldset>
                                : null}

                            {data?.status >= STATUS_IMPL_ORDER
                                ? <fieldset>
                                    <legend>Приказ внедрения</legend>
                                    <Row>
                                        <Col md={6}>
                                            <Input handleChangeValue={formik.handleChange}
                                                   label={'Номер приказа'}
                                                   id={'impl_order_number'}
                                                   name={'impl_order_number'}
                                                   error={formik.errors['impl_order_number']}
                                                   invalid={formik.errors['impl_order_number']}
                                                   disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status !== STATUS_IMPL_ORDER)}
                                                   value={formik.values.impl_order_number}
                                                   required={true}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Field component={FormikDate}
                                                   name={'impl_order_date'}
                                                   handleChangeValue={formik.handleChange}
                                                   label={'Дата'}
                                                   id={'impl_order_date'}
                                                   disabled={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status !== STATUS_IMPL_ORDER)}
                                                   required={true}
                                                   value={formik.values.impl_order_date}
                                                   error={formik.errors['impl_order_date']}
                                                   invalid={formik.errors['impl_order_date']}
                                            />
                                        </Col>
                                    </Row>
                                    <MyFileBrowser
                                        height={250}
                                        path={`root/storage/dpo/dpo_${params.id}/impl`}
                                        read_only={disabled || (userHasPermissions(PERMISSIONS.IS_MANAGER) && data?.status !== STATUS_IMPL_ORDER)}
                                        instanceId={'impl'}/>
                                    <hr/>
                                </fieldset>
                                : null}
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}
