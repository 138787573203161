import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Card, Col, Row, Form, ProgressBar } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { Fragment, useEffect, useRef, useState } from 'react'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import {
    STATUS_NEW,
    EVENT_TYPES,
    STATUS_ACCEPT,
    REQUIRED_FIELDS_TEMP,
    BLOCKS, INITIAL_FORMIK_HELPER, STATUS_ADD_RPD, STATUS_OPEN_OOP, MONTHS,
} from './constant'
import { Area, Check, Input } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { useAbac } from 'react-abac'
import { btnMessage } from '../../../utils/utils'
import { REQUIRED_FIELDS } from './constant'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { Field, FieldArray, FormikProvider, useFormik } from 'formik'
import { FormikDate, FormikSelect } from '../../../components/form/formik'

export const EducationalProgramItem = ({
                                           data,
                                           user,
                                           disabled,
                                           messages,
                                           generateOptions,
                                           ErrorsBlock,
                                           MessageHistory,
                                           ActionButtons,
                                           DisableButton,
                                           HeaderStatus,
                                           handleSubmit,
                                           setData,
                                           setMessages,
                                       }) => {
    const params = useParams()
    const navigate = useNavigate()

    const { userHasPermissions } = useAbac()
    const [select_data, setSelectData] = useState({})

    const project_ref = useRef()

    const select_fields = ['speciality_id', 'project', 'supervisor', 'deadlines_oop']
    const given_select_fields = ['event_type', 'is_network']
    const [other_speciality_id, setOtherSpecialityId] = useState(-1)

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'event_name': '',
        'speciality_id': null,
        'project_id': null,
        'responsible_edu': '',
        'event_type': '',
        'start': '',
        'is_network': null,
        'end': '',
        'dev_date': '',
        'impl_date_plan': '',
        'impl_date_real': '',
        'retrain_date': '',
        'start_order_date': '',
        'first_launch_date': '',
        'next_training_date': '',
        'document_link': '',
        'speciality_custom': '',
        'count_educated': 0,
        'rpd_progress': 0,
        'partners': '',
        'months': ['', '', '', '', '', '', '', '', '', '', '', ''],
        'blocks': {
            'matrix_dev': {},
            'reference': {},
            'characteristic': {},
            'curriculum': {},
            'information_pedagogical': {},
            'information_specialist': {},
            'information_mto': {},
            'information_leader': {},
            'extract_protocol': {},
        },
        'oop_progres': 0,
    })

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return getRequest('educational_program', () => null,
                {}, params.id, null, null, true)
                .then((r) => {
                    if (r.data?.results?.[0]?.updated_time !== data?.updated_time) {
                        formik.setFieldError('status', 'Данные устарели! Обновите страницу.')
                        return 'is_canceled'
                    } else {
                        let temp = { ...formik.errors }
                        delete temp?.status
                        formik.setErrors(temp)
                        console.log(values)

                        return saveFunction()
                    }
                })
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    const formik_helper = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            for (let block of [...BLOCKS, 'rpd']) {
                if (values?.[block]?.message) {
                    putRequest('educational_program_messages', {
                        type: block,
                        content: values?.[block]?.message,
                        educational_program_status: data.status,
                        status: data.status,
                        created_by: user.id,
                        educational_program: data.id,
                    }, false).then((r) => {
                        formik_helper.setFieldValue(`${block}.message`, '')
                        getRequest('educational_program_messages', setMessages, {
                            type_data: 'all',
                            educational_program: params.id,
                        }).then()
                    })
                }
            }
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        handleSubmit(formik, saveFunction)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД Образовательные программы | ИС «ПИШ»`

            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })
            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
            }

            let init_helper = {}
            for (let block of BLOCKS) {
                let change_date = !!data.blocks?.[block]?.end_stage_date
                init_helper[block] = { ...INITIAL_FORMIK_HELPER, change_date: change_date, type: block }
            }
            console.log(init_helper)
            formik_helper.setValues({ ...init_helper, rpd: { ...INITIAL_FORMIK_HELPER, type: 'rpd' } })

            if (!set_inputs?.months?.length) {
                set_inputs['months'] = ['', '', '', '', '', '', '', '', '', '', '', '']
            }
            formik.setValues({
                ...set_inputs,
                ...set_selected,
                current_status: data.status,
            })
        }
    }, [data])

    useEffect(() => {
        if (data) {
            if (Object.keys(select_data).length < select_fields.length) {
                for (let field of ['speciality', 'project', 'template_oop', 'deadlines_oop']) {
                    if (!Object.keys(select_data).includes(field)) {
                        getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        break
                    }
                }
            }
            if (select_data['speciality'] && other_speciality_id === -1) {
                let other_id = select_data['speciality'].find((v) => v?.title === 'Другое')?.id
                setOtherSpecialityId(other_id)
            }
            
        }
    }, [data, select_data, formik.values], other_speciality_id)

    useEffect(() => {
        if (formik.values?.project_id !== project_ref.current && Object.keys(select_data).includes('event')) {
            setSelectData({ ...select_data, event: [] })
        }
        if (formik.values?.project_id)
            project_ref.current = formik.values?.project_id
    }, [formik.values])

    const saveFunction = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_by: user.id }
        } else {
            payload = formik.values
        }

        updateRequest('educational_program', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/educational_program')
                setData(response.data)
            }
        })
        if (formik?.values?.message) {
            putRequest('educational_program_messages', {
                content: formik?.values?.message,
                educational_program_status: data.status,
                status: data.status,
                created_by: user.id,
                educational_program: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <ReactBreadcrumb/>
                <Form>
                    <div className='section-header sticky-top'>
                        <h1 className='page-header'>
                            {`Редактирование записи №${params.id} в БД Образовательные программы`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_OOP}
                                       permission_manage={PERMISSIONS.MANAGE_OOP}
                                       can_next={!(data?.status === STATUS_ADD_RPD && userHasPermissions(PERMISSIONS.IS_MANAGER))}
                                       can_back={userHasPermissions(PERMISSIONS.IS_MANAGER) ? false
                                           : data?.status > STATUS_NEW
                                           && data?.status !== STATUS_ACCEPT}
                        />
                    </div>
                    <div
                        className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                        <HeaderStatus style={{ width: '200px' }}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          value={formik.values?.message}
                                          disabled={disabled}
                                          rows='5'
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте
                                следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет
                                    сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>Основные данные</legend>
                                <Row>
                                    <Col md={12}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Наименование программы'}
                                               id={'event_name'}
                                               name={'event_name'}
                                               error={formik.errors['event_name']}
                                               invalid={formik.errors['event_name']}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               value={formik.values.event_name}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('event_name')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                            name={'speciality_id'}
                                            label={'Специальность и направления подготовки'}
                                            id={'speciality_id'}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={generateOptions('speciality', 'title', select_data)}
                                            required={data ? REQUIRED_FIELDS[data.status].includes('speciality') : false}
                                            disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                         />
            
                                    </Col>
                                    
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'event_type'}
                                               label={'Тип программы'}
                                               isClearable={true}
                                               options={EVENT_TYPES}
                                               id={'event_type'}
                                               error={formik.errors['event_type_id']}
                                               invalid={formik.errors['event_typed_id']}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('event_type')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    {formik.values.speciality_id === other_speciality_id 
                                        ? <Col md={6}>
                                         <Input handleChangeValue={formik.handleChange}
                                            label={'Введите специальность и направления подготовки:'}
                                            id={'speciality_custom'}
                                            name={'speciality_custom'}
                                            error={formik.errors['speciality_custom']}
                                            invalid={formik.errors['speciality_custom']}
                                            disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                            value={formik.values.speciality_custom}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('speciality_custom')}
                                        />
                                    </Col> : null}
                                </Row>
                                <Row>    
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'start'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата начала реализации'}
                                               id={'start'}
                                               placeholderText={'дд.мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('start')}
                                               value={formik.values.start}
                                               error={formik.errors['start']}
                                               invalid={formik.errors['start']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'end'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата окончания реализации'}
                                               id={'end'}
                                               placeholderText={'дд.мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('end')}
                                               value={formik.values.end}
                                               error={formik.errors['end']}
                                               invalid={formik.errors['end']}
                                        />
                                    </Col>
                                </Row>
                                {/*<Field component={FormikSelect}*/}
                                {/*       name={'is_network'}*/}
                                {/*       label={'Сетевая'}*/}
                                {/*       isClearable={true}*/}
                                {/*       options={IS_NETWORK}*/}
                                {/*       id={'is_network'}*/}
                                {/*       error={formik.errors['is_network_id']}*/}
                                {/*       invalid={formik.errors['is_network_id']}*/}
                                {/*       disabled={userHasPermissions(PERMISSIONS.IS_MANAGER)*/}
                                {/*       && data?.status >= STATUS_OPEN_OOP ? true : disabled}*/}
                                {/*       required={REQUIRED_FIELDS[STATUS_NEW].includes('is_network')}*/}
                                {/*/>*/}
                                {/*<Row>*/}
                                {/*    <Col md={6}>*/}
                              
                                {/*</Col>*/}
                                {/*<Col md={6}>*/}
                                {/*    <Field component={FormikSelect}*/}
                                {/*           name={'project_id'}*/}
                                {/*           label={'Проект'}*/}
                                {/*           id={'project_id'}*/}
                                {/*           isSearchable={true}*/}
                                {/*           isClearable={true}*/}
                                {/*           options={generateOptions('project', 'title_short', select_data)}*/}
                                {/*           error={formik.errors['project']}*/}
                                {/*           invalid={formik.errors['project']}*/}
                                {/*           disabled={userHasPermissions(PERMISSIONS.IS_MANAGER) ? true : disabled}*/}
                                {/*           required={data ? REQUIRED_FIELDS[data.status].includes('project') : false}*/}
                                {/*    />*/}
                                {/*</Col>*/}
                                {/*</Row>*/}
                                <Row>
                                    <Col md={12}>
                                        <Input handleChangeValue={formik.handleChange}
                                            label={'Задействованные в реализации, высокотехнологичные компании партнеры'}
                                            id={'partners'}
                                            name={'partners'}
                                            error={formik.errors['partners']}
                                            invalid={formik.errors['partners']}
                                            disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                            value={formik.values.partners}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('partners')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'supervisor_id'}
                                               label={'Руководитель паспорта'}
                                               id={'supervisor_id'}
                                               isClearable={true}
                                               isSearchable={true}
                                               error={formik.errors['supervisor']}
                                               invalid={formik.errors['supervisor']}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('supervisor') : false}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                            label={'Ответственный за образование'}
                                            id={'responsible_edu'}
                                            name={'responsible_edu'}
                                            error={formik.errors['responsible_edu']}
                                            invalid={formik.errors['responsible_edu']}
                                            disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                            value={formik.values.responsible_edu}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('responsible_edu')}
                                        />
                                    </Col>
                                </Row>
                                
                                <hr/>
                            </fieldset>
                            <fieldset>
                                <legend>План</legend>
                                <Row>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'dev_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Программа разработана'}
                                               id={'dev_date'}
                                               showMonthYear={true}  
                                               placeholderText={'мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('dev_date')}
                                               value={formik.values.dev_date}
                                               error={formik.errors['dev_date']}
                                               invalid={formik.errors['dev_date']}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'impl_date_plan'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Программа внедрена'}
                                               id={'impl_date_plan'}
                                               showMonthYear={true} 
                                               placeholderText={'мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('impl_date_plan')}
                                               value={formik.values.impl_date_plan}
                                               error={formik.errors['impl_date_plan']}
                                               invalid={formik.errors['impl_date_paln']}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'retrain_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Повторное обучение по программе'}
                                               id={'retrain_date'}
                                               showMonthYear={true} 
                                               placeholderText={'мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('retrain_date')}
                                               value={formik.values.retrain_date}
                                               error={formik.errors['retrain_date']}
                                               invalid={formik.errors['retrain_date']}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <hr/>
                            <fieldset>
                                <legend>Действительность</legend>
                                <Row>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'start_order_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата приказа об открытии'}
                                               id={'start_order_date'}
                                               placeholderText={'дд.мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.MANAGER_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('start_order_date')}
                                               value={formik.values.start_order_date}
                                               error={formik.errors['start_order_date']}
                                               invalid={formik.errors['start_order_date']}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'first_launch_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата первого запуска'}
                                               id={'first_launch_date'}
                                               placeholderText={'дд.мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.MANAGER_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('first_launch_date')}
                                               value={formik.values.first_launch_date}
                                               error={formik.errors['first_launch_date']}
                                               invalid={formik.errors['first_launch_date']}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <Field component={FormikDate}
                                               name={'next_training_date'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата последующего обучения'}
                                               id={'next_training_date'}
                                               placeholderText={'дд.мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.MANAGER_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('next_training_date')}
                                               value={formik.values.retrain_date}
                                               error={formik.errors['next_training_date']}
                                               invalid={formik.errors['next_training_date']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Количество обученных'}
                                               type={'int'}
                                               id={'count_educated'}
                                               name={'count_educated'}
                                               error={formik.errors['count_educated']}
                                               invalid={formik.errors['count_educated']}
                                               disabled={!userHasPermissions(PERMISSIONS.MANAGER_PANEL) || disabled}
                                               value={formik.values.count_educated}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('count_educated')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'impl_date_real'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата внедрения (месяц и год учета в показателе)'}
                                               id={'impl_date_real'}
                                               showMonthYear={true} 
                                               placeholderText={'мм.гггг'}
                                               disabled={!userHasPermissions(PERMISSIONS.MANAGER_PANEL) || disabled}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('impl_date_real')}
                                               value={formik.values.impl_date_real}
                                               error={formik.errors['impl_date_real']}
                                               invalid={formik.errors['impl_date_real']}
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <hr/>

                            <fieldset>
                                <legend>Документы</legend>
                                <Row>
                                    <Col md={12}>
                                        <Input handleChangeValue={formik.handleChange}
                                                label={'Ссылка на документы'}
                                                id={'document_link'}
                                                name={'document_link'}
                                                error={formik.errors['document_link']}
                                                invalid={formik.errors['document_link']}
                                                disabled={!userHasPermissions(PERMISSIONS.DIRECTION_PANEL) || disabled}
                                                value={formik.values.document_link}
                                                required={REQUIRED_FIELDS[STATUS_NEW].includes('document_link')}
                                        />
                                    </Col>
                                </Row>
                                <h>Прикрепленные файлы</h>
                                <MyFileBrowser
                                    path={`root/storage/educational_program/educational_program_${params.id}`}
                                    height={250}
                                    read_only={data?.status > STATUS_NEW
                                    && !userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                        ? true : disabled}
                                    instanceId={`educational_program_${params.id}`}/>
                            </fieldset>
                            <hr/>
                            {data?.status >= STATUS_OPEN_OOP
                                ? <fieldset>
                                    <legend>Отчет о реализации образовательной деятельности по месяцам</legend>
                                    <Row>
                                        <FieldArray name={'months'} render={(arrayHelpers) => {
                                            return arrayHelpers.form.values?.months?.map((fact, month) => (
                                                <Col md={6}>
                                                    <Area label={MONTHS[month]}
                                                          id={`months.${month}`}
                                                          name={`months.${month}`}
                                                          handleChangeValue={formik.handleChange}
                                                          value={fact}
                                                          disabled={(userHasPermissions(PERMISSIONS.IS_MANAGER)
                                                              || userHasPermissions(PERMISSIONS.ADMIN_PANEL)) && data.status === STATUS_OPEN_OOP
                                                              ? disabled : true}
                                                    />
                                                </Col>
                                            ))
                                        }}/>
                                    </Row>
                                    <hr/>
                                </fieldset> : null}
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
        
}
