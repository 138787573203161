import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Card, Col, Row, Form, ProgressBar } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { Fragment, useEffect, useRef, useState } from 'react'
import { getRequest, putRequest, updateRequest } from '../../../actions/common'
import {
    STATUS_NEW,
    EVENT_TYPES,
    STATUS_ACCEPT,
    REQUIRED_FIELDS_TEMP,
    BLOCKS_IDS,
    calculateProgress,
    BLOCKS, INITIAL_FORMIK_HELPER, STATUS_ADD_RPD, STATUS_OPEN_OOP, MAGISTRACY,
} from './constant'
import { Area, Check, Input } from '../../../components/form/forms'
import { PERMISSIONS } from '../../../rbac/constant'
import { useAbac } from 'react-abac'
import { btnMessage } from '../../../utils/utils'
import { REQUIRED_FIELDS } from './constant'
import { MyFileBrowser } from '../../../components/file_manager/file_manager'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikDate, FormikSelect } from '../../../components/form/formik'
import { DownloadFile } from '../../../components/file_manager/file_handlers'

export const ControlOopItem = ({
                                           data,
                                           user,
                                           disabled,
                                           messages,
                                           generateOptions,
                                           ErrorsBlock,
                                           MessageHistory,
                                           ActionButtons,
                                           DisableButton,
                                           HeaderStatus,
                                           handleSubmit,
                                           setData,
                                           setMessages,
                                       }) => {
    const params = useParams()
    const navigate = useNavigate()

    const { userHasPermissions } = useAbac()
    const [select_data, setSelectData] = useState({})
    const [other_speciality_id, setOtherSpecialityId] = useState(-1)
    const project_ref = useRef()

    const select_fields = ['speciality_id', 'project',  'supervisor', 'deadlines_oop']
    const given_select_fields = ['event_type', 'is_network']
    const disable_main_info = true
    const defaultDateEnd = new Date('2030-08-31')
    const today = new Date()
    const [blocks_number, setBlocksNumber] = useState(BLOCKS.length)

    const [input_fields, setInputFields] = useState({
        'status': 1,
        'current_status': 1,
        'save_form': false,
        'is_upload': false,
        'event_name': '',
        'speciality_id': null,
        'speciality_custom': '',
        'project_id': null,
        'responsible_edu': '',
        'event_type': '',
        'start': '',
        'is_network': null,
        'end': defaultDateEnd,
        'rpd_progress': 0,
        'partners': '',
        'months': ['', '', '', '', '', '', '', '', '', '', '', ''],
        'blocks': {
            'presentation': {},
            'matrix_dev': {},
            'reference': {},
            'characteristic': {},
            'curriculum': {},
            'information_pedagogical': {},
            'information_specialist': {},
            'information_mto': {},
            'information_leader': {},
            'extract_protocol': {},
        },
        'oop_progres': 0,
    })

    const formik = useFormik({
        validationSchema: data ? REQUIRED_FIELDS_TEMP[data?.status] : null,
        initialValues: input_fields,
        onSubmit: (values) => {
            return getRequest('control_oop', () => null,
                {}, params.id, null, null, true)
                .then((r) => {
                    if (r.data?.results?.[0]?.updated_time !== data?.updated_time) {
                        formik.setFieldError('status', 'Данные устарели! Обновите страницу.')
                        return 'is_canceled'
                    } else {
                        let temp = { ...formik.errors }
                        delete temp?.status
                        formik.setErrors(temp)
                        return saveFunction()
                    }
                })
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    const formik_helper = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            for (let block of [...BLOCKS, 'rpd']) {
                if (values?.[block]?.message) {
                    putRequest('control_oop_messages', {
                        type: block,
                        content: values?.[block]?.message,
                        educational_program_status: data.status,
                        status: data.status,
                        created_by: user.id,
                        educational_program: data.id,
                    }, false).then((r) => {
                        formik_helper.setFieldValue(`${block}.message`, '')
                        getRequest('educational_program_messages', setMessages, {
                            type_data: 'all',
                            educational_program: params.id,
                        }).then()
                    })
                }
            }
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    const setIsUpload = (value) => {
        formik.setFieldValue('is_upload', value)
    }

    useEffect(() => {
        handleSubmit(formik, saveFunction)
    }, [formik.values])

    useEffect(() => {
        if (data) {
            document.title = `Редактирование записи №${params.id} в БД Контроль разработки ООП | ИС «ПИШ»`

            let set_selected = {}
            select_fields.forEach((field) => {
                set_selected[`${field}_id`] = data[field]?.id
            })
            given_select_fields.forEach((field) => {
                set_selected[field] = data[field]
            })
            let set_inputs = {}
            for (let field in input_fields) {
                set_inputs[field] = data[field]
                if (field === 'end' && !set_inputs[field]) {
                    set_inputs[field] = defaultDateEnd
                }
            }

            let init_helper = {}
            for (let block of BLOCKS) {
                let change_date = !!data.blocks?.[block]?.end_stage_date
                init_helper[block] = { ...INITIAL_FORMIK_HELPER, change_date: change_date, type: block }
            }

            formik_helper.setValues({ ...init_helper, rpd: { ...INITIAL_FORMIK_HELPER, type: 'rpd' } })

            if (!set_inputs?.months?.length) {
                set_inputs['months'] = ['', '', '', '', '', '', '', '', '', '', '', '']
            }
            formik.setValues({
                ...set_inputs,
                ...set_selected,
                current_status: data.status,
            })
        }
    }, [data])

    useEffect(() => {
        if (data) {
            if (Object.keys(select_data).length < select_fields.length) {
                for (let field of ['speciality', 'project', 'template_oop', 'deadlines_oop']) {
                    if (!Object.keys(select_data).includes(field)) {
                        getRequest(field, setSelectData, { type_data: 'all' }, '', select_data, field)
                        break
                    }
                }
            }
            if (select_data['speciality'] && other_speciality_id === -1) {
                let other_id = select_data['speciality'].find((v) => v?.title === 'Другое')?.id
                setOtherSpecialityId(other_id)
            }
            if (select_data?.template_oop && formik.values.event_type !== MAGISTRACY) {
                let index = -1
                select_data?.template_oop.map((v, i) => {
                    if (v.name === 'information_leader') {
                        index = i
                    }
                })
                if (index > -1) select_data?.template_oop.splice(index, 1)
                setBlocksNumber(BLOCKS.length - 1)
            }
        }
    }, [data, select_data, formik.values, other_speciality_id, blocks_number])

    useEffect(() => {
        if (formik.values?.project_id !== project_ref.current && Object.keys(select_data).includes('event')) {
            setSelectData({ ...select_data, event: [] })
        }
        if (formik.values?.project_id)
            project_ref.current = formik.values?.project_id
    }, [formik.values])

    const saveFunction = () => {
        formik.setFieldValue('save_form', false)

        let payload = null
        if (STATUS_ACCEPT === formik.values.status && formik.values.status > data?.status) {
            payload = { ...formik.values, direction_by: user.id }
        } else {
            payload = formik.values
        }

        updateRequest('control_oop', { 'common': payload }, params.id).then((response) => {
            if (response.status === 200) {
                if (payload?.status !== data?.status)
                    navigate('/control_oop')
                setData(response.data)
            }
        })

        if (formik?.values?.message) {
            putRequest('control_oop_messages', {
                content: formik?.values?.message,
                educational_program_status: data.status,
                status: data.status,
                created_by: user.id,
                educational_program: data.id,
            }, false).then()
            document.getElementById('message').value = null
            formik.setFieldValue('message', '')
        }

        return Promise.resolve('is_saved')
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <ReactBreadcrumb/>
                <Form>
                    <div className='section-header sticky-top'>
                        <h1 className='page-header'>
                            {`Редактирование записи №${params.id} в БД Контроль разработки ООП`}
                            <DisableButton/>
                        </h1>
                        <ActionButtons form={formik}
                                       permission_control={PERMISSIONS.CONTROL_OOP}
                                       permission_manage={PERMISSIONS.MANAGE_OOP}
                                       can_next={!(data?.status === STATUS_ADD_RPD && userHasPermissions(PERMISSIONS.IS_MANAGER))}
                                       can_back={userHasPermissions(PERMISSIONS.IS_MANAGER) ? false
                                           : data?.status > STATUS_NEW
                                           && data?.status !== STATUS_ACCEPT}
                        />
                    </div>
                    <div
                        className='mb-2 text-light text-center d-flex flex-wrap align-items-center align-content-center justify-content-md-center'>
                        <HeaderStatus style={{ width: '200px' }}/>
                    </div>
                    <Button variant={'info'} onClick={btnMessage}><IconText icon={'comment'}
                                                                            text={'Сообщения'}/></Button>
                    <Card className={'bg-light mt-4'}
                          style={messages && messages?.length !== 0 || formik.errors?.message ? { display: 'block' } : { display: 'none' }}
                          id={'card-message'}>
                        <Card.Body>
                            <div className='alert alert-danger'
                                 style={{ display: formik.errors?.message ? 'block' : 'none' }}>
                                <p>Исправьте следующие ошибки:</p>
                                <ul>
                                    <li>Необходимо заполнить: "Комментарий"</li>
                                </ul>
                            </div>
                            <label>История сообщений</label>
                            <MessageHistory/>
                            <div id='field-mention' className='content-group navbar-nav px-0'>
                                <div id='field-comment'>
                                    <label htmlFor='message'>Комментарий</label>
                                    <Area id='message'
                                          name={'message'}
                                          value={formik.values?.message}
                                          disabled={disabled}
                                          rows='5'
                                          error={formik.errors['message']}
                                          invalid={formik.errors['message']}
                                          handleChangeValue={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    <Card className={'bg-light mb-3 mt-3'}>
                        <Card.Body>
                            <p>
                                <strong>Обратите внимание!</strong> При работе с данными карточки учитывайте
                                следующие
                                условные обозначения,
                                используемые в интерфейсе:
                            </p>
                            <ul>
                                <li>поля помеченные <strong className='text-danger'>красной
                                    «*»</strong> являются <strong>обязательными
                                    на текущем
                                    этапе для перехода на следующий</strong>;
                                </li>
                                <li>поля помеченные <strong className='text-success'>зелёной
                                    «*»</strong> являются <strong>обязательными
                                    для
                                    определенных условий</strong>;
                                </li>
                                <li>кнопка <span className='badge badge-success'>Сохранить</span> выполняет
                                    сохранение
                                    текущего состояния карточки (требуется
                                    заполнения ключевых полей);
                                </li>
                                <li>кнопка <span
                                    className='badge badge-success'>Сохранить + отправить далее</span> сохраняет
                                    карточку и
                                    проверяет выполнение
                                    условий текущего этапа (при выполнении условий карточка переходит на следующий
                                    этап).
                                </li>
                            </ul>
                            <hr/>
                            <ErrorsBlock form={formik}/>
                            <fieldset>
                                <legend>Основные данные</legend>
                                <Row>
                                    <Col md={12}>
                                        <Input handleChangeValue={formik.handleChange}
                                               label={'Наименование программы'}
                                               id={'event_name'}
                                               name={'event_name'}
                                               error={formik.errors['event_name']}
                                               invalid={formik.errors['event_name']}
                                               disabled={disable_main_info}
                                               value={formik.values.event_name}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('event_name')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        {formik.values.speciality_id === other_speciality_id 
                                            ? <Input handleChangeValue={formik.handleChange}
                                                label={'Направление подготовки'}
                                                id={'speciality_custom'}
                                                name={'speciality_custom'}
                                                error={formik.errors['speciality_custom']}
                                                invalid={formik.errors['speciality_custom']}
                                                disabled={disable_main_info}
                                                value={formik.values.speciality_custom}
                                                required={REQUIRED_FIELDS[STATUS_NEW].includes('speciality_custom')}
                                            />
                                            : <Field component={FormikSelect}
                                                name={'speciality_id'}
                                                label={'Направление подготовки'}
                                                id={'speciality_id'}
                                                isClearable={true}
                                                isSearchable={true}
                                                options={generateOptions('speciality', 'title', select_data)}
                                                required={data ? REQUIRED_FIELDS[data.status].includes('speciality') : false}
                                                disabled={disable_main_info}
                                            />
                                        }
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect}
                                               name={'event_type'}
                                               label={'Тип программы'}
                                               isClearable={true}
                                               options={EVENT_TYPES}
                                               id={'event_type'}
                                               error={formik.errors['event_type_id']}
                                               invalid={formik.errors['event_typed_id']}
                                               disabled={disable_main_info}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('event_type')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'start'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата первого набора'}
                                               id={'start'}
                                               disabled={disable_main_info}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('start')}
                                               value={formik.values.start}
                                               error={formik.errors['start']}
                                               invalid={formik.errors['start']}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikDate}
                                               name={'end'}
                                               handleChangeValue={formik.handleChange}
                                               label={'Дата окончания'}
                                               id={'end'}
                                               disabled={disable_main_info}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('end')}
                                               value={formik.values.end}
                                               error={formik.errors['end']}
                                               invalid={formik.errors['end']}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Input handleChangeValue={formik.handleChange}
                                            label={'Задействованные в реализации, высокотехнологичные компании партнеры'}
                                            id={'partners'}
                                            name={'partners'}
                                            error={formik.errors['partners']}
                                            invalid={formik.errors['partners']}
                                            disabled={disable_main_info}
                                            value={formik.values.partners}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('partners')}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field component={FormikSelect} name={'supervisor_id'}
                                               label={'Руководитель паспорта'}
                                               id={'supervisor_id'}
                                               isClearable={true}
                                               isSearchable={true}
                                               error={formik.errors['supervisor']}
                                               invalid={formik.errors['supervisor']}
                                               options={generateOptions('project', 'title_short', select_data)}
                                               required={data ? REQUIRED_FIELDS[data.status].includes('supervisor') : false}
                                               disabled={disable_main_info}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Input handleChangeValue={formik.handleChange}
                                            label={'Ответственный за образование'}
                                            id={'responsible_edu'}
                                            name={'responsible_edu'}
                                            error={formik.errors['responsible_edu']}
                                            invalid={formik.errors['responsible_edu']}
                                            disabled={disable_main_info}
                                            value={formik.values.responsible_edu}
                                            required={REQUIRED_FIELDS[STATUS_NEW].includes('responsible_edu')}
                                        />
                                    </Col>
                                </Row>
                                <hr/>
                            </fieldset>

                            {data?.status >= STATUS_OPEN_OOP
                                ? <fieldset>
                                    <legend>Готовность к реализации ООП: {calculateProgress(formik.values, blocks_number)}%</legend>
                                    <ProgressBar now={calculateProgress(formik.values, blocks_number)}
                                                 className={'mb-4'}
                                                 label={`${calculateProgress(formik.values, blocks_number) ? calculateProgress(formik.values, blocks_number) : 0}%`}
                                                 variant={calculateProgress(formik.values, blocks_number) > 98 ? 'success' : 'primary'}/>
                                    <hr/>
                                </fieldset>
                            : null}

                            {select_data.template_oop && data?.status >= STATUS_OPEN_OOP ? select_data.template_oop.map((v) => {
                                let block = v.name
                                let almost_ready = (formik.values?.blocks?.[block]?.progress > 98)
                                let missed_deadline = (!almost_ready && today > new Date(formik.values?.blocks?.[block]?.end_stage_date))
                        
                                return <fieldset key={block}>
                                    <legend>{v.title}</legend>
                                    <ProgressBar now={formik.values?.blocks?.[block]?.progress}
                                                 className={'mb-4'}
                                                 label={`${formik.values?.blocks?.[block]?.progress ? formik.values?.blocks?.[block]?.progress : 0}%`}
                                                 variant={almost_ready ? 'success' : missed_deadline ? 'danger' : 'primary'}/>

                                        {userHasPermissions(PERMISSIONS.OOP_DIRECTION_PANEL) || userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                            ? <div>
                                                <Input handleChangeValue={formik.handleChange}
                                                    min={0}
                                                    max={100}
                                                    type={'percent'}
                                                    label={`Прогресс выполнения ${formik.values?.blocks?.[block]?.progress
                                                        ? formik.values?.blocks?.[block]?.progress
                                                        : 0}%`}
                                                    id={'blocks.${block}.progress'}
                                                    name={`blocks.${block}.progress`}
                                                    error={formik.errors?.blocks?.[block]?.['progress']}
                                                    invalid={formik.errors?.blocks?.[block]?.['progress']}
                                                    disabled={disabled}
                                                    value={formik.values?.blocks?.[block]?.progress}
                                                    required={REQUIRED_FIELDS[STATUS_NEW].includes('progress')}
                                                    small={'Нельзя ввести значение больше 100'}
                                                />
                                            </div>
                                        : null}
                                    
                                    <Row>
                                        <Col md={6}>
                                            {formik_helper.values?.[block].change_date
                                                ? <Field component={FormikDate}
                                                    className={missed_deadline ? 'form-text text-danger' : ''}
                                                    classNameField={missed_deadline ? 'form-control is-invalid' : ''}
                                                    name={`blocks.${block}.end_stage_date`}
                                                    handleChangeValue={formik.handleChange}
                                                    label={'Дата завершения этапа'}
                                                    id={`blocks.${block}.end_stage_date`}
                                                    disabled={formik.values?.[block]?.progress > 99
                                                    || userHasPermissions(PERMISSIONS.IS_MANAGER) ? true : disabled}
                                                    required={REQUIRED_FIELDS[STATUS_NEW].includes('end_stage_date')}
                                                    value={formik.values?.blocks?.[block]?.end_stage_date}
                                                />
                                                : <Input type={'date'}
                                                        label={'Дата завершения этапа'}
                                                        disabled={true}
                                                        value={select_data?.deadlines_oop
                                                            ? select_data?.deadlines_oop.find((v) => v?.id === BLOCKS_IDS[block])?.date
                                                            : ''}
                                                />
                                            }
                                        </Col>
                                        <Col md={6}>
                                            {userHasPermissions(PERMISSIONS.CONTROL_OOP)
                                                ? <div style={{ marginTop: '2em' }}>
                                                    <Check label={'Изменить дату'}
                                                        name={`${block}.change_date`}
                                                        id={`${block}.change_date`}
                                                        handleChangeValue={formik_helper.handleChange}
                                                        value={formik_helper.values?.[block].change_date}
                                                    /> 
                                                </div>
                                            : null}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <DownloadFile folder={'template_oops'}
                                                className={'mb-4'}
                                                label={'Шаблон документа:  '}
                                                file={v?.filename}
                                                title={'template_oop'}/>
                                        </Col>
                                        <Col md={6}>
                                            <div style={{ float: 'right' }}>
                                                <Button variant={'info'} className={'mb-4'} onClick={() => {
                                                    formik_helper.setFieldValue(`${block}.show_messages`,
                                                        !formik_helper.values?.[block].show_messages)
                                                }}>
                                                    <IconText icon={'comment'}
                                                              text={'Сообщения'}/>
                                                </Button>
                                            </div>
                                            <Card className={'mb-4'}
                                                style={formik_helper.values?.[block].show_messages ? { display: 'block' } : { display: 'none' }}>
                                                <Card.Body>
                                                    <label>История сообщений</label>
                                                    <MessageHistory type={block}/>
                                                    {data?.status >= STATUS_OPEN_OOP && userHasPermissions(PERMISSIONS.IS_MANAGER)
                                                        ? null
                                                        : <div id='field-mention' className='content-group navbar-nav px-0'>
                                                            <div id='field-comment'>
                                                                <label htmlFor='message'>Комментарий</label>
                                                                <Area id={`${block}.message`}
                                                                    name={`${block}.message`}
                                                                    value={formik_helper.values?.[block]?.message}
                                                                    disabled={disabled}
                                                                    rows='5'
                                                                    handleChangeValue={formik_helper.handleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {data?.status >= STATUS_OPEN_OOP && userHasPermissions(PERMISSIONS.IS_MANAGER)
                                                        ? null
                                                        : <Button variant={'success'} onClick={() => formik_helper.submitForm()}>
                                                            Отправить комментарий
                                                        </Button>
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                
                                    <MyFileBrowser
                                        path={`root/storage/educational_program/educational_program_${params.id}/${block}`}
                                        read_only={formik.values?.blocks?.[block]?.progress > 99 ? true : disabled}
                                        can_delete={userHasPermissions(PERMISSIONS.CONTROL_OOP)}
                                        height={250}
                                        instanceId={`${block}`}/>
                                    <hr/>
                                </fieldset>
                            }) : null}

                            {(userHasPermissions(PERMISSIONS.OOP_DIRECTION_PANEL) || userHasPermissions(PERMISSIONS.DIRECTION_PANEL)) && data?.status >= STATUS_OPEN_OOP
                                ? <fieldset>
                                    <legend>Приказ об открытии ООП</legend>
                                            <MyFileBrowser
                                                path={`root/storage/educational_program/educational_program_${params.id}/oop_block`}
                                                read_only={calculateProgress(formik.values, blocks_number) > 99 ? true : disabled}
                                                setIsEmpty={setIsUpload}
                                                height={250}
                                                instanceId={'oop_block'}/>
                                    <hr/>
                                </fieldset>
                            : null}
                            
                            {data?.status >= STATUS_ADD_RPD
                                ? <fieldset>
                                    <legend>РПД: {formik.values?.rpd_progress}%</legend>
                                    <ProgressBar now={formik.values?.rpd_progress}
                                                 className={'mb-4'}
                                                 label={`${formik.values?.rpd_progress ? formik.values?.rpd_progress : 0}%`}
                                                 variant={formik.values?.rpd_progress > 98 ? 'success' : 'primary'}/>
                                    {userHasPermissions(PERMISSIONS.CONTROL_OOP)
                                        ? <Input handleChangeValue={formik.handleChange}
                                               min={0} max={100}
                                               type={'percent'}
                                               label={'Прогресс выполнения'}
                                               id={'rpd_progress'}
                                               name={'rpd_progress'}
                                               error={formik.errors['rpd_progress']}
                                               invalid={formik.errors['rpd_progress']}
                                               disabled={disabled}
                                               value={formik.values.rpd_progress}
                                               required={REQUIRED_FIELDS[STATUS_NEW].includes('rpd_progress')}
                                               small={'Нельзя ввести значение больше 100'}
                                        />
                                    : null}
                                    <div>
                                        <Button variant={'info'} className={'mb-4'} onClick={() => {
                                            formik_helper.setFieldValue('rpd.show_messages',
                                                !formik_helper.values?.rpd?.show_messages)
                                        }}>
                                            <IconText icon={'comment'}
                                                      text={'Сообщения'}/>
                                        </Button>
                                    </div>
                                    <Card className={'mb-4'}
                                          style={formik_helper.values?.rpd?.show_messages ? { display: 'block' } : { display: 'none' }}>
                                        <Card.Body>
                                            <label>История сообщений</label>
                                            <MessageHistory type={'rpd'}/>
                                            {userHasPermissions(PERMISSIONS.CONTROL_OOP)
                                                ? <Fragment>
                                                    <div id='field-mention' className='content-group navbar-nav px-0'>
                                                        <div id='field-comment'>
                                                            <label htmlFor='message'>Комментарий</label>
                                                            <Area id={'rpd.message'}
                                                                  name={'rpd.message'}
                                                                  value={formik_helper.values?.rpd?.message}
                                                                  disabled={disabled}
                                                                  rows='5'
                                                                  handleChangeValue={formik_helper.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Button variant={'success'}
                                                            onClick={() => formik_helper.submitForm()}>
                                                        Отправить комментарий
                                                    </Button>
                                                </Fragment> 
                                            : null}
                                        </Card.Body>
                                    </Card>
                                    <MyFileBrowser
                                        path={`root/storage/educational_program/educational_program_${params.id}/rpd_block`}
                                        read_only={data && data.status >= STATUS_ADD_RPD ? disabled : true}
                                        height={250}
                                        instanceId={'rpd_block'}/>
                                    <hr/>
                                </fieldset>
                            : null} 

                            {userHasPermissions(PERMISSIONS.OOP_DIRECTION_PANEL) && data?.status >= STATUS_OPEN_OOP
                                ? <fieldset>
                                    <legend>Приказы о зачислении</legend>
                                            <MyFileBrowser
                                                path={`root/storage/educational_program/educational_program_${params.id}/enrollment_block`}
                                                read_only={calculateProgress(formik.values, blocks_number) > 99 ? true : disabled}
                                                setIsEmpty={setIsUpload}
                                                height={250}
                                                instanceId={'enrollment_block'}/>
                                    <hr/>
                                </fieldset>
                            : null}

                            <fieldset>
                                <legend>Прикрепленные файлы</legend>
                                <MyFileBrowser
                                    path={`root/storage/educational_program/educational_program_${params.id}`}
                                    read_only={data?.status > STATUS_NEW
                                    && !userHasPermissions(PERMISSIONS.DIRECTION_PANEL)
                                        ? true : disabled}
                                    instanceId={`educational_program_${params.id}`}/>
                            </fieldset>
                        </Card.Body>
                    </Card>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
        
}
