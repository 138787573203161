import * as React from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { createContext, useState } from 'react'

export const LoaderContext = createContext(null)

export const Loader = ({ children }) => {
    const [show_loader, setShowLoader] = useState(false)

    return <LoaderContext.Provider value={setShowLoader}>
        {
            show_loader
                ? <div className={'loader d-flex justify-content-center'}>
                    <div className={'loader_circle align-self-center'}>
                        <ThreeDots
                            height='80'
                            width='80'
                            radius='5'
                            color='white'
                            secondaryColor='white'
                        />
                    </div>
                </div> : null
        }
        {children}
    </LoaderContext.Provider>
}
