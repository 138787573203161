import * as React from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SelectReact } from '../../../components/form/select'
import { getRequest, updateRequest } from '../../../actions/common'
import { Area, Input } from '../../../components/form/forms'
import { setChangedValue } from '../../../utils/utils'
import CalculationBlock from '../../../components/indicator/calculation_block'
import { useAbac } from 'react-abac'
import { PERMISSIONS } from '../../../rbac/constant'

const IndicatorUpdate = () => {
    const [indicator, setIndicator] = useState(null)
    const [units, setUnits] = useState(null)
    const [unit, setUnit] = useState(null)

    const { userHasPermissions } = useAbac()

    const [projects_indicators, setProjects] = useState(null)

    const [changes, setChanges] = useState({})

    const params = useParams()
    const navigate = useNavigate()


    useEffect(() => {
        if (!indicator) {
            getRequest('indicator', setIndicator, {}, params.id).then()
        } else {
            setUnit(indicator.results[0]['unit_id'])
            document.title = `${indicator.results[0].title_short} | ИС «ПИШ»`
        }
    }, [indicator])

    useEffect(() => {
        if (!units) {
            getRequest('unit', setUnits)
        }
    }, [units])

    useEffect(() => {
        if (!projects_indicators) {
            getRequest('indicator_project', setProjects, { 'indicator': params.id })
        }
    }, [projects_indicators])

    useEffect(() => {
        if (unit !== null && unit !== undefined) {
            setChangedValue(setChanges, changes, 'indicator', 'unit_id', unit)
        }
    }, [unit])

    const Units = () => {
        let options = []
        let placeholder = ''
        if (units !== null && units !== undefined && indicator && units.results !== null && units.results !== undefined) {
            for (let i = 0; i < units.results.length; i++) {
                if (units.results[i]) {

                    if (units.results[i].title === indicator.results[0].unit) {
                        placeholder = units.results[i].title
                    }
                    options.push({ value: units.results[i].id, label: units.results[i].title })
                }
            }
        }
        return <SelectReact options={options} setState={setUnit} value={unit} selectID='unit_id'
                            placeholder={placeholder}/>
    }

    const CardProjects = () => {
        if (projects_indicators) {
            let projects = []
            projects_indicators.forEach((element) => {
                projects.push(<Card key={element.id} className={'mb-4'}>
                    <Card.Body>
                        <Row>
                            <Col md={3} className='d-flex justify-content-center align-items-center'>
                                <div className={'lead mb-0'}>{element.project.title_short}</div>
                            </Col>
                            <Col md={3}>
                                <Input id='value' label={'Значение'}
                                       handleChangeValue={handleChangeValue}
                                       value={element.value}
                                       data_change_key={'project'}
                                       data_foreign_id={element.project.id}
                                />
                            </Col>
                            <Col md={3}>
                                <Input id='value_plan' label={'Ожидается'}
                                       handleChangeValue={handleChangeValue}
                                       value={element.value_plan}
                                       data_change_key={'project'}
                                       data_foreign_id={element.project.id}
                                />
                            </Col>
                            <Col md={3}>
                                <Input id='value_passport' label={'Паспорт'}
                                       handleChangeValue={handleChangeValue}
                                       value={element.value_passport}
                                       data_change_key={'project'}
                                       data_foreign_id={element.project.id}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>)
            })
            return projects
        }
    }

    const handleChangeValue = (e) => {
        e.preventDefault()
        let target = e.target.getAttribute('data_change_key')
        let sub_key = e.target.getAttribute('data_foreign_id')
        setChangedValue(setChanges, changes, target, e.target.id, e.target.value, sub_key)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (changes && indicator) {
            updateRequest('indicator', changes, indicator.results[0].id).then()
        }
        navigate('/indicator')
    }

    return (<React.Fragment>
        <ReactBreadcrumb/>
        <h1 className={'page-header'}>
            <span className={'mr-1'}>Редактировать</span>
            <small className={'text-muted'}>{indicator !== null ? indicator.results[0].title_short : null}</small>
        </h1>
        <form onSubmit={handleSubmit}>
            <Card>
                <Card.Body>
                    <Input id='title' label={'Заголовок'}
                           required={true}
                           handleChangeValue={handleChangeValue}
                           value={indicator ? indicator.results[0]['title'] : null}
                           data_change_key={'indicator'}
                    />
                    <Input id='title_short' label={'Сокращенное наименование'}
                           handleChangeValue={handleChangeValue}
                           value={indicator ? indicator.results[0]['title_short'] : null}
                           data_change_key={'indicator'}
                    />

                    <Area id='description' label={'Описание'}
                          rows='3'
                          handleChangeValue={handleChangeValue}
                          value={indicator ? indicator.results[0]['description'] : null}
                          data_change_key={'indicator'}
                    />

                    <div className='form-group'>
                        <Form.Label>Единицы измерения</Form.Label>
                        <Units/>
                    </div>
                    <Row className={'mb-4'}>
                        <Col md={'4'}>
                            <Input id='value' label={'Значение'}
                                   handleChangeValue={handleChangeValue}
                                   value={indicator ? indicator.results[0]['value'] : null}
                                   data_change_key={'indicator'}
                            />
                        </Col>
                        <Col md={'4'}>
                            <Input id='value_plan' label={'Ожидается'}
                                   handleChangeValue={handleChangeValue}
                                   value={indicator ? indicator.results[0]['value_plan'] : null}
                                   data_change_key={'indicator'}
                            />
                        </Col>
                        <Col md={'4'}>
                            <Input id='value_passport' label={'Паспорт'}
                                   handleChangeValue={handleChangeValue}
                                   value={indicator ? indicator.results[0]['value_passport'] : null}
                                   data_change_key={'indicator'}
                            />
                        </Col>
                    </Row>
                    <hr/>
                    {userHasPermissions(PERMISSIONS.DIRECTION_PANEL) ? <>
                        <CalculationBlock
                            indicator={indicator?.results?.[0]}
                            title={'Расчет показателя'}
                            type={'calculation_json'}
                        />
                        <CalculationBlock
                            indicator={indicator?.results?.[0]}
                            title={'Расчет планового показателя'}
                            type={'calculation_plan_json'}
                        />
                    </> : null}
                    <fieldset>
                        <legend>Проекты</legend>
                        <CardProjects/>
                    </fieldset>
                </Card.Body>
                <Card.Footer>
                    <div className={'button-actions'}>
                        <Button variant='success' type='submit'>Сохранить</Button>
                        <Button variant='secondary' onClick={() => {
                            navigate(`/indicator/update/${params.id}`)
                        }}>Сбросить</Button>
                    </div>
                </Card.Footer>
            </Card>
        </form>
    </React.Fragment>)
}

export default IndicatorUpdate
