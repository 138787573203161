import * as React from 'react'
import { Table } from 'react-bootstrap'

const ReactTableIndicator = ({ data, indicators, total, header_data }) => {

    const addOrUpdateTotal = (dict, field, element) => {
        if (Object.keys(dict).includes(field)) {
            dict[field]['value'] += element.value
            dict[field]['value_plan'] += element.value_plan
            dict[field]['value_passport'] += element.value_passport
        } else {
            dict[field] = {}
            dict[field]['value'] = element.value
            dict[field]['value_plan'] = element.value_plan
            dict[field]['value_passport'] = element.value_passport
        }
    }

    const round = (value) => {
        return Math.round(value * 100) / 100
    }

    const TableHeader = () => {
        if (data && header_data) {
            let values_component = <React.Fragment>
                <td className='text-center bg-light'>Факт</td>
                <td className='text-center bg-light'>Ожидается</td>
                <td className='text-center bg-light'>Паспорт</td>
            </React.Fragment>
            let elements = []
            let elements_values = [<React.Fragment key={'total_values'}>{values_component}</React.Fragment>]

            elements.push(<React.Fragment key={'header'}>
                    <th style={{ position: 'sticky', left: 0, minWidth: '45px' }} className='bg-light' rowSpan='2'>#</th>
                    <th style={{ position: 'sticky', left: '45px', minWidth: '350px' }} className='bg-light' rowSpan='2'>
                        Наименование показателя
                    </th>
                    <th style={{ position: 'sticky', left: '395px', minWidth: '100px' }} className='bg-light' rowSpan='2'>
                        Единица измерения
                    </th>
                    <th width='300' className='text-center bg-light' colSpan='3'
                        key={'total'}>Конечный результат
                    </th>
                </React.Fragment>,
            )
            header_data?.forEach((element) => {
                elements.push(<th width='300' className='text-center bg-light' colSpan='3'
                                  key={element.title_short}>{element.title_short}</th>)
                elements_values.push(<React.Fragment key={`${element.title_short}_values`}>
                    {values_component}
                </React.Fragment>)
            })

            return <React.Fragment>
                <tr>{elements}</tr>
                <tr>{elements_values}</tr>
            </React.Fragment>
        }
        return null
    }

    const TableBody = () => {
        if (data && indicators) {
            let rows = []
            let i = 1

            indicators?.forEach((indicator) => {
                if (Object.keys(total).includes(indicator.title_short)) {
                    let elements = [<React.Fragment key={`${i}_left_header`}>
                        <th style={{ position: 'sticky', left: 0, maxWidth: '45px' }} className={'bg-light'}>{i}</th>
                        <th style={{ position: 'sticky', left: '45px', minWidth: '350px' }}
                            className={'text-left bg-light'}>{indicator.title}</th>
                        <th style={{ position: 'sticky', left: '395px', minWidth: '100px' }}
                            className={'bg-light'}>{indicator.unit}</th>
                    </React.Fragment>]
                    let j = 1
                    data?.forEach((element) => {
                        if (indicator.title === element.indicator.title) {
                            elements.push(<React.Fragment key={`${i}_${j}_value`}>
                                <td>{round(element.value)}</td>
                                <td>{round(element.value_plan)}</td>
                                <td>{round(element.value_passport)}</td>
                            </React.Fragment>)
                        }
                        j += 1
                    })
                    elements.splice(1, 0, <React.Fragment key={`${i}_${j}_total_value`}>
                        <td>{round(total[indicator.title_short].value)}</td>
                        <td>{round(total[indicator.title_short].value_plan)}</td>
                        <td>{round(total[indicator.title_short].value_passport)}</td>
                    </React.Fragment>)
                    rows.push(<tr key={`${i}_row`}>{elements}</tr>)
                    i += 1
                }
            })
            return rows
        }
        return null
    }

    return <React.Fragment>
        <div style={{ width: '100%', maxHeight: '800px', overflow: 'auto' }}>
            <Table size={'md'} bordered={true} hover={true} style={{ borderCollapse: 'separate', borderSpacing: 0 }}>
                <thead style={{ position: 'sticky', top: 0, zIndex: 2 }}><TableHeader/></thead>
                <tbody><TableBody/></tbody>
            </Table>
        </div>
    </React.Fragment>
}

export default ReactTableIndicator
