import * as React from 'react'
import ReactBreadcrumb from '../../components/breadcrumbs'
import { BaseLayout } from '../../components/base'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AuthContext } from '../../auth'

const AccessError = () => {
    const navigate = useNavigate()

    useEffect(() => {
        if (typeof window !== 'undefined' && (!localStorage.getItem('token'))) {
            navigate('/login', { state: { from: window.location.href }, replace: true })
        }
    }, [])

    return <BaseLayout>
        <ReactBreadcrumb/>
        <section>
            <h1 className='h2 pb-2 mt-2 mb-2 border-bottom'>
                Отказано в доступе
            </h1>
            <p className='lead'>
                Вам не разрешено производить данное действие. </p>
            <p>Для получения доступа обратитесь по адресу <a href='mailto:ccm@spbstu.ru'>ccm@spbstu.ru</a></p>
        </section>
    </BaseLayout>
}

export default AccessError
