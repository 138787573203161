import Navbar from './navbar'
import * as React from 'react'
import { Container } from 'react-bootstrap'


export function BaseLayout({ children }) {
    return (
        <React.Fragment>
            <Navbar/>
            <main className={'py-2'}>
                <Container fluid>
                    {children}
                </Container>
            </main>
        </React.Fragment>
    )
}
