import React from 'react'
import { IconText } from '../icon_txt'

const Item = ({ id, visible, label, dragOverlay }) => {
  const style = {
    cursor: dragOverlay ? 'grabbing' : 'grab',
  }

  return (
    <div style={style} className='item'>
      <IconText text={label} icon={visible ? 'eye' : 'eye-slash'}/>
    </div>
  )
}

export default Item
