import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Field, FormikProvider, useFormik } from 'formik'
import { FormikSelect } from '../../../components/form/formik'
import * as React from 'react'
import { GRANT_VALUES, REPORT_YEAR } from './constant'
import { Button, Dropdown, Table } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { downloadPage } from '../../../utils/utils'
import { useEffect, useState } from 'react'
import { getRequest, updateRequest } from '../../../actions/common'
import { Link } from 'react-router-dom'
import { useAbac } from 'react-abac'
import { PERMISSIONS } from '../../../rbac/constant'
import { Input } from '../../../components/form/forms'

const GrantTotal = () => {
    const [projects, setProjects] = useState()
    const [grants, setGrants] = useState()
    const { userHasPermissions } = useAbac()

    const formik = useFormik({
        initialValues: {
            year: new Date().getFullYear(),
        },
    })

    const valuesFormik = useFormik({
        initialValues: {
            projects: {
                [-1]: { ...GRANT_VALUES },
            },
        },
        onSubmit: (values) => {
            updateRequest('grant_db', { ...values, year: formik.values.year }, 'all').then((r) => {
                if (r?.status === 200) fetchGrants()
            })
        },
    })

    useEffect(() => {
        fetchGrants()
    }, [formik.values])

    useEffect(() => {
        getRequest('project', setProjects, { type_data: 'all' }).then()
    }, [])

    useEffect(() => {
        if (projects?.length && grants?.length) {
            let toSet = { projects: {} }
            for (let project of [...projects, null]) {
                const grant = grants?.find((item) => project ? item?.project?.id === project?.id : item.project === null)
                toSet.projects[project?.id || null] = grant
                    ? {
                        plan: grant?.plan ? { ...grant?.plan } : { ...GRANT_VALUES.plan },
                        fact: grant?.fact ? { ...grant?.fact } : { ...GRANT_VALUES.fact },
                    }
                    : { ...GRANT_VALUES }
            }
            valuesFormik.setValues({ ...toSet })
        }
    }, [projects, grants])

    const fetchGrants = () => {
        getRequest('grant_db', setGrants, { type_data: 'all', year: formik.values.year }).then()
    }

    const getGrant = (project) => {

        const grantField = (type, field, isEditable) => {
            const grant = grants?.find((item) => project ? item?.project?.id === project?.id : item.project === null)

            if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && isEditable) {
                return <Input id={`projects.${project?.id || null}.${type}.${field}`}
                              name={`projects.${project?.id || null}.${type}.${field}`}
                              type={'number'}
                              disabled={userHasPermissions(PERMISSIONS.IS_MANAGER)}
                              handleChangeValue={valuesFormik.handleChange}
                              value={valuesFormik.values?.projects?.[project?.id || null]?.[type]?.[field]}
                />
            }
            return grant?.[type]?.[field] ? grant?.[type]?.[field] : 0
        }

        return <>
            <td>
                <Link to={`/grant/${project ? project?.id : null}?year=${formik.values.year}`}>
                    {project ? project?.title_short : 'Инфраструктурные расходы'}
                </Link>
            </td>
            <td>
                {grantField('plan', 'wage', true)}
            </td>
            <td>
                {grantField('plan', 'procurement', true)}
            </td>
            <td>
                {grantField('plan', 'business_trips', true)}
            </td>
            <td>
                {grantField('fact', 'wage', true)}
            </td>
            <td>
                {grantField('fact', 'procurement', true)}
            </td>
            <td>
                {grantField('fact', 'business_trips', true)}
            </td>
            <td>
                {grantField('total', 'plan')}
            </td>
            <td>
                {grantField('total', 'fact')}
            </td>
            <td>
                {grantField('total', 'remainder')}
            </td>
        </>
    }

    const getSumOfColumn = (section, column) => {
        let sum = 0
        if (grants) {
            for (let grant of grants) {
                if (grant?.[section]?.[column]) {
                    sum += Number(grant?.[section]?.[column])
                }
            }
        }
        return sum.toFixed(2)
    }

    return <>
        <ReactBreadcrumb/>
        <h1 className={'page-header text-center'}>
            Смета
            <h5 className={'text-secondary'}>
                В таблице указан план на текущий год. Указанные фактические расходы получены
                нарастающим итогом на текущий момент времени.
            </h5>
        </h1>
        <div className={'d-flex justify-content-between align-items-center'}>
            <FormikProvider value={formik}>
                <div className={'w-25'}>
                    <Field component={FormikSelect} label={'Год'}
                           id={'year'}
                           name={'year'}
                           isClearable={false}
                           handleChangeValue={formik.handleChange}
                           options={REPORT_YEAR}/>
                </div>
                <div>
                    {userHasPermissions(PERMISSIONS.DIRECTION_PANEL) ? <>
                            <Button variant={'success'} className={'mr-2'} onClick={() => valuesFormik.submitForm()}>
                                Сохранить
                            </Button>

                            <Button variant={'secondary'} className={'mr-2'} onClick={() => formik.resetForm()}>
                                <IconText icon={'ban'}
                                          text={'Отмена'}/>
                            </Button>
                        </>
                        : null}

                    <Dropdown className={'mr-1 d-inline-block'}>
                        <Dropdown.Toggle variant={'outline-secondary'}>
                            <FontAwesomeIcon icon={'external-link-alt'}/>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Header>
                                Экспорт данных с текущей страницы
                            </Dropdown.Header>
                            <Dropdown.Item onClick={() => downloadPage('xlsx', 'tblToExcl', 'grant')}>
                                <IconText text={'Excel'} icon={'file-excel'} icon_color={'green'}/>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </FormikProvider>
        </div>
        <Table id={'tblToExcl'} striped bordered hover size={'sm'} responsive='xl'>
            <thead>
            <tr>
                <th rowSpan={2}></th>
                <th colSpan={3}>План</th>
                <th colSpan={3}>Фактические расходы с обязательствами</th>
                <th rowSpan={2}>Всего План, руб.</th>
                <th rowSpan={2}>Всего Факт, руб.</th>
                <th rowSpan={2}>Всего Остаток, руб.</th>
            </tr>
            <tr>
                <th>Заработная плата с начислениями (ЗП), руб.</th>
                <th>Закупки, руб.</th>
                <th>Командировки, руб.</th>

                <th>Заработная плата с начислениями (ЗП), руб.</th>
                <th>Закупки, руб.</th>
                <th>Командировки, руб.</th>
            </tr>
            </thead>

            <tbody>
            <FormikProvider value={valuesFormik}>
                {projects ? projects?.map((item) => <tr key={`project-${item?.id}`}>{getGrant(item)}</tr>) : null}
                {getGrant(null)}
            </FormikProvider>
            <tr>
                <th>Итого</th>
                <td>{getSumOfColumn('plan', 'wage')}</td>
                <td>{getSumOfColumn('plan', 'procurement')}</td>
                <td>{getSumOfColumn('plan', 'business_trips')}</td>

                <td>{getSumOfColumn('fact', 'wage')}</td>
                <td>{getSumOfColumn('fact', 'procurement')}</td>
                <td>{getSumOfColumn('fact', 'business_trips')}</td>

                <td>{getSumOfColumn('total', 'plan')}</td>
                <td>{getSumOfColumn('total', 'fact')}</td>
                <td>{getSumOfColumn('total', 'remainder')}</td>

            </tr>
            </tbody>
        </Table>
    </>
}

export default GrantTotal
