import Select from 'react-select'
import * as React from 'react'
import AsyncSelect from 'react-select/async'
import { ThreeDots } from 'react-loader-spinner'


export const SelectReact = (props) => {
    const customStyles = {
        control: (base, state) => ({
            ...base,
            boxShadow: 'none',
            borderColor: state.isFocused
                ? '#ddd' : !props.invalid
                    ? '#ddd' : '#dc3545',
            backgroundColor: props.disabled ? '#e9ecef' : 'hsl(0, 0%, 100%)',
            '&:hover': {
                borderColor: state.isFocused
                    ? '#ddd' : !props.invalid
                        ? '#ddd' : '#dc3545',
            },
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: 'none',
        }),
    }

    const selectAllOption = {
        value: 'All',
        label: 'Выбрать все',
    }

    const getValue = (options) => {
        if (options) {
            if (props.value) {
                return props.multi
                    ? options.filter((option) => {
                        return props.value.indexOf(option.value) >= 0
                    })
                    : options.find((option) => {
                        return option.value === props.value
                    })
            } else {
                return props.multi ? [] : ''
            }
        }
    }

    const onChangeSelect = (newValue) => {
        if (
            newValue !== null
            && newValue.length > 0
            && newValue[newValue.length - 1].value === selectAllOption.value
        ) {
            let temp = []
            for (let index in props.options) {
                if (!props.options[index]?.isDisabled) {
                    temp.push(props.options[index])
                }
            }
            return onChangeSelect(temp)
        }
        if (!props.setSelectState && props.setState) {
            props.setState(props.multi ? newValue.map((value) => value?.value) : newValue?.value)
        } else if (props.setSelectState && props.select_key) {
            if (props.multi) {
                let new_value = { [props.select_key]: newValue.map((value) => value?.value) }
                props.setState({ ...props.select_values, ...new_value })
            } else {
                props.setState({ ...props.select_values, [props.select_key]: newValue?.value })
            }
        }
    }

    return <Select id={props.selectID}
                   instanceId={props.selectID}
                   isLoading={props?.isLoading}
                   styles={{ ...customStyles, ...props.style }}
                   className={props.classTitle}
                   classNamePrefix='react-select'
                   onChange={onChangeSelect}
                   menuPortalTarget={props.menuPortalTarget}
                   value={getValue(props.options)}
                   options={props.selectAllOption && props.options 
                        ? [selectAllOption, ...props.options] 
                        : props.options}
                   isMulti={props.multi}
                   placeholder={props.placeholder}
                   noOptionsMessage={() => 'Пусто'}
                   closeMenuOnSelect={props.closeMenuOnSelect === null 
                        ? true 
                        : props.closeMenuOnSelect}
                   isSearchable={props.isSearchable ? props.isSearchable : false}
                   controlShouldRenderValue={true}
                   hideSelectedOptions={false}
                   isDisabled={props.disabled}
                   isClearable={props.isClearable}
                   aria-invalid={props.invalid}
                   loadOptions={props.loadOptions}
                   onMenuScrollToBottom={props.onMenuScrollToBottom}
                   onMenuScrollToTop={props.onMenuScrollToTop}
    />
}
