import * as React from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import ReactFilterTable from '../../../components/tables/react_table_filters'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PERMISSIONS, ROLES } from '../../../rbac/constant'
import { Badge, Button } from 'react-bootstrap'
import { IconText } from '../../../components/icon_txt'
import { AuthContext } from '../../../auth'
import { FIELDS, LABELS, SCIENCE_DIRECTIONS, STATUS_BADGE_CLASS, STATUS_LABELS } from './constant'
import { getLabelFromOptions, Messages } from '../../../utils/utils'
import { putRequest } from '../../../actions/common'
import { STATUS_ACCEPT, STATUS_NEW } from './constant'
import { useAbac } from 'react-abac'


const Niokr = () => {
    const navigate = useNavigate()
    const user = useContext(AuthContext)
    const { userHasPermissions } = useAbac()

    const [niokr, setNiokr] = useState(null)
    const [filtering, setFiltering] = useState(null)
    const [itemGroups, setItemGroups] = useState({
        'Отображаемые колонки': ['science_dir', 'title', 'supervisor', 'partner', 'project'],
        'Скрытые / Фиксированные колонки': [
            'updated_by',
            'created_at',
            'updated_at',
            'created_by',
            'status',
            'presentation_link',
            'grnti_program',
            'id',
        ],
    })

    useEffect(() => {
        if (userHasPermissions(PERMISSIONS.DIRECTION_PANEL) && !userHasPermissions(PERMISSIONS.ADMIN_PANEL)) {
            setFiltering({ status_not: STATUS_NEW })
        }
    }, [user])

    const checkField = (field, key) => {
        if (key === 'status') {
            return <span className={`badge ${STATUS_BADGE_CLASS[field]}`}>{STATUS_LABELS[field]}</span>
        }
        if (key === 'messages') {
            return <Messages messages_={field} status_labels={STATUS_LABELS} source={'niokr'}/>
        }
        if (key === 'science_dir') {
            return getLabelFromOptions(SCIENCE_DIRECTIONS, field)
        }
        if (key === 'title') {
            if (field) {
                return <center>{field}</center>
            } else {
                return <center><span className={'not-set'}>(не задано)</span></center>
            }
        }
        if (key === 'project' && field) {
            return <span>{field.title_short}</span>
        }

        return null
    }

    const addNewNiokr = (state) => {
        putRequest('niokr', {
            status: state?.monitoring ? STATUS_ACCEPT : STATUS_NEW, project: user?.project?.id,
        }).then((response) => {
            if (response?.status === 201 && response?.data?.id) {
                navigate(`/niokr/item/${response.data.id}/?update=true`)
            }
        })
    }

    return (<React.Fragment>
        <ReactBreadcrumb/>
        <h1 className={'page-header'}>
            НИОКР
        </h1>
        {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.project_manager)
        || user?.roles?.includes(ROLES.direction)
            ? <React.Fragment>
                <div className={'button-actions'}>
                    {!user?.roles?.includes(ROLES.direction)
                        ? <Button variant={'success'} className={'mb-3'} onClick={() => {
                            addNewNiokr()
                        }}>
                            <IconText icon={'plus'} text={'Создать'}/>
                        </Button> : null}
                    {user?.roles?.includes(ROLES.admin) || user?.roles?.includes(ROLES.direction)
                        ? <Button variant={'secondary'} className={'mb-3'} onClick={() => {
                            addNewNiokr({ monitoring: true })
                        }}>
                            <IconText icon={'plus'} text={'Добавить в мониторинг'}/>
                        </Button> : null}
                </div>
            </React.Fragment> : null}
        <ReactFilterTable labels={LABELS} groups={itemGroups} setGroups={setItemGroups}
                          filtering={filtering}
                          data={niokr} setFunc={setNiokr} page={1} url={'/niokr'}
                          url_update={'/niokr/item'}
                          get_title={'niokr'}
                          checkField={checkField}
                          permission={PERMISSIONS.MANAGE_NIOKR}
                          fields={FIELDS}
        />
    </React.Fragment>)
}

export default Niokr
