import * as React from 'react'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Button, Card, Form } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { getRequest, putRequest } from '../../../actions/common'
import { SelectReact } from '../../../components/form/select'
import { Input, Select } from '../../../components/form/forms'
import { useNavigate } from 'react-router-dom'

const IndicatorCreate = () => {
    const [units, setUnits] = useState(null)
    const [unit, setUnit] = useState(null)
    const navigate = useNavigate()

    React.useEffect(() => {
        document.title = 'Создать | ИС «ПИШ»'
    })

    useEffect(() => {
        if (!units) {
            getRequest('unit', setUnits)
        }
    }, [units])

    const onSubmitForm = (e) => {
        e.preventDefault()
        let data = {}
        const inputs = Array.from(e.target)
        inputs.forEach(({ id, value }) => {
            if (value) {
                data[id] = value
            }
        })
        data['unit_id'] = unit
        putRequest('indicator', data)
        navigate('/indicator/')
    }

    const Units = () => {
        let options = []

        if (units !== null && units !== undefined) {
            for (let i = 0; i < units.results.length; i++) {
                if (units.results[i]) {
                    options.push({ value: units.results[i].id, label: units.results[i].title })
                }
            }
        }

        return <SelectReact options={options} setState={setUnit} value={unit} selectID='unit_id' required/>
    }

    const refreshFilter = () => {
        document.getElementById('id').value = ''
        document.getElementById('title').value = ''
        document.getElementById('description').value = ''
        setUnit(null)
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                Создать
            </h1>
            <form onSubmit={onSubmitForm}>
                <Card>
                    <Card.Body>
                        <Input label={'Заголовок'} required={true} id={'title'}/>
                        <Input label={'Сокращенное наименование'} id={'title_short'}/>
                        <Input label={'Описание'} id={'description'}/>
                        <div className='form-group'>
                            <Form.Label>Еденица измерения</Form.Label>
                            <Units/>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className={'button-actions'}>
                            <Button variant='success' type='submit'>Сохранить</Button>
                            <Button variant='secondary' onClick={refreshFilter}>Сбросить</Button>
                        </div>
                    </Card.Footer>
                </Card>
            </form>
        </React.Fragment>
    )
}

export default IndicatorCreate
