import * as Yup from 'yup'


export const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Обязательное поле!').nullable().typeError('Неверный email!'),
    password: Yup.string().required('Обязательное поле!'),
})

export const ForgetSchema = Yup.object().shape({
    email: Yup.string().required('Обязательное поле!').nullable().email('Неверный email!'),
})

export const ResetSchema = Yup.object().shape({
    password: Yup.string()
        .required('Обязательное поле!')
        .test('password_repeat_compare', 'Пароли должны совпадать',
            (value, context) => {
                return value === context.parent.password_repeat && value.length > 7
            },
    ),
    password_repeat: Yup.string().required('Обязательное поле!'),
})
