import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import {
    Button,
    Card,
} from 'react-bootstrap'
import ReactBreadcrumb from '../../../components/breadcrumbs'
import { Input, Select } from '../../../components/form/forms'
import { deleteRequest, getRequest, updateRequest } from '../../../actions/common'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setChangedValue } from '../../../utils/utils'
import { ROLES } from '../../../rbac/constant'
import { AuthContext } from '../../../auth'

const ReportFinUpdate = () => {
    const navigate = useNavigate()
    const params = useParams()
    const user = useContext(AuthContext)

    const [report_fin, setReportFin] = useState(null)
    const [input_values, setInputValues] = useState({ common: {} })
    const [select_values, setSelectValues] = useState({})
    const [errors, setErrors] = useState({ title: null, type: null })

    const REQUIRED_FIELDS = ['title', 'type']

    useEffect(() => {
        if (user?.roles?.includes(ROLES.management)) {
            navigate('/access-error')
        }
        if (!report_fin) {
            getRequest('report_fin', setReportFin, {}, params.id, null, null, true)
        } else {
            setSelectValues({ type: Number(report_fin.type) })
        }
    }, [report_fin])

    const handleChangeValue = (e) => {
        e.preventDefault()
        let target = e.target.getAttribute('data_change_key')
        setChangedValue(setInputValues, input_values, target, e.target.id, e.target.value)
    }

    const checkErrors = () => {
        let new_errors = {}
        let has_errors = false

        REQUIRED_FIELDS.forEach((field) => {
            if (!document.getElementById(field)?.value && !select_values[field]) {
                new_errors[field] = 'Обязательное поле!'
                has_errors = true
            } else {
                new_errors[field] = null
            }
        })

        setErrors({ ...errors, ...new_errors })
        return has_errors
    }

    const saveReportFin = () => {
        updateRequest('report_fin', input_values, params.id).then((response) => {
            if (response.status === 200) {
                navigate('/indicator/report_fin')
            }
        })
    }

    const handleUpdate = (e) => {
        e.preventDefault()
        if (checkErrors()) {
            toast.error('Ошибка в заполении данных!')
            return null
        }
        saveReportFin()
    }

    const handleDelete = () => {
        deleteRequest('report_fin', params.id).then((response) => {
            if (response.status === 200) {
                navigate('/indicator/report_fin')
            }
        })
    }

    return (
        <React.Fragment>
            <ReactBreadcrumb/>
            <h1 className={'page-header'}>
                Редактировать отчет
            </h1>

            <Card>
                <Card.Body>
                    <Input required={true}
                           id={'title'}
                           label={'Заголовок'}
                           value={report_fin ? report_fin.title : ''}
                           handleChangeValue={handleChangeValue}
                           error={errors.title}
                           invalid={errors.title}
                    />
                    <Select id={'type'}
                            label={'Тип'}
                            required={true}
                            error={errors.type}
                            invalid={errors.type}
                            options={[
                                { value: 1, label: 'Ручной' },
                                { value: 2, label: 'Квартальный' },
                                { value: 3, label: 'Ежегодный' },
                            ]}
                            select_values={select_values}
                            setSelectValues={setSelectValues}
                    />
                </Card.Body>
                <Card.Footer>
                    <div className={'button-actions'}>
                        <Button variant={'success'} onClick={handleUpdate}>Сохранить</Button>
                        <Button variant={'secondary'} onClick={() => {
                            setInputValues({})
                            window.location.reload()
                        }}>Сбросить</Button>
                        <Button variant={'danger'} onClick={handleDelete}>Удалить</Button>
                    </div>
                </Card.Footer>
            </Card>
        </React.Fragment>
    )
}

export default ReportFinUpdate
