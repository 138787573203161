import { Button, Modal } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Field, FormikProvider, useFormik } from 'formik'
import { Check, Input } from '../../form/forms'
import { FormikDate } from '../../form/formik'
import { PERMISSIONS } from '../../../rbac/constant'
import { IconText } from '../../icon_txt'
import ReactTable from '../../tables/react_table'
import * as React from 'react'
import {
    CAN_MANAGE_ROLES,
    MoneySchema,
    STATUS_CHECK_ACTS,
} from '../../../screens/database/financing/constant'
import { deleteRequest, getRequest, putRequest, updateRequest } from '../../../actions/common'
import { useEffect, useState } from 'react'
import { useAbac } from 'react-abac'

export const MoneyOrders = ({ disabled, fin_id, moneyOrdersData, setMoneyOrder, isAct = false, user }) => {
    const { userHasPermissions } = useAbac()
    const [showModal, setShowModal] = useState(false)
    const [data, setData] = useState(moneyOrdersData)
    const title = {
        nominativeCase: isAct ? 'Акт' : 'Платежное поручение',
        genitiveCase: isAct ? 'акта' : 'платежного поручения',
        plural: isAct ? 'акты' : 'платежные поручения',
    }

    const moneyOrdersLabels = {
        serial_number: '№',
        number: `Номер ${title.genitiveCase}`,
        date: `Дата ${title.genitiveCase}`,
        summary: `Сумма ${title.genitiveCase}`,
        created_at: 'Дата добавления',
    }

    if (isAct) {
        moneyOrdersLabels.is_checked = 'Проверено'
    } else {
        moneyOrdersLabels.include_month = 'Месяц и год включения суммы платежного поручения в отчет'
    }
    moneyOrdersLabels.delete = 'Удалить'


    const formMoney = useFormik({
        validationSchema: MoneySchema,
        initialValues: {
            is_act: isAct,
            is_checked: false,
            object_number: 0,
            number: '',
            data: undefined,
            summary: null,
        },
        onSubmit: (values) => {
            return saveModal(values, 'financing_orders', data, setMoneyOrder, formMoney, setShowModal)
        },
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
    })

    useEffect(() => {
        if (moneyOrdersData?.results) {
            let set = [...moneyOrdersData?.results].filter((item) => item?.is_act === isAct)
            setData({
                count: set.length,
                results: set,
            })
        }
    }, [moneyOrdersData])

    const saveModal = (values, url, len, setFunc, form, setModal) => {
        putRequest(url, {
            ...values,
            financing: fin_id,
            is_act: isAct,
            object_number: len?.results?.length + 1,
        }).then((r) => {
            getRequest(url, setFunc, { financing: fin_id }).then((r) => {
                cancelModal(form, setModal)
            })
        })
    }

    const cancelModal = (data, setFunc) => {
        data.setValues(data.initialValues)
        setFunc(false)
    }

    const handleDelete = (item) => {
        deleteRequest('financing_orders', item?.id).then((r) => {
            getRequest('financing_orders', setMoneyOrder, { financing: fin_id }).then()
        })
    }

    const handleCheck = (item, checked) => {
        updateRequest('financing_orders', {
            common: {
                is_checked: checked,
            },
        }, item?.id).then()
    }

    const additionalCheckField = (field, key, element) => {
        if (key === 'delete') {
            return element?.is_checked && userHasPermissions(PERMISSIONS.IS_MANAGER)
                ? ' '
                : (<Button variant={'danger'} onClick={() => handleDelete(element)}>
                    <FontAwesomeIcon icon={'trash-alt'}/>
                </Button>)
        } else if (key === 'is_checked') {
            if (user.roles.some((role) => CAN_MANAGE_ROLES[STATUS_CHECK_ACTS].includes(role))) {
                return (<Check value={field}
                               handleChangeValue={(e) => handleCheck(element, e?.target?.checked)}/>)
            } else {
                return field
            }
        }
        return field
    }


    return (
        <>
            <Modal size={'lg'} show={showModal} centered={true}
                   onHide={() => setShowModal(false)}>
                <Modal.Header>
                    <Modal.Title>
                        Добавление {title.genitiveCase}
                    </Modal.Title>
                    <button className={'close'} onClick={() => {
                        setShowModal(false)
                    }}><FontAwesomeIcon icon={'times'}/></button>
                </Modal.Header>
                <Modal.Body>
                    <FormikProvider value={formMoney}>
                        <Input label={`Номер ${title.genitiveCase}`}
                               handleChangeValue={formMoney.handleChange}
                               value={formMoney.values.number}
                               error={formMoney.errors['number']}
                               invalid={formMoney.errors['number']}
                               id={'number'}
                               name={'number'}
                               required={true}
                        />
                        <Field component={FormikDate} name={'date'}
                               handleChangeValue={formMoney.handleChange}
                               label={'Дата договора'}
                               id={'date'}
                               error={formMoney.errors['date']}
                               invalid={formMoney.errors['date']}
                               value={formMoney.values.date}
                               required={true}
                        />
                        {!isAct
                            ?  (<Field component={FormikDate} name={'include_month'}
                                   handleChangeValue={formMoney.handleChange}
                                   label={'Месяц и год включения в отчет'}
                                   id={'include_month'}
                                   error={formMoney.errors['include_month']}
                                   invalid={formMoney.errors['include_month']}
                                   value={formMoney.values.include_month}
                                   required={true}
                            />)
                            : null}
                        <Input type={'number'}
                               label={`Сумма ${title.genitiveCase}`}
                               handleChangeValue={formMoney.handleChange}
                               value={formMoney.values.summary}
                               error={formMoney.errors['summary']}
                               invalid={formMoney.errors['summary']}
                               id={'summary'}
                               name={'summary'}
                               required={true}
                        />
                    </FormikProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'success'} onClick={formMoney.submitForm}>
                        Добавить
                    </Button>
                    <Button variant={'danger'}
                            onClick={() => cancelModal(formMoney, setShowModal)}>
                        Отменить
                    </Button>
                </Modal.Footer>
            </Modal>
            {disabled ? null
                : <Button className={'mb-3'} onClick={() => setShowModal(true)}>
                    <IconText text={`Добавить ${title.nominativeCase}`} icon={'plus'}/>
                </Button>
            }
            <legend>{title.plural}</legend>
            <ReactTable
                bordered={true}
                hover={true}
                striped={true}
                additionalCheckField={additionalCheckField}
                show_result={false}
                data={data}
                labels={moneyOrdersLabels}/>
            <hr/>
        </>
    )
}

