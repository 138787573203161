import { ROLES } from '../../../rbac/constant'
import { Input, InputDate, Select } from '../../../components/form/forms'
import * as Yup from 'yup'
import { setRequiredField } from '../../../utils/utils'

export const STATUS_DRAFT = 0                 // Черновик
export const STATUS_TEMP = 4                 // Принято Д
export const STATUS_NEW = 1                    // Новый МП
export const STATUS_APPROVE = 2                // Рассматривается ДО
export const STATUS_ACCEPT = 3                 // Принято Д

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]

export const STATUS_LABELS = {
    [STATUS_DRAFT]: 'Отклонено',
    [STATUS_NEW]: 'Новый',
    [STATUS_APPROVE]: 'Рассматривается',
    [STATUS_ACCEPT]: 'Принято',
}

export const STATUS_OPTIONS = [
    { value: STATUS_NEW, label: 'Новый' },
    { value: STATUS_APPROVE, label: 'Рассматривается' },
    { value: STATUS_ACCEPT, label: 'Принято' },
]

export const STATUS_BADGE_CLASS = {
    [STATUS_DRAFT]: 'badge-danger',
    [STATUS_NEW]: 'badge-warning',
    [STATUS_APPROVE]: 'badge-info',
    [STATUS_ACCEPT]: 'badge-success',
}

export const EVENT_TYPE = [
    { value: 1, label: 'Инженерная/проектная подготовка' },
    { value: 2, label: 'Образовательная деятельность' },
    { value: 3, label: 'Профильные олимпиады' },
    { value: 4, label: 'Профориентационные мероприятия для школьников' },
    { value: 5, label: 'Довузовская подготовка' },
]

export const REPORT_YEAR = [
    { value: 2022, label: 2022 },
    { value: 2023, label: 2023 },
    { value: 2024, label: 2024 },
    { value: 2025, label: 2025 },
    { value: 2026, label: 2026 },
    { value: 2027, label: 2027 },
    { value: 2028, label: 2028 },
    { value: 2029, label: 2029 },
    { value: 2030, label: 2030 },
]

export const LABELS = {
    'id': 'ID',
    'status': 'Статус',

    'event_name': 'Наименование мероприятия',
    'event_type': 'Тип мероприятия',
    'event_info': 'Содержание мероприятия',

    'deadline': 'Срок проведения',
    'organization': 'Организация/школа',
    'region': 'Регион',
    'count_pupils': 'Количество школьников',
    'month': 'Месяц зачета',
    'year': 'Год зачета',
    'project': 'Проект',
    'docs_link': 'Ссылка на документы',
    'docs_title': 'Названия документов',

    'message': 'Комментарий',
    'messages': 'Сообщения',
    'updated_by': 'Обновлено пользователем',
    'created_at': 'Дата создания',
    'updated_at': 'Дата обновления',
    'created_by': 'Создано пользователем',
    'direction_by': 'Дирекция',
}

export const FIELDS = {
    id: {
        type: Input,
        content: 'number',
    },
    status: {
        type: Select,
        options: STATUS_OPTIONS,
    },
    event_name: {
        type: Input,
        content: 'text',
    },
    event_type: {
        type: Select,
        options: EVENT_TYPE,
    },
    event_info: {
        type: Input,
        content: 'text',
    },
    organization: {
        type: Input,
        content: 'text',
    },
    region: {
        type: Input,
        content: 'text',
    },
    month: {
        type: Input,
        content: 'number',
    },
    year: {
        type: Input,
        content: 'number',
    },
    docs_link: {
        type: Input,
        content: 'text',
    },
    docs_title: {
        type: Input,
        content: 'text',
    },
    count_pupils: {
        type: Input,
        content: 'number',
    },

    project: {
        type: Select,
        source: 'project',
        key: (v) => v?.title_short,
    },
    deadline: {
        type: Input,
        content: 'text',
    },

    updated_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    created_at: {
        type: InputDate,
        content: 'date',
    },
    updated_at: {
        type: InputDate,
        content: 'date',
    },
    created_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
    direction_by: {
        type: Select,
        source: 'users',
        key: (v) => v?.fio,
    },
}

export const REQUIRED_FIELDS = {
    [STATUS_DRAFT]: [],
    [STATUS_NEW]: ['event_type', 'event_name', 'event_info', 'project_id', 'deadline', 'count_pupils', 'docs_title'],
    [STATUS_APPROVE]: ['event_type', 'event_name', 'event_info',
        'project_id', 'deadline', 'count_pupils', 'month', 'year', 'docs_title'],
    [STATUS_ACCEPT]: ['event_type', 'event_name', 'event_info',
        'project_id', 'deadline', 'count_pupils', 'month', 'year', 'docs_title'],
    [STATUS_TEMP]: ['event_type', 'event_name', 'event_info',
        'project_id', 'deadline', 'count_pupils', 'month', 'year', 'docs_title'],
}

const getSchema = () => {
    return {
        status: Yup.number(),
        message: Yup.string().when(['status', 'current_status'], (status, schema) => {
            return status[0] < status[1] ? schema.required('Обязательное поле!') : schema
        }),
        event_type: Yup.number().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
        event_name: Yup.string().nullable().required('Обязательное поле!'),
        event_info: Yup.string().nullable().required('Обязательное поле!'),
        project_id: Yup.number().required('Обязательное поле!').typeError('Обязательное поле!'),
        deadline: Yup.string()
            .typeError('Обязательное поле!')
            .required('Обязательное поле!')
            .default(undefined),
        region: Yup.string().nullable(),
        docs_title: Yup.string().nullable().required('Обязательное поле!'),
        count_pupils: Yup.number().nullable().required('Обязательное поле!').typeError('Обязательное поле!'),
        month: setRequiredField(Yup.number().nullable().typeError('Обязательное поле!'), STATUS_ACCEPT),
        year: setRequiredField(Yup.number().nullable().typeError('Обязательное поле!'), STATUS_ACCEPT),

    }
}

export const REQUIRED_FIELDS_TEMP = {
    [STATUS_DRAFT]: Yup.object().shape(getSchema()),
    [STATUS_NEW]: Yup.object().shape(getSchema()),
    [STATUS_APPROVE]: Yup.object().shape(getSchema()),
    [STATUS_ACCEPT]: Yup.object().shape(getSchema()),
}

export const CAN_MANAGE_ROLES = {
    [STATUS_DRAFT]: [ROLES.admin],
    [STATUS_NEW]: [ROLES.admin, ROLES.project_manager],
    [STATUS_APPROVE]: [ROLES.admin, ROLES.direction],
    [STATUS_ACCEPT]: [ROLES.admin, ROLES.direction],
}
