import {
    ChonkyActions,
    FileHelper,
    FullFileBrowser,
    setChonkyDefaults,
} from 'chonky'
import { ChonkyIconFA } from 'chonky-icon-fontawesome'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { deleteFile, getDirectory, getFile, uploadFile } from '../../actions/file_manager'
import { russianI18n } from './locale'

setChonkyDefaults({ iconComponent: ChonkyIconFA })

export const MyFileBrowser = ({
                                  path,
                                  instanceId,
                                  height = 700,
                                  read_only = false,
                                  can_delete = true,
                                  setIsEmpty = null,
                              }) => {
    const [fileMap, setFileMap] = useState(null)
    const [currentFolderId, setCurrentFolderId] = useState(instanceId)

    useEffect(() => {
        if (!fileMap) {
            getDirectory(setFileMap, path).then((r) => {
            })
        }
        if (setIsEmpty) {
            setIsEmpty(fileMap?.[instanceId]?.childrenCount === 0)
        }
    }, [fileMap])

    const useFiles = (currentFolderId) => {
        return useMemo(() => {
            if (fileMap && Object.keys(fileMap).includes(currentFolderId)) {
                const currentFolder = fileMap[currentFolderId]
                return currentFolder?.childrenIds
                    ? currentFolder.childrenIds.map((fileId) => fileMap[fileId] ?? null)
                    : []
            }
            return []
        }, [fileMap, currentFolderId])
    }

    const useFolderChain = (currentFolderId) => {
        return useMemo(() => {
            if (fileMap && Object.keys(fileMap).includes(currentFolderId)) {
                const currentFolder = fileMap[currentFolderId]
                const folderChain = [currentFolder]

                let parentId = currentFolder?.parentId
                while (parentId) {
                    const parentFile = fileMap[parentId]
                    if (parentFile) {
                        folderChain.unshift(parentFile)
                        parentId = parentFile.parentId
                    } else {
                        parentId = null
                    }
                }

                return folderChain
            }
            return ''
        }, [fileMap, currentFolderId])
    }

    const useFileActionHandler = (setCurrentFolderId) => {
        return useCallback(
            (data) => {
                if (data.id === ChonkyActions.OpenFiles.id) {
                    const { targetFile, files } = data.payload
                    const fileToOpen = targetFile ?? files[0]
                    if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                        setCurrentFolderId(fileToOpen.id)
                        return
                    }
                }
                if (data.id === ChonkyActions.DownloadFiles.id) {
                    if (path == 'root' && data?.state?.contextMenuTriggerFile?.parentId != null) {
                        path = path + '/' + data?.state?.contextMenuTriggerFile?.parentId 
                    }
                    for (let file of data.state.selectedFiles) {
                        getFile(path, file.name)
                    }
                }
                if (data.id === ChonkyActions.UploadFiles.id) {
                    const handleUpload = (event) => {
                        event.preventDefault()
                        let formData = new FormData()
                        formData.append('file', event.target.files[0])
                        formData.append('path', path)
                        uploadFile('/api/file/', formData, setFileMap).then((r) => {
                        })
                    }
                    const form = document.createElement('form')
                    form.enctype = 'multipart/form-data'

                    const input = document.createElement('input')
                    input.type = 'file'
                    form.onchange = handleUpload
                    form.onsubmit = (e) => {
                        e.preventDefault()
                    }

                    form.appendChild(input)
                    input.click()
                    getDirectory(setFileMap, path).then((r) => {
                    })
                }
                if (data.id === ChonkyActions.DeleteFiles.id) {
                    for (let file of data.state.selectedFiles) {
                        deleteFile(path, file.name, setFileMap)
                    }
                }
            },
            [fileMap, setCurrentFolderId],
        )
    }

    const files = useFiles(currentFolderId)
    const folderChain = useFolderChain(currentFolderId)
    const handleFileAction = useFileActionHandler(setCurrentFolderId)
    const fileActions = can_delete ? [
        ChonkyActions.UploadFiles,
        ChonkyActions.DownloadFiles,
        ChonkyActions.DeleteFiles,
    ] : [
        ChonkyActions.UploadFiles,
        ChonkyActions.DownloadFiles,
    ]

    return (
        <div style={{ height: height }}>
            <FullFileBrowser
                instanceId={instanceId}
                files={files}
                folderChain={folderChain}
                onFileAction={handleFileAction}
                fileActions={read_only ? null : fileActions}
                i18n={russianI18n}
            />
        </div>
    )

}
