// an object with all permissions

const checkOwnProject = (element, user) => {
    if (user && element && element?.project?.id && user?.project?.id) {
        return element?.project?.id === user?.project?.id
    }
    return false
}

export const PERMISSIONS = {
    MANAGER_PANEL: 'perm.managerPanel',
    IS_MANAGER: 'perm.isManager',
    DIRECTION_PANEL: 'perm.directionPanel',
    RID_DIRECTION_PANEL: 'perm.ridDirectionPanel',
    EDU_DIRECTION_PANEL: 'perm.eduDirectionPanel',
    OOP_DIRECTION_PANEL: 'perm.oopDirectionPanel',
    DPO_DIRECTION_PANEL: 'perm.dpoDirectionPanel',
    STAFF_DIRECTION_PANEL: 'perm.staffDirectionPanel',
    FINANCING_DIRECTION_PANEL: 'perm.financingDirectionPanel',
    ADMIN_PANEL: 'perm.adminPanel',
    MANAGE_COMMON: 'perm.manageCommon',

    // Staff DB
    CONTROL_STAFF: 'perm.controlStaff',
    MANAGE_STAFF: 'perm.manageStaff',
    MANAGE_OWN_STAFF: 'perm.manageOwnStaff',

    // Specialist DB
    CONTROL_SPECIALIST: 'perm.controlSpecialist',
    MANAGE_SPECIALIST: 'perm.manageSpecialist',
    MANAGE_OWN_SPECIALIST: 'perm.manageOwnSpecialist',

    // Rid DB
    CONTROL_RID: 'perm.controlRid',
    MANAGE_RID: 'perm.manageRid',
    MANAGE_OWN_RID: 'perm.manageOwnRid',

    // Pub DB
    CONTROL_PUB: 'perm.controlPub',
    MANAGE_PUB: 'perm.managePub',
    MANAGE_OWN_PUB: 'perm.manageOwnPub',

    // Financing DB
    CONTROL_FINANCING: 'perm.controlFin',
    MANAGE_FINANCING: 'perm.manageFin',
    MANAGE_OWN_FINANCING: 'perm.manageOwnFin',

    // Niokr DB
    CONTROL_NIOKR: 'perm.controlNiokr',
    MANAGE_NIOKR: 'perm.manageNiokr',
    MANAGE_OWN_NIOKR: 'perm.manageOwnNiokr',

    // Educational DB
    CONTROL_OOP: 'perm.controlEducational',
    MANAGE_OOP: 'perm.manageEducational',
    MANAGE_OWN_OOP: 'perm.manageOwnEducational',

    // Internship DB
    CONTROL_INTERN: 'perm.controlINTERN',
    MANAGE_INTERN: 'perm.manageINTERN',
    MANAGE_OWN_INTERN: 'perm.manageOwnINTERN',

    // DPO DB
    CONTROL_DPO: 'perm.controlDpo',
    MANAGE_DPO: 'perm.manageDpo',
    MANAGE_OWN_DPO: 'perm.manageOwnDpo',

    // School DB
    CONTROL_SCHOOL: 'perm.controlSchool',
    MANAGE_SCHOOL: 'perm.manageSchool',
    MANAGE_OWN_SCHOOL: 'perm.manageOwnSchool',

}

export const ROLES = {
    admin: 'admin',
    direction: 'direction',
    direction_rid: 'direction_rid',
    direction_edu: 'direction_edu',
    direction_staff: 'direction_staff',
    direction_oop: 'direction_oop',
    direction_dpo: 'direction_dpo',
    project_manager: 'project_manager',
    direction_financing: 'direction_financing',
    management: 'management',
}

export const ROLES_LABELS = {
    admin: 'Администратор',
    direction: 'Дирекция',
    direction_rid: 'Дирекция РИД',
    direction_edu: 'Дирекция образование',
    direction_oop: 'Дирекция ООП',
    direction_dpo: 'Дирекция ДПО',
    direction_staff: 'Дирекция кадры',
    project_manager: 'Менеджер проекта',
    direction_financing: 'Дирекция софин',
    management: 'Руководители',
}

export const RULES = {
    [ROLES.project_manager]: {
        [PERMISSIONS.MANAGER_PANEL]: true,
        [PERMISSIONS.IS_MANAGER]: true,
        [PERMISSIONS.MANAGE_OWN_STAFF]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_OWN_SPECIALIST]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_OWN_RID]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_OWN_PUB]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_OWN_NIOKR]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_OWN_FINANCING]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_OWN_OOP]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_OWN_DPO]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_OWN_SCHOOL]: (element, user) => checkOwnProject(element, user),
    },
    [ROLES.direction_edu]: {
        [PERMISSIONS.EDU_DIRECTION_PANEL]: true,
        [PERMISSIONS.MANAGER_PANEL]: true,
        [PERMISSIONS.MANAGE_OWN_SPECIALIST]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_SPECIALIST]: true,
    },
    [ROLES.direction_oop]: {
        [PERMISSIONS.OOP_DIRECTION_PANEL]: true,
        [PERMISSIONS.MANAGER_PANEL]: true,
        [PERMISSIONS.MANAGE_OWN_OOP]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_OOP]: true,
        [PERMISSIONS.CONTROL_OOP]: true,

        [PERMISSIONS.CONTROL_INTERN]: true,
        [PERMISSIONS.MANAGE_INTERN]: true,
        [PERMISSIONS.MANAGE_OWN_INTERN]: (element, user) => checkOwnProject(element, user),
    },
    [ROLES.direction_dpo]: {
        [PERMISSIONS.DPO_DIRECTION_PANEL]: true,
        [PERMISSIONS.MANAGER_PANEL]: true,
        [PERMISSIONS.MANAGE_OWN_DPO]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_DPO]: true,
        [PERMISSIONS.CONTROL_DPO]: true,
    },
    [ROLES.direction_rid]: {
        [PERMISSIONS.RID_DIRECTION_PANEL]: true,
        [PERMISSIONS.MANAGER_PANEL]: true,
        [PERMISSIONS.MANAGE_OWN_RID]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_RID]: true,
    },
    [ROLES.direction_staff]: {
        [PERMISSIONS.STAFF_DIRECTION_PANEL]: true,
        [PERMISSIONS.MANAGER_PANEL]: true,
        [PERMISSIONS.MANAGE_OWN_STAFF]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_STAFF]: true,
        [PERMISSIONS.CONTROL_STAFF]: true,
    },
    [ROLES.direction]: {
        [PERMISSIONS.MANAGER_PANEL]: true,
        [PERMISSIONS.MANAGE_COMMON]: true,
        [PERMISSIONS.DIRECTION_PANEL]: true,

        [PERMISSIONS.MANAGE_OWN_SPECIALIST]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_SPECIALIST]: true,
        [PERMISSIONS.CONTROL_SPECIALIST]: true,

        [PERMISSIONS.CONTROL_OOP]: true,
        [PERMISSIONS.CONTROL_DPO]: true,

        [PERMISSIONS.CONTROL_INTERN]: true,
        [PERMISSIONS.MANAGE_INTERN]: true,

        [PERMISSIONS.MANAGE_OWN_RID]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_RID]: true,
        [PERMISSIONS.CONTROL_RID]: true,


        [PERMISSIONS.CONTROL_SCHOOL]: true,
        [PERMISSIONS.MANAGE_SCHOOL]: true,
        [PERMISSIONS.MANAGE_OWN_SCHOOL]: (element, user) => checkOwnProject(element, user),

        [PERMISSIONS.MANAGE_OWN_STAFF]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_STAFF]: true,
        [PERMISSIONS.CONTROL_STAFF]: true,

        [PERMISSIONS.MANAGE_OWN_PUB]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_PUB]: true,
        [PERMISSIONS.CONTROL_PUB]: true,

        [PERMISSIONS.MANAGE_OWN_FINANCING]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_FINANCING]: true,
        [PERMISSIONS.CONTROL_FINANCING]: true,

        [PERMISSIONS.MANAGE_OWN_NIOKR]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_NIOKR]: true,
        [PERMISSIONS.CONTROL_NIOKR]: true,

    },
    [ROLES.direction_financing]: {
        [PERMISSIONS.FINANCING_DIRECTION_PANEL]: true,
        [PERMISSIONS.MANAGER_PANEL]: true,
        [PERMISSIONS.MANAGE_OWN_FINANCING]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_FINANCING]: true,
        [PERMISSIONS.CONTROL_FINANCING]: true,
    },
    [ROLES.management]: {
        [PERMISSIONS.MANAGER_PANEL]: true,
        [PERMISSIONS.DIRECTION_PANEL]: true,
    },
    [ROLES.admin]: {
        [PERMISSIONS.MANAGER_PANEL]: true,
        [PERMISSIONS.ADMIN_PANEL]: true,
        [PERMISSIONS.MANAGE_COMMON]: true,
        [PERMISSIONS.DIRECTION_PANEL]: true,
        [PERMISSIONS.OOP_DIRECTION_PANEL]: true,

        [PERMISSIONS.MANAGE_OWN_SPECIALIST]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_SPECIALIST]: true,
        [PERMISSIONS.CONTROL_SPECIALIST]: true,

        [PERMISSIONS.MANAGE_OWN_RID]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_RID]: true,
        [PERMISSIONS.CONTROL_RID]: true,

        [PERMISSIONS.MANAGE_OWN_OOP]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_OOP]: true,
        [PERMISSIONS.CONTROL_OOP]: true,

        [PERMISSIONS.MANAGE_OWN_DPO]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_DPO]: true,
        [PERMISSIONS.CONTROL_DPO]: true,

        [PERMISSIONS.MANAGE_OWN_STAFF]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_STAFF]: true,
        [PERMISSIONS.CONTROL_STAFF]: true,

        [PERMISSIONS.MANAGE_OWN_PUB]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_PUB]: true,
        [PERMISSIONS.CONTROL_PUB]: true,

        [PERMISSIONS.CONTROL_SCHOOL]: true,
        [PERMISSIONS.MANAGE_SCHOOL]: true,
        [PERMISSIONS.MANAGE_OWN_SCHOOL]: true,

        [PERMISSIONS.MANAGE_OWN_FINANCING]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_FINANCING]: true,
        [PERMISSIONS.CONTROL_FINANCING]: true,

        [PERMISSIONS.MANAGE_OWN_NIOKR]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_NIOKR]: true,
        [PERMISSIONS.CONTROL_NIOKR]: true,

        [PERMISSIONS.MANAGE_OWN_EMPLOYED]: (element, user) => checkOwnProject(element, user),
        [PERMISSIONS.MANAGE_EMPLOYED]: true,
        [PERMISSIONS.CONTROL_EMPLOYED]: true,
    },

}
